import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import moment from "moment";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import ProfileService from 'services/profile';

import { useQuery } from '@tanstack/react-query';
import { useGlobal } from 'context/global-context';
import { formatPrice } from 'helpers/formatters';
import { Skeleton, Typography } from '@mui/material';

interface Data {
  createdDate: string;
  transactionType: string;
  notes: string;
  credit: number;
  debit: number;
}

interface DisplayData extends Data {
  Amount: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: keyof DisplayData | 'Amount';
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'createdDate',
    numeric: false,
    label: 'Created Date',
  },
  {
    id: 'transactionType',
    numeric: false,
    label: 'Transaction Type',
  },
  {
    id: 'notes',
    numeric: false,
    label: 'Notes',
  },
  {
    id: 'Amount',
    numeric: true,
    label: 'Amount',
  },
];

interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DisplayData) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DisplayData | 'Amount') => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof DisplayData | 'Amount'>('createdDate');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { showLoader } = useGlobal();

  const { getTransactionSummary} = ProfileService()

  const { data, isLoading }  = useQuery({
    queryKey: ["transactionSummary"],
    queryFn: () => {
        return getTransactionSummary()
    },
    refetchOnWindowFocus: false
})

  const rows = data?.payload ?? [];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DisplayData | 'Amount'
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...rows]
        .map((row) => ({
          ...row,
          Amount: row.credit > 0 ? row.credit : row.debit,
        }))
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage, isLoading]
  );


  const renderAmount = (credit: number, debit: number) => {
    if (credit > 0) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',  gap: .8 }}>
          <span style={{ color: 'transparent'}}>-</span>
          {formatPrice(credit)}
        </Box>
      );
    }
    if (debit > 0) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center',  justifyContent: 'flex-end', gap: .8 }}>
          -
          {formatPrice(debit)}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: '100%', padding: '2rem', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px' }}>
      {
        (!showLoader && !isLoading && rows?.length === 0) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='body2'>You don't have any transactions yet!</Typography> 
          </Box>
        )
      }
      
      {
         (showLoader || isLoading || rows?.length > 0) && (
          <React.Fragment>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!isLoading && visibleRows.map((row, index) => {
                    const labelId = `enhanced-table-label-${index}`;
      
                    return (
                      <TableRow hover tabIndex={-1} key={index} sx={{ cursor: 'pointer' }}>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} component="th" id={labelId} scope="row">
                          {row.createdDate ? moment(row.createdDate).format('YYYY-MM-DD') : ''}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="left">
                          {row.transactionType}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="left">
                          {row.notes}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}} align="right">
                          {renderAmount(row.credit, row.debit)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
      
                  {isLoading && (
                    <React.Fragment>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          {Array(4).fill(null).map((_, idx) => (
                            <TableCell key={idx} sx={{ width: idx === 0 ? '50px' : '400px' }}>
                              <Skeleton height={35} />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                margin: '2rem 0 0 0',
                '.MuiTablePagination-toolbar': {
                  fontSize: '0.875rem',
                },
                '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                  fontSize: '0.75rem',
                },
                '.MuiTablePagination-select': {
                  fontSize: '0.75rem',
                },
              }}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </React.Fragment>
         )
      }
    </Box>
  );
}
