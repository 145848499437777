import { Status } from "./types";
import { IAutomation } from "services/automations/automatios.service.types";

export const updateListAfterSaveName = (list: IAutomation[], id: number, newName: string) => {
    return list.map(item => (item.id === id ? { ...item, name: newName } : item));
}

export const updateListAfterDuplicated = (list: IAutomation[], newItem: IAutomation) => {
    return [ newItem, ...list ];
}

export const updateListAfterUpdateStatus = (list: IAutomation[], id: number, newStatus: Status) => {
    return list.map(item => (item.id === id ? { ...item, status: newStatus } : item));
}

export const updateListAfterDelete = (list: IAutomation[], id: number) => {
    return list.filter(item => item.id !== id);
}
