import { Box, CircularProgress, Grid, IconButton, MenuItem, Tooltip } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import DeleteIcon from 'assets/icons/delete';
import DuplicateIcon from 'assets/icons/duplicate';
import EditPencilIcon from 'assets/icons/edit-pencil';
import RenameIcon from 'assets/icons/rename';
import TemplateIcon from 'assets/icons/template';
import { formatNumber } from 'helpers/formatters';
import MDBox from 'material-ui/components/MDBox';
import MDIconButton from 'material-ui/components/MDIconButton';
import MDSelect from 'material-ui/components/MDSelect';
import MDTypography from 'material-ui/components/MDTypography';
import borders from 'material-ui/theme/base/borders';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';
import { OrderType } from 'models/enums/OrderType';
import { DeprecatedProductTypes } from 'models/enums/ProductTypes';
import { IntegrationOrderType, IVM_GridOrder } from 'models/views/gridOrderVm';

interface IGridColumnsProps {
  setCurrentOrder: (order: IVM_GridOrder) => void;
  setShowEditNameModal: (value: boolean) => void;
  setOrderName: (orderName: string) => void;
  handleStatusChange: (order: IVM_GridOrder, value: IntegrationOrderStatus) => void;
  setShowDeleteOrderModal: (value: boolean) => void;
  setShowCreateTemplateModal: (value: boolean) => void;
  setShowCopyOrderModal: (value: boolean) => void;
  setShowCopyIntegrationOrderModal: (value: boolean) => void;
  navigate: (path: string ) => void;
  showPlatform?: boolean;
}

const statusColors: { [key in IntegrationOrderStatus]: 'success' | 'error' | 'warning' } = {
  [IntegrationOrderStatus.Active]: 'success',
  [IntegrationOrderStatus.Inactive]: 'error',
  [IntegrationOrderStatus.Testing]: 'warning',
};

const productionStatusBackslash = {
  position: 'absolute',
  width: 20,
  height: 42,
  zIndex: 1,
  background: '#F05A2A',
  transform: 'rotate(20deg)',
};

export const gridColumns = ({
  setOrderName,
  setShowEditNameModal,
  handleStatusChange,
  setCurrentOrder,
  setShowDeleteOrderModal,
  setShowCreateTemplateModal,
  setShowCopyOrderModal,
  setShowCopyIntegrationOrderModal,
  navigate,
  showPlatform,
}: IGridColumnsProps): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row: order }: { row: IVM_GridOrder }) => (
        <Box width="100%" display="flex" justifyContent="space-between">
          <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{order.name}</p>
          <Tooltip title="Edit Name">
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                setCurrentOrder(order);
                setShowEditNameModal(true);
                setOrderName(order.name);
              }}
            >
              <RenameIcon htmlColor="#646367" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 132,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row: order }: { row: IVM_GridOrder }) => {
        const status = order.status as keyof typeof IntegrationOrderStatus;
        const isMover = order?.integrationOrderType === IntegrationOrderType.MOVER;
        const removeTestingStatus =
          order?.integrationOrderType === IntegrationOrderType.LEAD_REVEAL ||
          (isMover && status !== IntegrationOrderStatus.Testing);
        return (
          <MDSelect
            fullWidth
            value={status}
            onChange={(event) => handleStatusChange(order, event.target.value as IntegrationOrderStatus)}
            color={Object.keys(statusColors).includes(status) ? statusColors[status] : 'primary'}
          >
            <MenuItem value={IntegrationOrderStatus.Active}>{IntegrationOrderStatus.Active}</MenuItem>
            <MenuItem value={IntegrationOrderStatus.Inactive}>{IntegrationOrderStatus.Inactive}</MenuItem>
            {!removeTestingStatus && (
              <MenuItem value={IntegrationOrderStatus.Testing}>
                {isMover ? 'Draft' : IntegrationOrderStatus.Testing}
              </MenuItem>
            )}
          </MDSelect>
        );
      },
    },
    {
      headerName: 'Production Status',
      field: 'productionStatus',
      headerAlign: 'center',
      align: 'center',
      width: 410,
      hideSortIcons: true,
      sortable: false,
      renderCell: ({ row: order }: { row: IVM_GridOrder }) => (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          position="relative"
          width="100%"
          overflow="hidden"
        >
          <Grid
            item
            xs={3.6}
            p="6px"
            sx={{
              backgroundColor: '#929CB2',
              borderTopLeftRadius: borders.borderRadius.md,
              borderBottomLeftRadius: borders.borderRadius.md,
              textAlign: 'center',
            }}
          >
            <MDTypography fontWeight="normal" sx={{ fontSize: 12 }} color="white">
              Queued:{' '}
              <MDTypography component="span" display="inline-block" pl={0.5} sx={{ fontSize: 13 }} color="white">
                {order.inProcessing ? (
                  <CircularProgress size={13} color="inherit" />
                ) : formatNumber(order.queueCount ?? 0)}
              </MDTypography>
            </MDTypography>
          </Grid>
          <Grid item xs={4.8} p="6px" sx={{ backgroundColor: '#F05A2A', textAlign: 'center' }} position="relative">
            <MDBox sx={{ ...productionStatusBackslash, top: 0, left: -7 }} />
            <MDTypography fontWeight="normal" sx={{ fontSize: 12 }} color="white">
              In Production:{' '}
              <MDTypography component="span" display="inline-block" pl={0.5} sx={{ fontSize: 13 }} color="white">
                {order.inProductionCount ? formatNumber(order.inProductionCount) : 0}
              </MDTypography>
            </MDTypography>
            <MDBox sx={{ ...productionStatusBackslash, top: -5, right: -7 }} />
          </Grid>
          <Grid
            item
            xs={3.6}
            p="6px"
            sx={{
              backgroundColor: '#130B46',
              borderTopRightRadius: borders.borderRadius.md,
              borderBottomRightRadius: borders.borderRadius.md,
              textAlign: 'center',
            }}
          >
            <MDTypography fontWeight="normal" sx={{ fontSize: 12 }} color="white">
              Mailed:{' '}
              <MDTypography component="span" display="inline-block" pl={0.5} sx={{ fontSize: 13 }} color="white">
                {order.mailedCount ? formatNumber(order.mailedCount) : 0}
              </MDTypography>
            </MDTypography>
          </Grid>
        </Grid>
      ),
    },
    {
      type: 'actions',
      field: 'actions',
      align: 'right',
      flex: 1,
      minWidth: 200,
      getActions: (params: GridRowParams) => {
        const order = params.row as IVM_GridOrder;
        const isNotDeprecated =
          order.product !== null &&
          order.product !== '' &&
          order.product !== DeprecatedProductTypes.MediumHandwrittenCardA7;
        const buttons = [
          <Tooltip title={`Edit ${showPlatform ? 'Smart Automation' : 'integration order template'}`}>
            <MDIconButton
              borderRadiusSize="xxl"
              sx={{ padding: '5px', width: '40px', height: '30px', minWidth: 'unset' }}
              backgroundColor="light"
              onClick={() =>
                navigate(
                  order.platform === 'Zapier' ? `/template/${order.templateId}` : `/automations?status=createdSuccessfully`
                )
              }
            >
              <EditPencilIcon />
            </MDIconButton>
          </Tooltip>,
        ];
        if (isNotDeprecated) {
          buttons.push(
            <Tooltip title="Create Template">
              <MDIconButton
                borderRadiusSize="xxl"
                backgroundColor="light"
                sx={{ padding: '5px', width: '40px', height: '30px', minWidth: 'unset' }}
                onClick={() => {
                  setCurrentOrder(order);
                  setShowCreateTemplateModal(true);
                }}
              >
                <TemplateIcon />
              </MDIconButton>
            </Tooltip>
          );
          if (order.orderType === OrderType.Normal || order.orderType === OrderType.Integration) {
            const setShowCopyFunction =
              order.orderType === OrderType.Normal ? setShowCopyOrderModal : setShowCopyIntegrationOrderModal;
            buttons.push(
              <Tooltip title="Copy">
                <MDIconButton
                  borderRadiusSize="xxl"
                  sx={{ padding: '5px', width: '40px', height: '30px', minWidth: 'unset' }}
                  backgroundColor="light"
                  onClick={() => {
                    setCurrentOrder(order);
                    setShowCopyFunction(true);
                  }}
                >
                  <DuplicateIcon />
                </MDIconButton>
              </Tooltip>
            );
          }
        }
        buttons.push(
          <Tooltip title={params.row.canDelete ? 'Delete' : 'Integration order unable to be deleted'}>
            <span>
              <MDIconButton
                borderRadiusSize="xxl"
                sx={{ padding: '5px', width: '40px', height: '30px', minWidth: 'unset' }}
                backgroundColor="light"
                disabled={!params.row.canDelete}
                onClick={() => {
                  setCurrentOrder(order);
                  setShowDeleteOrderModal(true);
                }}
              >
                <DeleteIcon color="error" />
              </MDIconButton>
            </span>
          </Tooltip>
        );
        return buttons;
      },
    },
  ];

  if (showPlatform) {
    columns.splice(1, 0, {
      headerName: 'Platform',
      field: 'platform',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    });
  }

  return columns;
};
