import { Dialog, DialogContent, Stack } from "@mui/material"
import DialogTitle from "@mui/material/DialogTitle"
import MDButton from "material-ui/components/MDButton"
import AdminService from "services/admin";
import {useQueryClient} from "@tanstack/react-query";
import { useGlobal } from "context/global-context";

interface BulkUpdateOrdersModalProps {
    show: boolean
    setShow: Function
    orders: number[]
}

function BulkUpdateOrdersModal({show, setShow, orders}: BulkUpdateOrdersModalProps) {
    const queryClient = useQueryClient()

    const {setShowLoader} = useGlobal()
    const {bulkUpdateOrdersToMailed} = AdminService()

    function updateOrders() {
        setShowLoader(true)

        bulkUpdateOrdersToMailed(orders).then(() => {
            setShowLoader(false)
            setShow(false)

            queryClient.invalidateQueries({queryKey: ["adminOrders"]})
        })
    }

    return (
        <Dialog open={show} onClose={() => setShow(false)}>
            <DialogContent>
                <DialogTitle>
                    Are you sure you want to bulk update {orders.length} order{orders.length > 1? 's' : ''} to mailed?
                </DialogTitle>

                <Stack p={1} direction="row" spacing={2} marginTop={2} justifyContent="flex-end">
                    <MDButton color="light" onClick={() => setShow(false)}>
                        No
                    </MDButton>

                    <MDButton
                        color="primary"
                        onClick={updateOrders}
                    >
                        Yes
                    </MDButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default BulkUpdateOrdersModal
