import * as Yup from 'yup';

export const smartAutomationSourceValidation = Yup.object({
  name: Yup.string().nullable().required('Required'),
  dataSourceId: Yup.number().required('Required'),
});

export const smartAutomationValidation = (areFiltersRequired: boolean, source?: number) =>
  Yup.object({
    profileDataProviderId: Yup.string()
      .nullable()
      .when([], {
        is: () => source === 1,
        then: Yup.string().required('Required'),
        otherwise: Yup.string().notRequired(),
      }),
    segmentId: Yup.string()
      .nullable()
      .when([], {
        is: () => source === 2,
        then: Yup.string().required('Required'),
        otherwise: Yup.string().notRequired(),
      }),
    template: Yup.object().nullable().required('Required'),
    postageType: Yup.number().nullable().oneOf([null, 1, 2]).required('Required'),
    userCreatedFilters: Yup.array()
      .of(
        Yup.object().shape({
          integrationOrderFilters: Yup.array()
            .min(1, 'Required')
            .of(
              Yup.object().shape({
                dataSourceFilter: Yup.object()
                  .shape({
                    id: Yup.number().required(),
                    name: Yup.string().required(),
                    valueType: Yup.string().required(),
                  })
                  .nullable()
                  .required('Required'),
                operator: Yup.object()
                  .shape({
                    id: Yup.number().required(),
                    operator: Yup.string().required(),
                    showUi: Yup.boolean(),
                  })
                  .nullable()
                  .required('Required'),
                value: Yup.mixed().when('operator.showUi', {
                  is: true,
                  then: Yup.mixed().required('Required').notOneOf([''], 'Cannot be empty'),
                  otherwise: Yup.mixed().notRequired(),
                }),
              })
            ),
        })
      )
      .when([], {
        is: () => areFiltersRequired,
        then: Yup.array().required('Required'),
        otherwise: Yup.array().notRequired(),
      }),
    multiUseCouponId: Yup.number().nullable().notRequired(),
    couponListId: Yup.number().nullable().notRequired(),
  });

const moverAutomationSchema = Yup.object().shape({
  recipientSearchId: Yup.number(),
  template: Yup.object().nullable().required('Required'),
  polygonSearches: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        desiredQuantity: Yup.number().when('recipientSearchId', {
          is: (recipientSearchId: number) => recipientSearchId != null,
          then: Yup.number().required('Required'),
          otherwise: Yup.number().nullable(),
        }),
        polygonCriteria: Yup.object().shape({
          coordinates: Yup.array()
            .of(
              Yup.object().shape({
                lat: Yup.number().required('Required'),
                lng: Yup.number().required('Required'),
              })
            )
            .required('Required'),
        }),
      })
    )
    .required('Required'),
  addressRadiusSearches: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      desiredQuantity: Yup.number().when('recipientSearchId', {
        is: (recipientSearchId: number) => recipientSearchId != null,
        then: Yup.number().required('Required'),
        otherwise: Yup.number().nullable(),
      }),
      areaCriteria: Yup.object().shape({
        coordinate: Yup.object()
          .shape({
            lat: Yup.number().required('Required'),
            lng: Yup.number().required('Required'),
          })
          .required('Required'),
        radius: Yup.number().required('Required'),
      }),
    })
  ),
  filter: Yup.object()
    .shape({
      ownerOrRenterFilter: Yup.number().nullable().oneOf([0, 1], 'Required'),
      propertyTypeFilter: Yup.number().oneOf([0, 1, 2]),
    })
    .required('Required'),
  sendingOptions: Yup.object()
    .shape({
      maxNumberCardsSentPerMonth: Yup.number().nullable(),
      postageType: Yup.number().nullable().oneOf([null, 1, 2]).required('Required'),
    })
    .required('Required'),
});

export default moverAutomationSchema;
