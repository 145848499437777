import MDBox from "material-ui/components/MDBox";
import {Autocomplete, Divider, Grid, Stack, Tooltip} from "@mui/material";
import MDCard from "material-ui/components/MDCard";
import MDButton from "material-ui/components/MDButton";
import PhoneNumberService from "services/phone-number";
import {useQuery} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {ApiResponse} from "models/apiResponse";
import {AreaCode} from "models/areaCode";
import MDInput from "material-ui/components/MDInput";
import StyledDataGrid from "components/styled-data-grid";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid-pro";
import PlusIcon from "assets/icons/plus";
import {CTMPhoneNumber} from "models/CTMPhoneNumber";
import MDTypography from "material-ui/components/MDTypography";
import {formatPrice} from "helpers/formatters";
import CheckIcon from "assets/icons/check";
import CloseIcon from "assets/icons/close";
import DeleteIcon from "assets/icons/delete";
import {useNavigate} from "react-router-dom";
import CheckoutModal from "../components/checkout-modal";
import InfoModal from "components/info-modal";
import { useGlobal } from "context/global-context";
import { Box } from "@mui/system";

function CallTrackingOrderPage() {
    const navigate = useNavigate()

    const {setShowLoader} = useGlobal()
    const {findPhoneNumbers, getAreaCodes, getPaymentMethod, checkoutPhoneNumbers} = PhoneNumberService()

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    const [phoneNumbers, setPhoneNumbers] = useState<CTMPhoneNumber[]>([])
    const [rows, setRows] = useState<CTMPhoneNumber[]>([])

    let [selectedAreaCode, setSelectedAreaCode] = useState<AreaCode | null>(null)
    let [cart, setCart] = useState<CTMPhoneNumber[]>([])

    const [isFetching, setIsFetching] = useState<boolean>(false)

    let [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false)

    let getAreaCodesQuery = useQuery<ApiResponse<AreaCode[]>>({
        queryFn: getAreaCodes,
        queryKey: ["areaCodes"],
        refetchOnWindowFocus: false
    })

    useEffect(function () {
        let temp = []

        for(let i = 0; i < phoneNumbers.length; i++) {
            let currentNumber = phoneNumbers[i]
            let isInCart = false

            for (let j = 0; j < cart.length; j++) {
                if (cart[j].id === currentNumber.id) {
                    isInCart = true
                    break
                }
            }

            if(!isInCart){
                temp.push(currentNumber)
            }
        }

        setRows(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, phoneNumbers])

    useEffect(function () {
        setIsFetching(true)

        findPhoneNumbers(selectedAreaCode? selectedAreaCode.code : "").then((result) => {
            setPhoneNumbers(result.payload)

            setIsFetching(false)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAreaCode])

    function addToCart(item: CTMPhoneNumber) {
        setCart(prevState => {
            return [...prevState, item]
        })
    }

    function removeFromCart(item: CTMPhoneNumber){
        setCart(prevState => {
            return prevState.filter(a => a.id !== item.id);
        })
    }

    function handleCheckout(){
        setShowLoader(true)

        getPaymentMethod().then((response) => {
            setShowLoader(false)

            if (response.payload.id) {
                //The user has a payment method, do the checkout
                checkout()
            }
            else{
                //The user doesn't have a payment method, make them add one
                setShowCheckoutModal(true)
            }
        })
    }

    function checkout(){
        setShowLoader(true)

        checkoutPhoneNumbers(cart).then((response) => {
            if(response.hasErrors || response.hasWarnings){
                let text = ""

                if(response.hasErrors){
                    text = response.errors[0]
                }
                else if(response.hasWarnings){
                    text = response.warnings[0]
                }

                setErrorMessage(text)
                setShowErrorModal(true)
                setShowLoader(false)

                return
            }

            setShowLoader(false)
            setCart([])
            setShowCheckoutModal(false)

            navigate('/call-tracking/history')
        })
    }

    let columns: GridColDef[] = [
        {
            headerName: "Phone Number",
            field: "number",
            minWidth: 220,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                let firstPart = params.row.number.substring(0, 3);
                let secondPart = params.row.number.substring(3, 7);

                return `(${params.row.areaCode}) ${firstPart}-${secondPart}`
            }
        },
        {
            headerName: "Rate Center & Features",
            field: "features",
            width: 260,
            renderCell: (params: GridRenderCellParams) => {
                return <MDBox>
                    <MDTypography variant={"h6"} fontWeight={"normal"}>{params.row.rateCenterName}</MDTypography>

                    <Grid container alignItems={"center"} gap={2}>
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <Grid item>
                                    <MDTypography fontSize={"14px !important"} fontWeight={"normal"}>SMS:</MDTypography>
                                </Grid>
                                <Grid item>
                                    <Stack alignItems={"center"} justifyContent={"center"}>
                                        {params.row.hasSMS?
                                            <CheckIcon color={"primary"}/>
                                            :
                                            <CloseIcon/>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <Grid item>
                                    <MDTypography fontSize={"14px !important"} fontWeight={"normal"}>MMS:</MDTypography>
                                </Grid>

                                <Grid item>
                                    <Stack alignItems={"center"} justifyContent={"center"}>
                                        {params.row.hasMMS?
                                            <CheckIcon color={"primary"}/>
                                            :
                                            <CloseIcon/>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
            }
        },
        {
            headerName: "Monthly Fee",
            field: "fee",
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return `$${params.row.fee.toFixed(2)}`
            }
        },
        {
            field: "actions",
            type: "actions",
            renderCell: function (params: GridRenderCellParams) {
                return <Tooltip title={"Add to cart"}>
                    <MDBox display={"flex"} onClick={() => addToCart(params.row)}>
                        <PlusIcon/>
                    </MDBox>
                </Tooltip>
            },
        }
    ]

    return <Grid container gap={3} flexWrap={"nowrap"} width={"100%"} position={'relative'} sx={{ margin: '.25rem'}}>
        <Grid item flex={1}>
            <MDCard>
                <Box px={4}>
                    <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                        <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                            Call Tracking 
                        </MDTypography>
                    </Grid>
                </Box>
                <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />

                <MDBox p={4}>
                    <Autocomplete
                        sx={{width: "300px"}}
                        fullWidth={true}
                        options={getAreaCodesQuery.data?.payload?? []}
                        groupBy={(option: AreaCode) => option.state}
                        getOptionLabel={(option: any) => `(${option.code})`}
                        onChange={(event, value) => {
                            setSelectedAreaCode(value)
                        }}
                        renderInput={(props: any) => (
                            <MDInput
                                {...props}
                                size={"large"}
                                label={"# Area Code"}
                            />
                        )}
                    />

                    <MDBox mt={3}>
                        <StyledDataGrid
                            loading={isFetching}
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {paginationModel: {pageSize: 25}},
                            }}
                            pageSizeOptions={[25]}
                            pagination={true}
                            disableRowSelectionOnClick={true}
                        />
                    </MDBox>
                </MDBox>
            </MDCard>
        </Grid>

        <Grid item width={"500px"}>
            <MDCard>
                <MDBox p={4}>
                    <MDTypography fontWeight={"bold"} sx={{fontSize: 20}}>Cart</MDTypography>

                    <Grid container mt={2} alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item>
                            <MDTypography fontWeight={"bold"} sx={{fontSize: 16}}>Phone Number</MDTypography>
                        </Grid>

                        <Grid item>
                            <MDTypography fontWeight={"bold"} sx={{fontSize: 16}}>$/month</MDTypography>
                        </Grid>
                    </Grid>

                    <MDBox mt={1}>
                        {cart.map((entry, index) => {
                            let firstPart = entry.number.substring(0, 3);
                            let secondPart = entry.number.substring(3, 7);

                            return <Grid container alignItems={"center"} justifyContent={"space-between"} mt={1.5}>
                                <Grid item>
                                    <MDTypography sx={{fontSize: 15}}>({entry.areaCode}) {firstPart}-{secondPart}</MDTypography>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems={"center"} gap={1}>
                                        <Grid item>
                                            <MDTypography sx={{fontSize: 15}}>{formatPrice(entry.fee)}</MDTypography>
                                        </Grid>

                                        <Grid item>
                                            <MDBox onClick={() => removeFromCart(entry)} display={"flex"} sx={{cursor: "pointer"}}>
                                                <DeleteIcon color={"error"}/>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </MDBox>

                    <Divider light={true}/>

                    <MDBox mt={1}>
                        <Grid container alignItems={"center"} justifyContent={"flex-end"} gap={5}>
                            <Grid item>
                                <MDTypography color={"primary"} fontWeight={"bold"} sx={{fontSize: 18}}>Total Due: </MDTypography>
                            </Grid>

                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Grid item>
                                        <MDTypography color={"primary"} fontWeight={"bold"} sx={{fontSize: 18}}>
                                            {formatPrice(
                                                cart
                                                    .reduce((sum,entry) => {
                                                        return sum + entry.fee
                                                    }, 0)
                                            )}
                                        </MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography color={"primary"} fontWeight={"normal"} sx={{fontSize: 12}}>/month</MDTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDBox>

                    <MDBox mt={4}>
                        <MDButton color={"primary"} fullWidth={true} disabled={!cart.length} onClick={handleCheckout}>
                            Checkout
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDCard>
        </Grid>

        <CheckoutModal
            show={showCheckoutModal}
            setShow={setShowCheckoutModal}
            checkout={checkout}
        />

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorMessage}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />
    </Grid>
}

export default CallTrackingOrderPage