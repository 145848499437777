import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DialogContent, Grid, DialogTitle, Dialog, Box } from '@mui/material';
import MDButton from 'material-ui/components/MDButton';
import MDTypography from 'material-ui/components/MDTypography';
import Loader from 'components/loader';
import TemplateService from 'services/template';
import { ITemplate, Template } from 'models/template';
import { useGlobal } from 'context/global-context';
import { ProductOptions } from 'newStandard/src/services/order/types';
import TemplateCardForModals from 'components/template-card-for-modals';

const SelectTemplateModal = () => {
  const navigate = useNavigate();
  const { getTemplates, postTemplate } = TemplateService();
  const { templateFlow, setTemplateFlow, setShowLoader, allowDiscount } = useGlobal();
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(templateFlow.order?.template);

  const { data: templates, isLoading } = useQuery({
    queryKey: ['templates', templateFlow.shouldCheckNotSupported],
    queryFn: () => getTemplates(templateFlow.shouldCheckNotSupported),
    refetchOnWindowFocus: false,
  });

  const { mutate: createTemplate } = useMutation({
    mutationFn: () => postTemplate({ ...new Template(0), isIntegrationOrderTemplate: true }),
    onMutate: () => setShowLoader(true),
    onSuccess: (data) => {
      setTemplateFlow((prev) => ({ ...prev, showModal: false }));
      navigate(`/template/${data.id}`);
      setShowLoader(false);
    },
  });

  const handleTemplateConfirm = (newTemplate?: ITemplate) => {
    const auxTemplate = newTemplate || selectedTemplate;
    if (auxTemplate) {
      templateFlow.onTemplateChange?.(auxTemplate);
      setTemplateFlow({});
    } else if (templateFlow.customCreateCallback) {
      setTemplateFlow({ ...templateFlow, showModal: false });
      templateFlow.customCreateCallback();
    } else createTemplate();
    setSelectedTemplate(null);
  };

  const handleModalClose = () => {
    setSelectedTemplate(null);
    setTemplateFlow({});
  };

  return (
    <Dialog open={templateFlow.showModal ?? false} onClose={() => handleModalClose()} maxWidth={false}>
      <DialogContent>
      {!isLoading && (
          <Box display="flex" gap={1} justifyContent={ !!templates?.length ? 'space-between' : 'flex-start'} height={'85px'} sx={{ 
              padding: '2rem 0 0 0',
              position: 'sticky',
              top: '-30px',
              background: 'white',
              zIndex: 999999
            }} >
            <Box>
              <DialogTitle>Select one of your templates</DialogTitle>
            </Box>
            {
              !!templates?.length && (
                <Box sx={{ display: 'flex', gap: 1, height: '40px' }}>
                  <MDButton color="light" onClick={() => handleModalClose()}>
                    Close
                  </MDButton>
                  <MDButton sx={{ opacity: !selectedTemplate ? '.5' : '1', cursor: !selectedTemplate ? 'not-allowed' : 'pointer'}} onClick={() => selectedTemplate && handleTemplateConfirm()} disabeld={selectedTemplate === null}>
                    Select
                  </MDButton>
                </Box>
              )
            }
          </Box>
        )}
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Loader />
          </Box>
        ) : templates?.length ? (
          <Box >
            <Box sx={{ 
                overflowY: 'auto',  
                minHeight: '800px',
                minWidth: '1000px',
                padding: templates?.some((t: ITemplate) => t.product === ProductOptions.HandwrittenBiFoldCard) ? '6rem 0' : '2rem 0',
              }}>
              <Grid container spacing={'100px'} alignItems="stretch">
                {templates.map((template: Template, index: number) => (
                  <Grid item key={index} sx={{ height: templates?.some((t: ITemplate) => t.product === ProductOptions.HandwrittenBiFoldCard) ? '390px' : '250px' }}>
                    <TemplateCardForModals
                      template={template}
                      disabled={(template.hasDiscount && !allowDiscount) || template.templateNotSupported}
                      hideButtons
                      onClick={() => {
                        setSelectedTemplate(template);
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                      }}                      
                      onDoubleClick={() => handleTemplateConfirm(template)}
                      pointerCursor
                      selected={selectedTemplate?.id === template.id}
                      showPreviewButton
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ) : (
          <DialogTitle mb={0}>
            You don't have any templates yet. Please create one by clicking
            <MDTypography
              color={'primary'}
              isLink
              onClick={() => createTemplate()}
              display={'inline-block'}
              fontWeight={'bold'}
              ml={0.5}
            >
              here
            </MDTypography>
          </DialogTitle>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SelectTemplateModal;
