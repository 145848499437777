import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import {Grid, Tooltip} from "@mui/material";
import CheckIcon from "assets/icons/check";
import {useState} from "react";
import StyledDataGrid from "components/styled-data-grid";
import {
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams, useGridApiRef
} from "@mui/x-data-grid-pro";
import {formatUSPhoneNumber, removeUSPhoneNumberFormat} from "helpers/phoneNumberFormat";
import {IMaskInput} from "react-imask";
import EditPencilIcon from "assets/icons/edit-pencil";
import PhoneNumberService from "services/phone-number";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import MDButton from "material-ui/components/MDButton";
import DeleteIcon from "assets/icons/delete";
import InfoModal from "components/info-modal";
import {ProfilePhoneNumber} from "models/phoneNumber";
import CheckoutModal from "../components/checkout-modal";
import { useGlobal } from "context/global-context";
import { Box } from "@mui/system";

function CallTrackingHistoryPage(){
    const {setShowLoader} = useGlobal()
    const queryClient = useQueryClient()
    const apiRef = useGridApiRef()

    const {getPhoneNumbers, deletePhoneNumber, updateForwardsTo, getPaymentMethod} = PhoneNumberService()

    const [numberToBeDeleted, setNumberToBeDeleted] = useState<ProfilePhoneNumber | null>(null)
    const [numberToBeEdited, setNumberToBeEdited] = useState<ProfilePhoneNumber | null>(null)

    const [showDeleteNumberModal, setShowDeleteNumberModal] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>("false")

    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(false)

    const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = useState<boolean>(false)

    const getCallTrackingPaymentMethodQuery = useQuery({
        queryKey: ["callTrackingPaymentMethod"],
        queryFn: getPaymentMethod,
        refetchOnWindowFocus: false
    })

    const getCallTrackingNumbersQuery = useQuery({
        queryKey: ["phoneNumbers"],
        queryFn: getPhoneNumbers,
        refetchOnWindowFocus: false
    })

    const callTrackingInfo = [
        "Collect call tracking analytics with a unique phone number that you can add to your cards.",
        "Each number costs $1.50 per month and $0.04 per minute.",
        "To start your account is filled with $25 of credits and will automatically refill to that amount once it dips below $10.",
        "All charges are billed directly to your card from our 3rd call tracking provider."
    ]

    let columns: GridColDef[] = [
        {
            headerName: "Call Tracking Number",
            field: "number",
            flex: 1,
            editable: false,
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatUSPhoneNumber(params.value);
            }
        },
        {
            headerName: "Forwards Calls To",
            field: "forwardsTo",
            flex: 1,
            editable: true,
            renderEditCell: () => {
                return <IMaskInput
                        defaultValue={numberToBeEdited? formatUSPhoneNumber(numberToBeEdited.forwardsTo) : ""}
                        style={{
                            border: "none",
                            outline: "none",
                            paddingLeft: "10px",
                            fontSize: "inherit",
                            width: "100%",
                            height: "100%"
                        }}
                        placeholder={"+1(000) 000-0000"}
                        mask="+1(000) 000-0000"
                        onAccept={(value: any) => {
                            setNumberToBeEdited(prevState => {
                                return {...prevState!, forwardsTo: `+${removeUSPhoneNumberFormat(value)}`}
                            })
                        }}
                />
            },
            renderCell: function (params: GridRenderCellParams) {
                return <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        {params.formattedValue}
                    </Grid>
                    <Grid item>
                        <Tooltip title={"Edit"}>
                            <MDBox display={"flex"} onClick={() => {
                                setNumberToBeEdited(params.row)
                                apiRef.current.startCellEditMode({ id: params.id, field: 'forwardsTo' });
                            }}>
                                <EditPencilIcon/>
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            },
            cellClassName: (params: GridCellParams) => {
                if (!params.value) {
                    return 'error';
                }

                return '';
            },
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatUSPhoneNumber(params.value);
            }
        },
        {
            field: "actions",
            type: "actions",
            editable: false,
            width: 80,
            renderCell: function (params: GridRenderCellParams) {
                return <Tooltip title={"Remove"}>
                    <MDBox display={"flex"} onClick={() => {
                        setNumberToBeDeleted(params.row)
                        setShowDeleteNumberModal(true)
                    }}>
                        <DeleteIcon color={'error'}/>
                    </MDBox>
                </Tooltip>
            }
        }
    ]

    function createPaymentMethod() {
        setShowPaymentMethodModal(false)
    }

    function handleDeleteNumber(){
        setShowLoader(true)

        deletePhoneNumber(numberToBeDeleted?.id!).then(() => {
            setShowDeleteNumberModal(false)
            setShowLoader(false)

            queryClient.invalidateQueries({queryKey: ["phoneNumbers"]})
        })
    }

    return <MDCard sx={{ position: 'relative', margin: '.25rem'}}>
        <Box px={4}>
            <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                    Call Tracking
                </MDTypography>
            </Grid>
            <MDBox mt={2} mb={3}>
                {callTrackingInfo.map((el, index) => {
                    return <Grid container alignItems={"center"} key={index} gap={1}>
                        <Grid item display={"flex"}>
                            <CheckIcon color={"primary"}/>
                        </Grid>
                        <Grid item>
                            <MDTypography sx={{fontSize: 18}} fontWeight={"regular"}>
                                {el}
                            </MDTypography>
                        </Grid>
                    </Grid>
                })}
            </MDBox>

            <MDTypography sx={{fontSize: 20}} fontWeight={"bold"}>My Numbers</MDTypography>

            <MDBox mt={2} mb={3} maxWidth={"850px"}>
                <StyledDataGrid
                    apiRef={apiRef}
                    loading={getCallTrackingNumbersQuery.isLoading}
                    rows={getCallTrackingNumbersQuery.data?.payload?? []}
                    columns={columns}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                    }}
                    pageSizeOptions={[25]}
                    pagination={true}
                    disableRowSelectionOnClick={true}
                    onCellEditStop={(params, event: any, details) => {
                        setShowLoader(true)

                        updateForwardsTo(numberToBeEdited!).then((response) => {
                            setShowLoader(false)
                            setNumberToBeEdited(null)

                            if(response.hasErrors || response.hasWarnings){
                                let text = ""

                                if(response.errors){
                                    text = response.errors[0]
                                }
                                else if(response.warnings){
                                    text = response.warnings[0]
                                }

                                setShowErrorModal(true)
                                setErrorModalText(text)

                                return
                            }

                            queryClient.invalidateQueries({queryKey: ["phoneNumbers"]})
                            apiRef.current.updateRows([{id: params.id, forwardsTo: numberToBeEdited?.forwardsTo}]);
                        })
                    }}
                />
            </MDBox>

            <MDTypography sx={{fontSize: 20}} fontWeight={"bold"}>My Numbers</MDTypography>

            <MDBox mt={2}>
                <MDTypography sx={{fontSize: 18}} fontWeight={"regular"}>
                    Call tracking is provided by a 3rd party who will bill your credit card directly.
                </MDTypography>
            </MDBox>

            {!getCallTrackingPaymentMethodQuery.isLoading?
                getCallTrackingPaymentMethodQuery.data.payload.id?
                    <MDBox mt={2}>
                        <MDCard color={"primary"} borderRadiusSize={"xl"} border={false} boxShadow={false} sx={{width: 300}}>
                            <MDBox p={2} height={"100%"}>
                                <Grid container justifyContent={"space-between"} height={"100%"}>
                                    <Grid item>
                                        <MDTypography variant={"h4"} fontWeight={"regular"} color={"white"}>**** **** **** {getCallTrackingPaymentMethodQuery.data.payload.endsWith}</MDTypography>
                                        <MDTypography variant={"h5"} fontWeight={"regular"} color={"white"}>EXP {getCallTrackingPaymentMethodQuery.data.payload.expDate}</MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item>
                                                <MDBox display={"flex"} onClick={() => {
                                                    setShowDeletePaymentMethodModal(true)
                                                }}>
                                                    <EditPencilIcon color={"white"}/>
                                                </MDBox>
                                            </Grid>

                                            <Grid item>
                                                <MDBox display={"flex"} onClick={() => {
                                                    setShowDeletePaymentMethodModal(true)
                                                }}>
                                                    <DeleteIcon color={"white"}/>
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </MDCard>
                    </MDBox>
                    :
                    <MDBox mt={2}>
                        <MDButton
                            color={"primary"}
                            onClick={() => {
                                setShowPaymentMethodModal(true)
                            }}
                        >
                            Add Payment Method
                        </MDButton>
                    </MDBox>
                :
                null
            }
        </Box>

        <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />


        <InfoModal
            show={showDeleteNumberModal}
            setShow={setShowDeleteNumberModal}
            headerText={`Are you sure you want to delete ${numberToBeDeleted? formatUSPhoneNumber(numberToBeDeleted.number) : ""}?`}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteNumberModal(false)
            }}
            confirmButtonOnClick={handleDeleteNumber}
        />

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalText}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />

        <InfoModal
            show={showDeletePaymentMethodModal}
            setShow={setShowDeletePaymentMethodModal}
            headerText={'Please contact support@lettrlabs.com to change or remove your payment method'}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowDeletePaymentMethodModal(false)
            }}
        />

        <CheckoutModal
            show={showPaymentMethodModal}
            setShow={setShowPaymentMethodModal}
            checkout={createPaymentMethod}
        />
    </MDCard>
}

export default CallTrackingHistoryPage