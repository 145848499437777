import API_ENDPOINT, { IObjectResult } from '../endpoint';

import { PaymentMethod } from '@stripe/stripe-js';
import { ICheckoutData } from './checkout.types';

const useUnauthenticatedCheckoutService = () => {
    const API_PATH = `${API_ENDPOINT}/deals`;

    const get = async (BillingManagerSessionIdentifier: string, headers: HeadersInit = {}): Promise<IObjectResult<ICheckoutData>> => { 
        try {
            const response = await fetch(`${API_PATH}/anonymous/${BillingManagerSessionIdentifier}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            return data
        } catch (error) {
            console.error('Erro na requisição GET:', error);
            throw error;
        }
    };

    const getProfilePaymentsMethod = async (dealId: string, headers: HeadersInit = {}): Promise<IObjectResult<PaymentMethod[]>> => { 
        const response = await fetch(`${API_PATH}/anonymous/profilePaymentMethods/${dealId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const data: IObjectResult<PaymentMethod[]> = await response.json();
        
        return data;
    };

    const makeAnonymousSubscription = async (
        payload: Partial<ICheckoutData>, 
        headers: HeadersInit = {}
    ): Promise<IObjectResult<ICheckoutData>> => {
    
        const response = await fetch(`${API_PATH}/anonymous`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(payload),
        });
    
        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }
    
        return response.json();
    };

    const addAnonymousPayment = async (
        payload: Partial<ICheckoutData>, 
        headers: HeadersInit = {}
    ): Promise<IObjectResult<ICheckoutData>> => {
    
        const response = await fetch(`${API_PATH}/anonymous/profilePaymentMethods`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(payload),
        });
    
        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }
    
        return response.json();
    };

    return {
        get,
        getProfilePaymentsMethod,
        makeAnonymousSubscription,
        addAnonymousPayment
    };
};

export default useUnauthenticatedCheckoutService;
