import React from 'react';

import { Box } from '@mui/system';
import { IProfile } from 'models/profile';
import { useStripe } from '@stripe/react-stripe-js';
import { Typography } from '@mui/material';
import { ICheckoutData } from 'services/checkout/checkout.types';
import { enqueueSnackbar } from 'notistack';

import MDRadio from 'material-ui/components/MDRadio';
import ProfileService from 'services/profile';

interface INewACH {
  profile: IProfile | undefined,
  data: ICheckoutData | any,
  loadingProfilePayments: boolean,
  selectedPaymentMethodId: string,
  setSubmiting: (value: boolean) => void,
  setErrorMessage: (value: string | null) => void,
  setIsSubmitingPayment: (value: boolean) => void,
  setSelectedPaymentMethodId: (value: string) => void,
  handleUpdateList: () => void
}

const NewACH: React.FC<INewACH> = ({ 
    data, 
    profile, 
    loadingProfilePayments, 
    selectedPaymentMethodId, 
    setSubmiting, 
    setErrorMessage, 
    handleUpdateList,
    setIsSubmitingPayment,
    setSelectedPaymentMethodId
  }) => {

  const { createSetupIntent } = ProfileService();

    const stripe = useStripe();

    // **Link** flow for US Bank Account
    // 1) Create SetupIntent in backend
    // 2) Confirm that SetupIntent in the frontend with Link
    // 3) (Optional) addPaymentMethod if your flow requires it
    const handleBankPaymentFlowWithLink = async () => {
      if (!stripe) {
          setErrorMessage('Stripe not loaded');
          return;
      }
      setIsSubmitingPayment(true);
      setErrorMessage(null);

      // Step 1: create SetupIntent with "instant" verification
      const response = await createSetupIntent(); // This likely returns { payload: {...}, errors: [] }
      if (!response?.payload?.client_secret) {
          setErrorMessage('Could not find client_secret in SetupIntent response.');
          setIsSubmitingPayment(false);
          setSubmiting(false);
          return;
      }

      // 2) Extract the actual string for client_secret
      const clientSecret = response.payload.client_secret;

      // Step 1: Collect Bank Account for Setup
      const { setupIntent, error } = await stripe.collectBankAccountForSetup({
          clientSecret,
          params: {
              payment_method_type: 'us_bank_account',
              payment_method_data: {
                  billing_details: {
                      name: profile?.firstName + ' ' + profile?.lastName,
                      email: profile?.email,
                  }
              }
          }
      });

      if (error) {
          setErrorMessage(error.message || 'Error verifying bank');
          setIsSubmitingPayment(false);
          setSubmiting(false);
          return;
      } else {
          const paymentMethodId = setupIntent.payment_method;
          if (paymentMethodId) {
              setSelectedPaymentMethodId(paymentMethodId?.toString());
          }
      }

      // Step 2: Confirm
      const { setupIntent: confirmedSetupIntent, error: confirmError } = await stripe.confirmUsBankAccountSetup(clientSecret);
      if (error) {
          setErrorMessage(confirmError?.message || 'Error verifying bank');
          setIsSubmitingPayment(false);
          setSubmiting(false);
          return;
      } else if (confirmedSetupIntent && confirmedSetupIntent.status === 'succeeded') {
          // The new PaymentMethod is attached to the customer automatically
          // (Because we passed 'Customer' in the SetupIntent creation if we had a known customer)
          enqueueSnackbar('Bank account instantly verified!', { variant: 'success' });
          handleUpdateList()

      }
      else {
          enqueueSnackbar(`SetupIntent ended with status: ${setupIntent?.status}`, {
              variant: 'info',
          });
          setIsSubmitingPayment(false);
          setSubmiting(false);
      }
    };

    return (
      <React.Fragment>
        {!loadingProfilePayments && (
            <Box
                sx={{
                    margin: '1rem 0',
                    padding: '1rem',
                    border: '1px solid rgba(0,0,0,.1)',
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <MDRadio
                        value="new ach"
                        checked={selectedPaymentMethodId === 'new ach'}
                        onChange={() => {
                            handleBankPaymentFlowWithLink();
                            setSelectedPaymentMethodId('new ach')
                        }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="body2">Add a New Bank Account (ACH)</Typography>
                    </Box>
                </Box>

                {selectedPaymentMethodId === 'new ach' && (
                    <Typography variant="caption" sx={{ ml: 5 }}>
                        We will prompt Stripe to instantly verify your bank.
                    </Typography>
                )}
            </Box>
        )}
      </React.Fragment>
    );
}

export default NewACH;