import { ExpandMore } from '@mui/icons-material';
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'helpers/formatters';
import ProfileRecipientEnrichmentService from 'services/profile-recipient-enrichment';
import { useGlobal } from 'context/global-context';
import Loader from 'components/loader';
import { ResolveProfileEnrichmentConflicts } from 'models/enums/ProfileEnrichment';
import EnrichmentOrderDetail from 'models/profle-recipients/enrichment-order-detail';

interface ResolveProfileEnrichmentConflictRoot {
  profileRecipientId: number;
  existingSelected: boolean;
  enrichedSelected: boolean;
}

export default function EnrichmentReport() {
  const { setShowLoader } = useGlobal();
  const queryClient = useQueryClient();
  const [anchorButton, setAnchorButton] = useState<HTMLElement>();
  const [resolvedArray, setResolvedArray] = useState<ResolveProfileEnrichmentConflictRoot[]>();

  let { id = '' } = useParams();

  const { getEnrichOrderDetails, getProfileEnrichmentConflicts, resolveProfileEnrichmentConflicts } =
    ProfileRecipientEnrichmentService();

  const boxLoader = () => {
    return (
      <Box
        minHeight={'10vh'}
        overflow="hidden"
        display="flex"
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Loader />
      </Box>
    );
  };

  const {
    data: conflicts,
    isSuccess,
    isFetching,
  } = useQuery({
    queryFn: () => getProfileEnrichmentConflicts(parseInt(id)),
    queryKey: ['getProfileEnrichmentConflicts'],
    refetchOnWindowFocus: false,
  });

  const getEnrichOrderDetailsQuery = useQuery<EnrichmentOrderDetail>({
    queryFn: () => getEnrichOrderDetails(parseInt(id)),
    queryKey: ['getEnrichOrderDetails'],
    refetchOnWindowFocus: false,
  });

  const { mutate: resolve } = useMutation({
    mutationFn: () => resolveProfileEnrichmentConflicts(parseInt(id), resolvedArray ?? []),
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProfileEnrichmentConflicts'] });
      setShowLoader(false);
    },
  });

  useEffect(() => {
    if (conflicts && conflicts?.result?.length > 0) {
      const mapped = conflicts.result.map((item: any) => {
        const resolvedObject: ResolveProfileEnrichmentConflictRoot = {
          profileRecipientId: item.baseProfileRecipient.id,
          existingSelected: false,
          enrichedSelected: false,
        };
        return resolvedObject;
      });
      setResolvedArray(mapped);
    }
  }, [isSuccess, conflicts]);

  const setExistingSelected = (recipienteId: number, selected: boolean) => {
    setResolvedArray((prevItems) =>
      prevItems?.map((item) =>
        item.profileRecipientId === recipienteId ? { ...item, existingSelected: selected } : item
      )
    );
  };

  const setEnrichedSelected = (recipienteId: number, selected: boolean) => {
    setResolvedArray((prevItems) =>
      prevItems?.map((item) =>
        item.profileRecipientId === recipienteId ? { ...item, enrichedSelected: selected } : item
      )
    );
  };

  const isChecked = (group: ResolveProfileEnrichmentConflicts, profileRecipientId: number) => {
    if (group === ResolveProfileEnrichmentConflicts.Existent) {
      return resolvedArray?.find((x) => x.profileRecipientId === profileRecipientId)?.existingSelected;
    }
    if (group === ResolveProfileEnrichmentConflicts.Enriched) {
      return resolvedArray?.find((x) => x.profileRecipientId === profileRecipientId)?.enrichedSelected;
    }
  };

  const shortcutToSelection = (selected: ResolveProfileEnrichmentConflicts) => {
    if (selected === ResolveProfileEnrichmentConflicts.Existent) {
      setResolvedArray((prevItems) =>
        prevItems?.map((i) => {
          return { ...i, existingSelected: true, enrichedSelected: false };
        })
      );
    }
    if (selected === ResolveProfileEnrichmentConflicts.Enriched) {
      setResolvedArray((prevItems) =>
        prevItems?.map((i) => {
          return { ...i, existingSelected: false, enrichedSelected: true };
        })
      );
    }
    if (selected === ResolveProfileEnrichmentConflicts.Both) {
      setResolvedArray((prevItems) =>
        prevItems?.map((i) => {
          return { ...i, existingSelected: true, enrichedSelected: true };
        })
      );
    }
    if (selected === ResolveProfileEnrichmentConflicts.None) {
      setResolvedArray((prevItems) =>
        prevItems?.map((i) => {
          return { ...i, existingSelected: false, enrichedSelected: false };
        })
      );
    }
  };

  return (
    <MDCard>
      <Stack m={2} spacing={2}>
        <Typography variant="h3" fontSize={26}>
          Enrichment Report
        </Typography>
        <Typography fontSize={16}>
          <p>You can view and resolve all conflicts in your enrichment process here.</p>
          <p>
            If any of the columns differ from the ones we've provided, they'll be shown here for you to choose which one
            to keep—or if you prefer, you can keep both.
          </p>
        </Typography>
        <Divider light />

        {getEnrichOrderDetailsQuery.isLoading && <>{boxLoader()}</>}

        {getEnrichOrderDetailsQuery.isSuccess && (
          <>
            <p>{`You have requested ${getEnrichOrderDetailsQuery.data.totalRequested} recipients to be enriched.`}</p>
            <Box component={'ul'} ml={3}>
              <Typography component={'li'} fontSize={16} ml={6}>
                {`${getEnrichOrderDetailsQuery.data.totalProcessed} enrichments were successfully processed.`}
              </Typography>
              <Typography component={'li'} fontSize={16} ml={6}>
                {`You were charged in $${getEnrichOrderDetailsQuery.data.totalPrice.toFixed(2)}.`}
              </Typography>
              <Typography component={'li'} fontSize={16} ml={6}>
                {`${getEnrichOrderDetailsQuery.data.totalConflicted} enrichments encountered conflicts and require your attention.`}
              </Typography>
            </Box>
            <p>Please review the conflicts to proceed with resolving them.</p>
          </>
        )}

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h3" fontSize={26}>
            Conflict Resolution
          </Typography>

          <MDButton
            color="light"
            onClick={(evt) => setAnchorButton(evt.currentTarget)}
            disabled={conflicts?.result?.length === 0}
          >
            Select All
            <ExpandMore sx={{ ml: 1 }} />
          </MDButton>
          <Menu anchorEl={anchorButton} open={!!anchorButton} onClose={() => setAnchorButton(undefined)}>
            <MenuItem
              onClick={() => {
                setAnchorButton(undefined);
                shortcutToSelection(ResolveProfileEnrichmentConflicts.Existent);
              }}
            >
              Existing
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorButton(undefined);
                shortcutToSelection(ResolveProfileEnrichmentConflicts.Enriched);
              }}
            >
              Enriched
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorButton(undefined);
                shortcutToSelection(ResolveProfileEnrichmentConflicts.Both);
              }}
            >
              Both
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorButton(undefined);
                shortcutToSelection(ResolveProfileEnrichmentConflicts.None);
              }}
            >
              None
            </MenuItem>
          </Menu>
        </Box>
        <Box maxHeight={'50vh'} overflow="auto">
          {isFetching && <>{boxLoader()}</>}
          <Table></Table>
          {isSuccess && !isFetching && conflicts?.result?.length > 0 && (
            <Table style={{ width: '100%', fontSize: 14, borderCollapse: 'collapse' }}>
              <TableHead style={{ position: 'sticky', top: 0, backgroundColor: '#130B46', zIndex: 1 }}>
                <TableRow>
                  <TableCell style={{ borderRadius: '1rem 0 0 0 ' }}></TableCell>
                  <TableCell align="left" style={{ color: '#FFF' }}>
                    First Name
                  </TableCell>
                  <TableCell align="left" style={{ color: '#FFF' }}>
                    Last Name
                  </TableCell>
                  <TableCell align="left" style={{ color: '#FFF' }}>
                    Address 1
                  </TableCell>
                  <TableCell align="left" style={{ color: '#FFF' }}>
                    Address 2
                  </TableCell>
                  <TableCell align="left" style={{ color: '#FFF' }}>
                    City
                  </TableCell>
                  <TableCell align="left" style={{ color: '#FFF' }}>
                    State
                  </TableCell>
                  <TableCell align="left" style={{ color: '#FFF' }}>
                    Zip Code
                  </TableCell>
                  <TableCell align="left" style={{ color: '#FFF', borderRadius: '0 1rem 0 0' }}>
                    Birthday
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conflicts.result.map((item: any) => (
                  <>
                    <TableRow key={item.id} style={{ backgroundColor: '#f05a2a33' }}>
                      <TableCell>
                        <Checkbox
                          sx={{ '.MuiSvgIcon-root': { bgcolor: '#FFF' } }}
                          checked={
                            !!isChecked(ResolveProfileEnrichmentConflicts.Existent, item.baseProfileRecipient.id)
                          }
                          onChange={(e) => setExistingSelected(item.baseProfileRecipient.id, e.target.checked)}
                        />
                      </TableCell>
                      <TableCell>{item.baseProfileRecipient.firstName}</TableCell>
                      <TableCell>{item.baseProfileRecipient.lastName}</TableCell>
                      <TableCell>{item.baseProfileRecipient.address1}</TableCell>
                      <TableCell>{item.baseProfileRecipient.address2}</TableCell>
                      <TableCell>{item.baseProfileRecipient.city}</TableCell>
                      <TableCell>{item.baseProfileRecipient.state}</TableCell>
                      <TableCell>{item.baseProfileRecipient.zipCode}</TableCell>
                      <TableCell>{item.baseProfileRecipient.birthDay}</TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: '#f3f5fb', borderBottom: '5px solid white' }}>
                      <TableCell>
                        <Checkbox
                          sx={{ '.MuiSvgIcon-root': { bgcolor: '#FFF' } }}
                          checked={
                            !!isChecked(ResolveProfileEnrichmentConflicts.Enriched, item.baseProfileRecipient.id)
                          }
                          onChange={(e) => setEnrichedSelected(item.baseProfileRecipient.id, e.target.checked)}
                        />
                      </TableCell>
                      <TableCell>{item.enrichedProfileRecipient.firstName}</TableCell>
                      <TableCell>{item.enrichedProfileRecipient.lastName}</TableCell>
                      <TableCell>{item.enrichedProfileRecipient.address1}</TableCell>
                      <TableCell>{item.enrichedProfileRecipient.address2}</TableCell>
                      <TableCell>{item.enrichedProfileRecipient.city}</TableCell>
                      <TableCell>{item.enrichedProfileRecipient.state}</TableCell>
                      <TableCell>{item.enrichedProfileRecipient.zipCode}</TableCell>
                      <TableCell>
                        {item.enrichedProfileRecipient.birthDay
                          ? formatDate(item.enrichedProfileRecipient.birthDay)
                          : ''}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          )}
          {!isFetching && isSuccess && conflicts?.result?.length === 0 && (
            <Box
              minHeight={'10vh'}
              overflow="hidden"
              display="flex"
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
            >
              <Alert severity="success" sx={{ mb: 2 }}>
                <Typography fontSize={16}>Congratulations!!! All conflicts have been resolved.</Typography>
              </Alert>
            </Box>
          )}
        </Box>
        <Box maxHeight={'50vh'} overflow="auto" display="flex" alignItems={'end'} justifyContent={'end'} width={'100%'}>
          <table style={{ width: '300px', fontSize: 14, borderCollapse: 'collapse', marginTop: 10 }}>
            <tr>
              <td style={{ width: '150px' }}>Current recipient</td>
              <td style={{ backgroundColor: '#f05a2a33' }}>&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td style={{ width: '150px' }}>Enriched recipient</td>
              <td style={{ backgroundColor: '#f3f5fb' }}>&nbsp;&nbsp;</td>
            </tr>
          </table>
        </Box>
        <MDButton sx={{ alignSelf: 'flex-end' }} onClick={() => resolve()} disabled={conflicts?.result?.length === 0}>
          Save Changes
        </MDButton>
      </Stack>
    </MDCard>
  );
}
