import { useState } from 'react';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { DeleteOutline, Edit, PlaylistAdd } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Box, Grid, IconButton, Snackbar, Tooltip, Typography } from '@mui/material';
import { actionColumnProps } from 'components/table/table.utils';
import useDiscountCodesService from 'services/discount-codes';
import { formatDate } from 'helpers/formatters';
import StyledDataGrid from 'components/styled-data-grid';
import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import DiscountCodesUpsertModal, { DiscountCodesUpsertModalProps } from './components/discount-codes-upsert-modal';
import Table from 'components/table';
import InfoModal from 'components/info-modal';
import { ICoupon, IMultiUseCoupon, ISingleUseList } from 'services/discount-codes/discount-codes.types';
import { IObjectResult } from 'services/endpoint';
import MDTypography from 'material-ui/components/MDTypography';

export default function DiscountCodes() {
  const queryClient = useQueryClient();
  const [multiOrListToDelete, setMultiOrListToDelete] = useState<IMultiUseCoupon | ISingleUseList>();
  const [couponsToDelete, setCouponsToDelete] = useState<number[]>([]);
  const [expandedListId, setExpandedListId] = useState<number | null>(null);
  const [modalProps, setModalProps] = useState<DiscountCodesUpsertModalProps>({ open: false });
  const [deleteModal, setDeleteModal] = useState<string>('');
  const [deleteError, setDeleteError] = useState<string>('');

  const {
    getAllDiscountCodes,
    getCouponByList,
    deleteMultiUseCoupon,
    deleteSingleUseCoupon,
    deleteCouponsFromSingleUseList,
  } = useDiscountCodesService();

  const { data: discountCodesData, isFetching } = useQuery({
    queryFn: () => getAllDiscountCodes(true),
    queryKey: ['getAllDiscountCodes'],
  });
  const { multiUseCoupon = [], singleUseCouponList = [] } = discountCodesData?.payload || {};

  const { data: couponsData, isFetching: isFetchingCoupons } = useQuery({
    queryFn: () => (expandedListId ? getCouponByList(expandedListId) : { payload: [] as ICoupon[] }),
    queryKey: ['getCouponByList', expandedListId],
  });
  const coupons = couponsData?.payload || [];
  const allCouponsSelected =
    couponsToDelete?.length && couponsToDelete.length === coupons?.filter((c) => !c.isUsed).length;

  const onSuccess = (response: IObjectResult<{}>) => {
    if (response?.hasErrors) setDeleteError(response.errors?.[0] ?? 'Something went wrong.');
    else {
      queryClient.invalidateQueries({ queryKey: ['getAllDiscountCodes'] });
      if (expandedListId) queryClient.invalidateQueries({ queryKey: ['getCouponByList', expandedListId] });
      if (couponsToDelete.length) setCouponsToDelete([]);
    }
  };

  const { mutate: deleteMultiUse, isPending: isDeletingMultiUse } = useMutation({
    mutationFn: deleteMultiUseCoupon,
    onSuccess,
  });

  const { mutate: deleteSingleUse, isPending: isDeletingSingleUse } = useMutation({
    mutationFn: deleteSingleUseCoupon,
    onSuccess,
  });

  const { mutate: deleteCoupons, isPending: isDeletingCoupon } = useMutation({
    mutationFn: deleteCouponsFromSingleUseList,
    onSuccess,
  });

  const disabledRow = (row: ICoupon) => row.isUsed;

  const expirationColumn: GridColDef = {
    headerName: 'Expiration',
    field: 'expirationDate',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: ({ value }) => (value ? formatDate(value) : '-'),
  };

  const multiUseColumns: GridColDef[] = [
    { headerName: 'Code', field: 'name', flex: 1 },
    expirationColumn,
    {
      ...actionColumnProps,
      getActions: ({ row }: GridRowParams) => [
        <IconButton
          onClick={() => {
            setModalProps({ open: true, actionType: 'edit', discountType: 'multiUse', initialValues: row });
          }}
        >
          <Edit />
        </IconButton>,
        <IconButton
          color="error"
          disabled={!row.canDelete}
          onClick={() => {
            setMultiOrListToDelete(row);
            setDeleteModal('multi');
          }}
        >
          <DeleteOutline />
        </IconButton>,
      ],
    },
  ];

  const singleUseColumns: GridColDef[] = [
    { headerName: 'Code', field: 'name', flex: 1 },
    {
      headerName: 'Used / Total',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      field: 'totalCoupons',
      valueGetter: ({ row }) => `${row.usedCoupons || '0'} / ${row.totalCoupons || '0'}`,
    },
    expirationColumn,
    {
      ...actionColumnProps,
      width: 150,
      getActions: ({ row }: GridRowParams) => [
        <IconButton
          onClick={() => {
            setModalProps({
              open: true,
              actionType: 'edit',
              discountType: 'singleUse',
              initialValues: row,
              isCouponsStep: true,
            });
          }}
        >
          <PlaylistAdd />
        </IconButton>,
        <IconButton
          onClick={() => {
            setModalProps({
              open: true,
              actionType: 'edit',
              discountType: 'singleUse',
              initialValues: row,
              isCouponsStep: false,
            });
          }}
        >
          <Edit />
        </IconButton>,
        <IconButton
          color="error"
          disabled={!row.canDelete}
          onClick={() => {
            setMultiOrListToDelete(row);
            setDeleteModal('single');
          }}
        >
          <DeleteOutline />
        </IconButton>,
      ],
    },
  ];

  const deleteModalConfirmAction = () => {
    if (deleteModal === 'coupons') deleteCoupons({ couponIds: couponsToDelete, listId: expandedListId! });
    else if (deleteModal === 'single') deleteSingleUse(multiOrListToDelete?.id!);
    else deleteMultiUse(multiOrListToDelete?.id!);
    setMultiOrListToDelete(undefined);
    setDeleteModal('');
  };

  const deleteModalHeaderText = () => {
    let deleteModalHeader = '';
    if (deleteModal === 'coupons') {
      const amount = couponsToDelete.length;
      const listName = singleUseCouponList.find((el) => el.id === expandedListId)?.name;
      deleteModalHeader = `${amount} single use coupon${amount > 1 ? 's' : ''} from the list ${listName}?`;
    } else {
      const listType = deleteModal === 'multi' ? 'multi use coupon' : 'single use coupon list';
      deleteModalHeader = `the ${listType} ${multiOrListToDelete?.name}?`;
    }
    return `Are you sure you want to delete ${deleteModalHeader}`;
  };

  const renderToolbar = (discountType: 'multiUse' | 'singleUse') => {
    const toolbarOptions = {
      multiUse: { title: 'Multi Use Coupons', buttonText: 'New Coupon' },
      singleUse: { title: 'Single Use Coupon Lists', buttonText: 'New Coupon List' },
    };
    return (
      <Box m={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" fontSize={16}>
          {toolbarOptions[discountType].title}
        </Typography>
        <MDButton size="small" onClick={() => setModalProps({ open: true, actionType: 'create', discountType })}>
          {toolbarOptions[discountType].buttonText}
        </MDButton>
      </Box>
    );
  };

  return (
    <MDCard sx={{ position: 'relative', margin: '.25rem'}}>
      <Box px={4}>
        <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
          <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
              Discount Codes
          </MDTypography>
        </Grid>
        <Box sx={{ margin: '2rem 0'}}>
          <StyledDataGrid
            initialState={{ pagination: { paginationModel: { pageSize: 100 } } }}
            pagination
            disableColumnMenu
            disableRowSelectionOnClick
            loading={isFetching || isDeletingMultiUse}
            rows={multiUseCoupon}
            columns={multiUseColumns}
            pageSizeOptions={[25, 50, 100]}
            density="compact"
            slots={{ toolbar: () => renderToolbar('multiUse') }}
          />
          <Box sx={{ margin: '1rem 0'}} />
          <StyledDataGrid
            initialState={{ pagination: { paginationModel: { pageSize: 100 } } }}
            pagination
            disableColumnMenu
            disableRowSelectionOnClick
            loading={isFetching || isDeletingSingleUse}
            rows={singleUseCouponList}
            columns={singleUseColumns}
            pageSizeOptions={[25, 50, 100]}
            density="compact"
            slots={{ toolbar: () => renderToolbar('singleUse') }}
            detailPanelExpandedRowIds={expandedListId ? [expandedListId] : []}
            getDetailPanelHeight={() => 'auto'}
            onDetailPanelExpandedRowIdsChange={(ids) =>
              setExpandedListId(ids?.length ? Number(ids[ids.length - 1]) : null)
            }
            getDetailPanelContent={({ row }) => (
              <Box m={1}>
                <Table
                  rows={coupons}
                  columns={[
                    { field: 'name', headerName: 'Code', flex: 1 },
                    { field: 'isUsed', headerName: 'Used', type: 'boolean' },
                  ]}
                  density="compact"
                  tableHeight={'45vh'}
                  setAllSelected={() => {}}
                  setSearchParams={() => {}}
                  totalRowCount={coupons.length}
                  selectedRows={couponsToDelete}
                  allSelected={allCouponsSelected || false}
                  setSelectedRows={setCouponsToDelete}
                  disabledRow={(row) => disabledRow(row)}
                  isLoading={isFetchingCoupons || isDeletingCoupon}
                  sx={{ '& .MuiDataGrid-columnHeaders': { backgroundColor: 'darkgray' } }}
                  renderToolbarFilterRight={() => (
                    <Grid item flex={1} display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="h5" m={1}>
                        {row.name} Coupons
                      </Typography>
                      <Tooltip
                        title={
                          !couponsToDelete.length
                            ? 'First select an unused coupon'
                            : `Remove ${allCouponsSelected ? 'all' : couponsToDelete.length} selected`
                        }
                      >
                        <Box>
                          <IconButton
                            color="error"
                            disabled={!couponsToDelete.length}
                            onClick={() => setDeleteModal('coupons')}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
      <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />

      <DiscountCodesUpsertModal {...modalProps} onClose={() => setModalProps((prev) => ({ ...prev, open: false }))} />
      <InfoModal
        showCancelButton
        showConfirmButton
        show={!!deleteModal}
        setShow={() => setDeleteModal('')}
        cancelButtonOnClick={() => setDeleteModal('')}
        confirmButtonOnClick={() => deleteModalConfirmAction()}
        headerText={deleteModalHeaderText()}
      />
      <Snackbar
        open={!!deleteError}
        autoHideDuration={6000}
        onClose={() => setDeleteError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setDeleteError('')} severity="error" variant="standard" sx={{ py: 0 }}>
          {deleteError}
        </Alert>
      </Snackbar>
    </MDCard>
  );
}
