import { AdminPanelSettings } from '@mui/icons-material';

import AdminFeedbackPage from 'pages/admin/feedback';
import AdminCustomerSuccessDashboardPage from 'pages/admin/customer-success';
import AdminSalesRevenueDashboard from 'pages/admin/sales-revenue';
import AdminSoftwareAdoption from 'pages/admin/software-adoption';
import AdminChangeOrderStatusPage from 'pages/superadmin/change-order-status';
import AdminOrderTracking from 'pages/admin/order-tracking';
import AdminOrdersPage from 'pages/superadmin/order';

import { RouteType } from './types';

const sidebarAdminRoutes: RouteType[] = [
  {
    icon: <AdminPanelSettings />,
    name: 'Admin',
    collapse: [
      {
        name: 'Customer Feedback',
        route: '/admin/feedback',
        component: <AdminFeedbackPage />,
      },
      {
        name: 'Customer Success',
        route: '/admin/customer-success',
        component: <AdminCustomerSuccessDashboardPage />,
      },
      {
        name: 'Sales Dashboard',
        route: '/admin/sales-revenue',
        component: <AdminSalesRevenueDashboard />,
      },
      {
        name: 'Software Adoption',
        route: '/admin/software-adoption',
        component: <AdminSoftwareAdoption />,
      },
      {
        name: 'Change Order Status',
        route: '/admin/order-statuses',
        component: <AdminChangeOrderStatusPage />,
      },
      {
        name: 'Order',
        route: '/admin/order',
        component: <AdminOrdersPage />,
      },
      {
        name: 'Order Tracking',
        route: '/admin/order-tracking',
        component: <AdminOrderTracking />,
      },
    ],
  },
];

export default sidebarAdminRoutes;
