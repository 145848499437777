import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CSVBoxButton } from '@csvbox/react';
import { GroupAdd } from '@mui/icons-material';
import { Divider, Menu, MenuItem } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import { IOrderRecipients } from 'models/orderRecipient';
import { IOrder } from 'newStandard/src/services/order/types';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import AddRecipientsFromRecipientSearchModal from './AddRecipientsFromRecipientSearchModal';
import AddRecipientsFromAddressBookModal from './AddRecipientsFromAddressBookModal';
import UpsertRecipientModal from './UpsertRecipientModal';
import { getUserData } from 'helpers/getUserInfos';

interface IImportCSVData {
  row_success: string;
  rows: IOrderRecipients;
  raw_file: string;
  row_count: number;
}

interface AddRecipientsButtonProps {
  onRecipientsSubmit: () => void;
}

export default function AddRecipientsButton({ onRecipientsSubmit }: AddRecipientsButtonProps) {
  const params = useParams();
  const orderId = parseInt(params.id || '');
  const { setTemplate } = useWorkflowContext<IOrder>();
  const [anchorButton, setAnchorButton] = useState<HTMLElement>();
  const [showModal, setShowModal] = useState<string>('');

  const handleCSVImport = (result: boolean, data: IImportCSVData) => {
    setAnchorButton(undefined);
    if (!result) return alert('Upload failed. Please try again.');
    onRecipientsSubmit();
    setTemplate((prev) => ({
      ...prev,
      recipientsUploaded: true,
      recipientsCsvBoxUrl: data.raw_file,
      orderVolume: data.row_count,
    }));
  };

  const handleOptionClick = (option: string | (() => void)) => {
    setAnchorButton(undefined);
    if (typeof option === 'string') setShowModal(option);
    else option();
  };

  const modalProps = (modal: string) => ({
    show: showModal === modal,
    onClose: () => setShowModal(''),
    onSubmit: onRecipientsSubmit,
  });

  return (
    <>
      <MDButton onClick={(evt) => setAnchorButton(evt.currentTarget)}>
        <GroupAdd sx={{ mr: 1 }} />
        Add Recipients
      </MDButton>
      {getUserData()?.id && <CSVBoxButton
        licenseKey={process.env.REACT_APP_CSVBOX_LICENCE ?? ''}
        user={{ user_id: 'LettrLabsCsvBoxAdmin', order_id: orderId, profile_id: getUserData()?.id }}
        onImport={handleCSVImport}
        render={(launch) => (
          <Menu
            anchorEl={anchorButton}
            open={!!anchorButton}
            onClose={() => setAnchorButton(undefined)}
            disableAutoFocus
          >
            <MenuItem disabled>Add recipients from:</MenuItem>
            <Divider light />
            <MenuItem onClick={() => handleOptionClick('addressBook')}>Address Book</MenuItem>
            <MenuItem onClick={() => handleOptionClick(launch)}>Upload Spreadsheet</MenuItem>
            <MenuItem onClick={() => handleOptionClick('mailingList')}>Mailing List</MenuItem>
            <MenuItem onClick={() => handleOptionClick('singleRecipient')}>Single Recipient</MenuItem>
          </Menu>
        )}
      />}
      
      <AddRecipientsFromAddressBookModal {...modalProps('addressBook')} />
      <AddRecipientsFromRecipientSearchModal {...modalProps('mailingList')} />
      <UpsertRecipientModal {...modalProps('singleRecipient')} />
    </>
  );
}
