import { Autocomplete, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FormikErrorMessage from "components/formik-error-message";
import TemplateScenePreview from "components/template-scene-preview";
import { useGlobal } from "context/global-context";
import { Field, FieldProps, Form, Formik } from "formik";
import MDButton from "material-ui/components/MDButton";
import { copyTemplateValidation } from "models/modelValidations/copyTemplate";
import { Template } from "models/template";
import { useSnackbar } from "notistack";
import {  useEffect, useState } from "react";
import ProfileService from "services/profile";
import { ISelectedProfile } from "services/profile/profile.types";
import TemplateService from "services/template";

interface CopyTemplateParams {
  templateName: string,
  userIdToCopyTo: number | null
}


interface CopyTemplateModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  template: Template
}

const CopyTemplateModal = ({ open, setOpen, template }: CopyTemplateModalProps) => {
  const queryClient = useQueryClient()
  const { setShowLoader } = useGlobal()
  const { enqueueSnackbar } = useSnackbar();
  const { createTemplateFromOrder } = TemplateService()
  const { getLoggedInProfile, getProfilesByFilter } = ProfileService()
  const [ autocompleteOptions, setAutocompleteOptions ] = useState<ISelectedProfile[] | any>([]);
  const [ userToCopyTo, setUserToCopyTo ] = useState<ISelectedProfile | null>(null)
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetchedSuccessfully, setHasFetchedSuccessfully] = useState(false);


  const { data: profile } = useQuery({
    queryKey: ["loggedInProfile"],
    queryFn: getLoggedInProfile,
  })

  const { mutate: copyTemplate } = useMutation({
    mutationFn: ({ templateName }: CopyTemplateParams) => createTemplateFromOrder(template.id, templateName, userToCopyTo?.id ?? null),
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      setOpen(false)
      setShowLoader(false)
      enqueueSnackbar('Template successfully copied', { variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ["templates"] })
    }
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 3 && !hasFetchedSuccessfully) {
        setIsLoading(true);
        getProfilesByFilter(searchTerm)
          .then((profiles) => {
            setAutocompleteOptions(profiles.payload || []);
            setHasFetchedSuccessfully(true);
          })
          .catch(() => {
            setHasFetchedSuccessfully(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (searchTerm.length < 3) {
        setAutocompleteOptions([]);
        setIsLoading(false);
        setHasFetchedSuccessfully(false);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, getProfilesByFilter, hasFetchedSuccessfully]);

  const handleInputChange = (e: any, newInputValue: string) => {
    if (e?.type === "change") {
      setSearchTerm(newInputValue);
      setHasFetchedSuccessfully(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"md"} fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ templateName: "", userIdToCopyTo: null }}
          validationSchema={copyTemplateValidation}
          onSubmit={(values) => copyTemplate(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <DialogTitle>Copy template</DialogTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Field name={"templateName"}>
                    {({ field, meta }: FieldProps) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          type="text"
                          label={"Template Name"}
                          InputLabelProps={{ shrink: true }}
                        />
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>
                {profile?.isAdmin && !profile?.actingAsUserId && (
                  <Grid item xs={12} md={7}>
                    <Field name={"userIdToCopyTo"}>
                      {({ field }: FieldProps) => (
                       <Autocomplete
                       options={autocompleteOptions}
                       value={userToCopyTo}
                       onChange={(e, newValue) => {
                        setUserToCopyTo(newValue)
                       }}   
                       noOptionsText="Type at least 3 characters to find a User"                       
                       onInputChange={handleInputChange}
                       getOptionLabel={(option) =>
                         typeof option === "string"
                           ? option
                           : `${option.firstName} ${option.lastName} - ${option.email}`
                       }
                       loading={isLoading}
                       renderInput={(params) => (
                         <TextField
                           {...params}
                           label="User to copy template to"
                           placeholder="Myself"
                           InputProps={{
                             ...params.InputProps,
                             endAdornment: (
                               <>
                                 {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                 {params.InputProps.endAdornment}
                               </>
                             ),
                           }}
                         />
                       )}
                     />
                     
                      
                      )}
                    </Field>
                  </Grid>
                 )}
                <Grid item>
                  <TemplateScenePreview {...template} />
                </Grid>
                <Grid item container justifyContent="flex-end" gap={1}>
                  <MDButton color="light" onClick={() => setOpen(false)}>
                    Cancel
                  </MDButton>
                  <MDButton type="submit">
                    Copy
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CopyTemplateModal;