import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import {Dialog, DialogContent, Stack, Typography} from "@mui/material";
import {Template} from "models/template";
import { Box } from "@mui/system";

import NoPictureIcon from 'assets/images/no-picture.png';

interface ShowTemplateImagesModalProps {
    show: boolean
    setShow: Function
    template: Template
}

function ShowTemplateImagesModal({show, setShow, template}: ShowTemplateImagesModalProps) {
    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "600px"}}>
            <MDBox>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <MDTypography variant="h4" fontWeight="bold" color="text">
                        Preview
                    </MDTypography>
                    <MDTypography sx={{ fontSize: '16px' }} variant="h4" fontWeight="bold" color="text">
                        Template ID: {template.id}
                    </MDTypography>

                </Box>
                <Typography fontWeight={'bold'} marginTop={'-5px'}>
                    {template.name}
                </Typography>
                <MDTypography variant="button" color="text">
                    {template.product}
                </MDTypography>
            </MDBox>

            <Stack direction="row" justifyContent="space-between" spacing={2} mt={2}>
                <MDBox
                    width={"50%"}
                    minHeight={'192px'}
                    sx={{
                        borderColor: "gray",
                        boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    }}
                    className={"pdf-full-height"}
                > 
                {
                    template.sceneFrontThumb ? (
                        <img alt={""} style={{width: "100%", height: "100%"}} src={template.sceneFrontThumb}/>
                    ) : (
                        <Box sx={{ display: 'flex', opacity: .8, alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                            <img width={'80px'} src={NoPictureIcon} alt="Template not finished" />
                            <Typography variant="body2">Template Not Completed</Typography>
                        </Box>
                    )
                }
                </MDBox>
                <MDBox
                    width={"50%"}
                    sx={{
                        borderColor: "gray",
                        boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    }}
                    className={"pdf-full-height"}
                >
                    {
                        template.sceneBackThumb ? (
                            <img alt={""} style={{width: "100%", height: "100%"}} src={template.sceneBackThumb}/>
                        ) : (
                            <Box sx={{ display: 'flex', opacity: .8, alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                <img width={'80px'} src={NoPictureIcon} alt="Template not finished" />
                                <Typography variant="body2">Template Not Completed</Typography>
                            </Box>
                        )
                    }
                </MDBox>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default ShowTemplateImagesModal