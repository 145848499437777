import { ConfigTypes, Configuration } from '@cesdk/cesdk-js';

const adminDefaultConfig: Configuration = {
  license: process.env.REACT_APP_IMGLYLICENCE,
  baseURL: 'https://cdn.img.ly/packages/imgly/cesdk-js/1.42.0/assets',
  userId: 'guides-user',
  theme: 'light',
  role: 'Adopter',
  logger: (message, logLevel) => console.log(`${logLevel}: ${message}}`),
};

export const adminCardConfig = (onUpload: ConfigTypes.OnUploadCallback): Configuration => ({
  ...adminDefaultConfig,
  i18n: {
    en: {
      'libraries.ly.img.image.ly.img.image.upload.label': 'Past Images',
      'libraries.ly.img.image.brand.images.label': 'Brand Images',
      'libraries.card.designs.label': 'Card Designs',
      'libraries.card.designs.Birthday.label': 'Birthday',
      'libraries.card.designs.Christmas.label': 'Christmas',
      'libraries.card.designs.Fathers Day.label': 'Fathers Day',
      'libraries.card.designs.Graduation.label': 'Graduation',
      'libraries.card.designs.Happy Holidays.label': 'Happy Holidays',
      'libraries.card.designs.Invitations.label': 'Invitations',
      'libraries.card.designs.Mothers Day.label': 'Mothers Day',
      'libraries.card.designs.Real Estate.label': 'Real Estate',
      'libraries.card.designs.Thank You.label': 'Thank You',
      'libraries.my.templates.confirmation.headline': 'Confirm Template Selection',
      'libraries.my.templates.confirmation.body':
        'Selecting a new template will discard all current content. This action cannot be undone.',
      'libraries.my.templates.confirmation.abort': 'Cancel',
      'libraries.my.templates.confirmation.confirm': 'Confirm and Select New Template',
    },
  },
  ui: {
    scale: 'large',
    elements: {
      view: 'default',
      navigation: { show: true },
      panels: { inspector: true, settings: true },
      libraries: {},
      blocks: { transform: true },
    },
  },
  callbacks: { onUpload },
});

export const envelopeConfig: Configuration = {
  ...adminDefaultConfig,
  ui: {
    elements: {
      navigation: { show: true },
      panels: { settings: true, assetLibrary: true, inspector: true },
    },
  },
};

export const adminBasicOrderConfig = [
  'lettrLabs.dock',
  'returnAddress.dock',
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.template',
    icon: '@imgly/Template',
    label: 'libraries.ly.img.template.label',
    entries: ['ly.img.template'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.text',
    icon: '@imgly/Text',
    label: 'libraries.ly.img.text.label',
    entries: ['ly.img.text'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.image',
    icon: '@imgly/Image',
    label: 'libraries.ly.img.image.label',
    entries: ['ly.img.image'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'card.designs',
    icon: '@imgly/Image',
    label: 'libraries.card.designs.label',
    entries: ['card.designs'],
  },
];

export const adminAdvancedDockOrder = [
  'lettrLabs.dock',
  'returnAddress.dock',
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.template',
    icon: '@imgly/Template',
    label: 'libraries.ly.img.template.label',
    entries: ['ly.img.template'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.elements',
    icon: '@imgly/Library',
    label: 'component.library.elements',
    entries: ['ly.img.image', 'card.designs', 'ly.img.text', 'ly.img.vectorpath', 'cutout-entry'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.text',
    icon: '@imgly/Text',
    label: 'libraries.ly.img.text.label',
    entries: ['ly.img.text'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.image',
    icon: '@imgly/Image',
    label: 'libraries.ly.img.image.label',
    entries: ['ly.img.image'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'card.designs',
    icon: '@imgly/Image',
    label: 'libraries.card.designs.label',
    entries: ['card.designs'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.vectorpath',
    icon: '@imgly/Shapes',
    label: 'libraries.ly.img.vectorpath.label',
    entries: ['ly.img.vectorpath'],
  },
  {
    id: 'ly.img.assetLibrary.dock',
    key: 'ly.img.sticker',
    icon: '@imgly/Sticker',
    label: 'libraries.ly.img.sticker.label',
    entries: ['ly.img.sticker'],
  }
];

export const adminBasicInspectorBar = [
  'img.fullBleed',

  'ly.img.separator',

  'ly.img.text.typeFace.inspectorBar',
  'ly.img.text.fontSize.inspectorBar',
  'ly.img.shape.options.inspectorBar',
  'ly.img.cutout.type.inspectorBar',
  'ly.img.cutout.offset.inspectorBar',
  'ly.img.cutout.smoothing.inspectorBar',
  'ly.img.group.create.inspectorBar',
  'ly.img.group.ungroup.inspectorBar',
  'ly.img.audio.replace.inspectorBar',

  'ly.img.separator',

  'ly.img.text.bold.inspectorBar',
  'ly.img.text.italic.inspectorBar',
  'ly.img.text.alignHorizontal.inspectorBar',
  'ly.img.combine.inspectorBar',

  'ly.img.separator',

  'ly.img.fill.inspectorBar',
  'ly.img.trim.inspectorBar',
  'ly.img.volume.inspectorBar',
  'ly.img.crop.inspectorBar',

  'ly.img.separator',

  'ly.img.stroke.inspectorBar',

  'ly.img.separator',

  'ly.img.adjustment.inspectorBar',
  'ly.img.filter.inspectorBar',
  'ly.img.effect.inspectorBar',
  'ly.img.blur.inspectorBar',

  'ly.img.separator',

  'ly.img.shadow.inspectorBar',

  'ly.img.spacer',
  'ly.img.separator',

  'ly.img.position.inspectorBar',

  'ly.img.separator',

  'ly.img.options.inspectorBar',

  'ly.img.separator',

  'editor.mode',
];

export const adminAdvancedInspectorBar = [
  'img.fullBleed',

  'ly.img.separator',

  'ly.img.text.typeFace.inspectorBar',
  'ly.img.text.fontSize.inspectorBar',
  'ly.img.shape.options.inspectorBar',
  'ly.img.cutout.type.inspectorBar',
  'ly.img.cutout.offset.inspectorBar',
  'ly.img.cutout.smoothing.inspectorBar',
  'ly.img.group.create.inspectorBar',
  'ly.img.group.ungroup.inspectorBar',
  'ly.img.audio.replace.inspectorBar',

  'ly.img.separator',

  'ly.img.text.bold.inspectorBar',
  'ly.img.text.italic.inspectorBar',
  'ly.img.text.alignHorizontal.inspectorBar',
  'ly.img.combine.inspectorBar',

  'ly.img.separator',

  'ly.img.fill.inspectorBar',
  'ly.img.trim.inspectorBar',
  'ly.img.volume.inspectorBar',
  'ly.img.crop.inspectorBar',

  'ly.img.separator',

  'ly.img.stroke.inspectorBar',

  'ly.img.separator',

  'ly.img.adjustment.inspectorBar',
  'ly.img.filter.inspectorBar',
  'ly.img.effect.inspectorBar',
  'ly.img.blur.inspectorBar',

  'ly.img.separator',

  'ly.img.shadow.inspectorBar',

  'ly.img.spacer',
  'ly.img.separator',

  'ly.img.position.inspectorBar',

  'ly.img.separator',

  'ly.img.options.inspectorBar',

  'ly.img.separator',

  'editor.mode',
];

export const adminTextModeCanvasMenuOrder = [
  'ly.img.text.color.canvasMenu',

  'ly.img.separator',

  'ly.img.text.bold.canvasMenu',
  'ly.img.text.italic.canvasMenu',
];
