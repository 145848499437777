import React, { useState } from 'react';

import { Box } from '@mui/system';
import { Status } from 'pages/automations/context/types';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { format, subDays } from 'date-fns'
import { useAutomationContext } from 'pages/automations/context';
import { Dialog, Skeleton, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import CloseIcon from 'assets/icons/close';
import MDDatePicker from 'material-ui/components/MDDatePicker';
import useAutomationsService from 'services/automations';


const UpdateStatusModal: React.FC = () => {
    
    const { modalState, toggleModal, selectedAutomation, handleUpdateListAfterUpdteStatus, statusUpdateInfo } = useAutomationContext();
    
    const { enqueueSnackbar } = useSnackbar();
    const { updateStatus } = useAutomationsService();
    
    const [loading, setLoading] = useState(false);
    const [refreshDate, setRefreshDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'));

    const mutation = useMutation({
        mutationFn: () => updateStatus(selectedAutomation?.id ?? 0, selectedAutomation?.integrationOrderType ?? 0, statusUpdateInfo.nextStatus, refreshDate),
        onMutate: () => setLoading(true),
        onSuccess: (data) => {
            setLoading(false);
            toggleModal('isUpdateStatusModalOpen', false);
            if(!data.payload.Message) {
                enqueueSnackbar('Automation status successfully updated.', { variant: 'success' } );
                handleUpdateListAfterUpdteStatus(selectedAutomation?.id ?? 0, statusUpdateInfo.nextStatus as Status);
            } else {
                enqueueSnackbar('Something went wrong, please try again later.', { variant: 'error' } );    
            }
        },
        onError: (err) => {
          setLoading(false);
        },
    });

    const handleConfirm = async() => {
        mutation.mutate();
    }

    return (
        <Dialog maxWidth={'md'} fullWidth open={modalState.isUpdateStatusModalOpen} onClose={() => toggleModal('isUpdateStatusModalOpen', false)}>
            <Box sx={{ display: 'flex', position: 'relative', padding: '2rem 2rem 0 2rem' }}>
                <Typography variant="h4" mb={2} pb={2} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                    Update status
                </Typography>
                <CloseIcon data-testid="update-status-modal-close-btn" sx={{ opacity: .6, position: 'absolute', right: 28}} onClick={() => toggleModal('isUpdateStatusModalOpen', false)} />
            </Box>
            <Box sx={{ padding: '0 2rem'}}>
                {
                    statusUpdateInfo.loading ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Skeleton width={'100%'} height={35} />
                            <Skeleton width={'90%'} height={35} />
                            <Skeleton width={'93%'} height={35} />
                            <Skeleton width={'30%'} height={35} />
                        </Box>
                    ) : (
                        <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap', lineHeight: '2rem', margin: '1rem 0 0 0' }}>
                            {statusUpdateInfo.statusMessageToShowBeforeSave.replace(/\/n/g, '\n')}
                        </Typography>
                    )
                }
            </Box>
            <Box>
                {
                    !statusUpdateInfo.loading && statusUpdateInfo.requiresRetroactiveDate && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', margin: '2rem auto 0 auto'}}>
                            <Typography variant='h5' sx={{ margin: '.5rem 0'}} data-testid="select-date-message">Select how far back to add recipients to your segment</Typography>
                            <MDDatePicker
                                data-testid="date-selector-input"
                                options={{ minDate: subDays(new Date(), 90), maxDate: new Date() }}
                                onChange={(_: Date, dateStr: string) => setRefreshDate(dateStr)}
                                input={{
                                    value: refreshDate,
                                    placeholder: 'MM/DD/YYYY',
                                    helperText: `Max ${90} days in the past.`,
                                }}
                            />
                        </Box>
                    )
                }
            </Box>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', padding: '2rem'}}>
                <MDButton 
                    color='light'
                    data-testid="update-status-modal-cancel-btn"
                    onClick={() => toggleModal('isUpdateStatusModalOpen', false)} 
                    sx={{ borderRadius: '5px !important' }} 
                    disabled={loading || statusUpdateInfo.loading}>
                    Cancel
                </MDButton>
                <MDButton 
                    data-testid="uodate-status-modal-confirm-btn"
                    onClick={handleConfirm} 
                    sx={{ borderRadius: '5px !important' }} 
                    disabled={loading || statusUpdateInfo.loading}>
                    Update
                </MDButton>
            </Box>
        </Dialog>
    );
}

export default UpdateStatusModal;