export function getUserData() {
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('Token não encontrado no localStorage.');
      return null;
    }
  
    // Divide o token em suas três partes (header, payload e signature)
    const parts = token.split('.');
  
    if (parts.length !== 3) {
      console.error('Token JWT inválido.');
      return null;
    }
  
    const decodeBase64Url = (base64Url: string) => {
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const decoded = atob(base64);
      return JSON.parse(decoded);
    };
  
    const payload = decodeBase64Url(parts[1]);
  
    return payload;
  }
  