import { Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ILeadRevealSourcePayload, ISetLeadRevealPayload } from 'services/data-sources/data-sources.types';
import { leadRevealFinishSchema } from 'services/data-sources/data-sources.schema';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import useDataSourcesService from 'services/data-sources';
import { isLettrLabsUser } from 'helpers/profileHelper';
import MDButton from 'material-ui/components/MDButton';
import { useGlobal } from 'context/global-context';
import ProfileService from 'services/profile';
import { IProfile } from 'models/profile';
import Loader from 'components/loader';

import LeadRevealFinishForm from './lead-reveal-finish-form';

interface LeadRevealFinishModalProps {
  open: boolean;
  onClose: () => void;
}

export default function LeadRevealFinishModal({ open, onClose }: LeadRevealFinishModalProps) {
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();
  const { getProfile } = ProfileService();
  const [, setSearchParams] = useSearchParams();
  const { getLeadRevealSource, finishLeadRevealSetup } = useDataSourcesService();
  
  const { data, isLoading } = useQuery({ queryKey: ['getLeadRevealSource'], queryFn: getLeadRevealSource });
  const source: ILeadRevealSourcePayload = data?.payload?.payload
    ? convertKeysToCamelCase(JSON.parse(data?.payload?.payload))
    : undefined;

  const { data: profile } = useQuery<IProfile>({ queryKey: ['profile'], queryFn: () => getProfile() });

  const { mutate: finishSetup } = useMutation({
    mutationFn: finishLeadRevealSetup,
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
      setShowLoader(false);
      setSearchParams('modal=SuccessLeadReveal', { replace: true });
    },
  });

  const handleSubmit = (values: ISetLeadRevealPayload) => {
    if (source) onClose();
    finishSetup();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={!!source}>
      <DialogContent>
        <DialogTitle display={'flex'}>
          LeadReveal
          {source && (
            <MDButton
              color="error"
              variant="transparent"
              onClick={() => setSearchParams('modal=RevokeLeadReveal')}
              sx={{ ml: 'auto' }}
            >
              Revoke Setup
            </MDButton>
          )}
        </DialogTitle>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{
              ...source,
              useServiceAccount: source?.useServiceAccount ?? true,
              hasDefaultCreditCard: !!profile?.defaultPaymentMethodId || isLettrLabsUser(profile),
              hasActiveSubscription: !!profile?.hasSubscription || isLettrLabsUser(profile)
            }}
            validationSchema={leadRevealFinishSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formProps) => <LeadRevealFinishForm onClose={onClose} {...formProps} />}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
}
