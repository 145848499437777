// @ts-nocheck
import { useEffect, useState } from 'react';
import { CheckCircleOutline } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Radio,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { IProfile } from 'models/profile';
import OrderService from 'services/order';
import ProfileService from 'services/profile';
import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import { formatPrice } from 'newStandard/src/utils/format';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import { ITemplate } from 'newStandard/src/services/template/types';
import { ProductOptions } from 'newStandard/src/services/order/types';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import useSceneService from 'newStandard/src/features/Editor/services/sceneService';
import { ArtifactType } from 'newStandard/src/features/Editor/services/artifact/types';
import { isLettrLabsUser } from "helpers/profileHelper";
import { cleanTemplate, IProduct, productCategories, ProductDetails } from './ProductStep.utils';

export default function ProductStep() {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const { template, isSaving, nextStep, changeStep, setSaveSteps } = useWorkflowContext<ITemplate>();

  const [product, setProduct] = useState<IProduct>();
  const [name, setName] = useState<string>(template.name || '');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const { getProfile } = ProfileService();
  const { clearScene } = useSceneService();
  const { postOrderParameters } = OrderService();

  const { data: profile } = useQuery<IProfile>({ queryKey: ['profile'], queryFn: getProfile });

  const { mutate: resetParameters, isPending } = useMutation({
    mutationFn: () => postOrderParameters(template.id, []),
    onSuccess: () => {
      handleSubmit();
      setShowWarningModal(false);
    },
  });

  const { mutate: resetScene, isPending: isReseting } = useMutation({
    mutationFn: () => clearScene(template.id, product?.id ?? 0),
    onSuccess: () => resetParameters(),
  });

  const isLoading = isPending || isReseting;

  useEffect(() => {
    setName(template.name);
  }, [template.name]);

  useEffect(() => {
    setSaveSteps((prev) => ({ ...prev, 1: handleNextClick }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSaveSteps]);

  useEffect(() => {
    if (product) return setSelectedImage(product.images[0]);
    productCategories.forEach((category) => {
      const currentProduct = category.products.find((p) => p.name === template.product);
      if (currentProduct) return setProduct(currentProduct);
    });
  }, [template.product, product]);

  const handleCancelProductChange = () => {
    setProduct(undefined);
    setShowWarningModal(false);
  };

  const handleSubmit = () => {
    if (!product) return;
    const updatedTemplate = { ...template, ...cleanTemplate(product.name), name };
    if (template.product === updatedTemplate.product) {
      if (template.name === updatedTemplate.name) changeStep(nextStep ?? 2);
      else changeStep(nextStep ?? 2, { ...template, name });
    } else changeStep(nextStep ?? 2, updatedTemplate);
  };

  const handleNextClick = () => {
    const sceneArtifact = template.orderArtifacts?.find((a) => a.artifactType === ArtifactType.CreativeEditorScene);
    if (product && template.product !== product?.name && sceneArtifact) setShowWarningModal(true);
    else handleSubmit();
  };

  const renderProductCost = (p: IProduct) => {
    if (!profile) return <Skeleton height={20} width={220} />;
    const { subscriptionPricings } = profile.customerSubscription.subscription;
    const price = subscriptionPricings.find((a) => a.productId === p.id)?.price ?? p.priceBeforeAddons;
    return (
      <Typography variant="h5" fontWeight={'regular'} ml="auto">
        as low as {formatPrice(price)} ea before add ons
      </Typography>
    );
  };

  return (
    <MDCard sx={{ p: 2, m: '.5rem' }}>
      <Box mx={'auto'} maxWidth={1500} width={'100%'}>
        <Typography variant="h4" width={'100%'} mb={2}>
          Select a product
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              {productCategories
               .filter(
                  (category) =>
                    category.publiclyAvailable !== false || isLettrLabsUser(profile) === true
               )
              .map((category) => (
                <Grid item flex={1} key={category.id}>
                  <RadioSelectWrapper
                    padding={isXl ? '20px' : '16px'}
                    selected={product?.categoryId === category.id}
                    onClick={() => setProduct(category.products[0])}
                  >
                    <Box>
                      <img alt={''} src={category.icon} />
                      <Typography variant={'h5'} whiteSpace={'break-spaces'}>
                        {category.name}
                      </Typography>
                    </Box>
                  </RadioSelectWrapper>
                </Grid>
              ))}
            </Grid>
            <Stack mt={1} spacing={1}>
              {product &&
                productCategories[product.categoryId].products
                .filter(
                  (product) =>
                    product.publiclyAvailable !== false || isLettrLabsUser(profile) === true
                )
                .map((p) => (
                  <RadioSelectWrapper
                    key={p.id}
                    selected={p.id === product?.id}
                    padding={isXl ? '10px 20px' : '0 10px'}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      checked={p.id === product?.id}
                      onChange={() => setProduct(p)}
                      sx={{ display: 'flex' }}
                      disableTypography
                      label={
                        <Grid container alignItems={'center'} justifyContent={'space-between'} py={1}>
                          <Grid item mr={1}>
                            <Typography variant="h5">{p.label}</Typography>
                          </Grid>
                          <Grid item>{renderProductCost(p)}</Grid>
                        </Grid>
                      }
                    />
                  </RadioSelectWrapper>
                ))}
            </Stack>
            <Stack spacing={1} mt={2}>
              <Typography variant="h4" fontSize={18}>
                Product Details
              </Typography>
              {Object.values(ProductDetails).map((title) => (
                <Box key={title} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                  <CheckCircleOutline color="primary" />
                  <Typography variant={'h5'} mx={1}>
                    {title}:
                  </Typography>
                  <Typography variant={'h5'} fontWeight={'normal'}>
                    {product ? product.details[title].bullet : <Skeleton width={100} />}
                  </Typography>
                </Box>
              ))}
              {product && product.name !== ProductOptions.HandwrittenBiFoldCard && (
                <Link fontSize={16} fontWeight={'bold'} href={product.designSpecsUrl} color={'primary'} target="_blank">
                  Click here for Design Specs
                </Link>
              )}
            </Stack>
            <Typography variant="h4" fontSize={18} mt={2} mb={1}>
              Name
            </Typography>
            <TextField
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText="You can give it a name to help identify it later."
            />
          </Grid>
          <Grid item display={'flex'} xs={12} md={6}>
            <Stack spacing={1} flex={1}>
              <Paper variant="outlined" sx={{ borderColor: '#EDEFF5' }}>
                {selectedImage ? (
                  <img alt="" width="100%" height="350px" style={{ objectFit: 'contain' }} src={selectedImage} />
                ) : (
                  <Skeleton variant="rounded" width={'100%'} height={350} />
                )}
              </Paper>
              <Box display={'flex'} gap={1} flexWrap={'nowrap'}>
                {product
                  ? product.images.map((image) => (
                      <RadioSelectWrapper
                        key={image}
                        selected={selectedImage === image}
                        onClick={() => setSelectedImage(image)}
                      >
                        <img alt="" width="100%" height="auto" src={image} />
                      </RadioSelectWrapper>
                    ))
                  : Array(5)
                      .fill(0)
                      .map((_, i) => (
                        <Skeleton key={i} variant="rounded" width={'100%'} sx={{ pt: 'calc(100% / 5)' }} />
                      ))}
              </Box>
              <Box flex={1} />
              <MDButton onClick={handleNextClick} disabled={isSaving} sx={{ width: 100, alignSelf: 'end' }}>
                {isSaving ? <CircularProgress color="inherit" size={20} /> : 'Next'}
              </MDButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={showWarningModal} onClose={() => setShowWarningModal(false)} maxWidth="md">
        <DialogContent>
          <Typography variant="h4">Are you sure you want to switch products?</Typography>
          <Box component={'ul'} ml={3} my={2}>
            <Typography component={'li'} fontSize={16}>
              Switching from {template.product} to {product?.name}
            </Typography>
            <Typography component={'li'} fontSize={16}>
              This will delete all saved text, images, and QR codes.
            </Typography>
            <Typography component={'li'} fontSize={16} fontWeight={'bold'}>
              This action is irreversible.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <MDButton circular disabled={isLoading} color="light" onClick={handleCancelProductChange}>
              Cancel
            </MDButton>
            <MDButton circular disabled={isLoading} onClick={() => resetScene()} sx={{ width: 185 }}>
              {isLoading ? <CircularProgress size={18} color="inherit" /> : 'Confirm and Switch'}
            </MDButton>
          </Box>
        </DialogContent>
      </Dialog>
    </MDCard>
  );
}
