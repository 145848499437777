import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAutomationContext } from 'pages/automations/context';
import { CircularProgress, Dialog, TextField, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import CloseIcon from 'assets/icons/close';
import useAutomationsService from 'services/automations';

const DuplicateAutomationModal: React.FC = () => {
    
    const { modalState, toggleModal, selectedAutomation, handleUploadListAfterDuplicated } = useAutomationContext();

    const { enqueueSnackbar } = useSnackbar();
    const { duplicateAutomation, getAllAutomations } = useAutomationsService();

    const [loading, setLoading] = useState(false);
    const [ newAutomationName, setNewAutomationName ] = useState('');

    const mutation = useMutation({
        mutationFn: () => duplicateAutomation(selectedAutomation?.id ?? 0, selectedAutomation?.integrationOrderType ?? 0, newAutomationName),
        onMutate: () => setLoading(true),
        onSuccess: (data) => {
            setLoading(false);
            if(data.payload.id) {
                toggleModal('isDuplicateModalOpen', false);
                handleUploadListAfterDuplicated(data.payload)
                getAllAutomations();
                enqueueSnackbar('Automation successfully duplicated.', { variant: 'success' } );
            } else {
                toggleModal('isDuplicateModalOpen', false);
                enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' } );
            }
        },
        onError: (err) => {
          setLoading(false);
        },
    });

    useEffect(() => {
        setNewAutomationName(selectedAutomation?.name ? selectedAutomation.name + '_copy' : '')
    }, [selectedAutomation])

    const handleDuplicate = async() => {
        mutation.mutate();
    }

    return (
        <Dialog maxWidth={'sm'} fullWidth open={modalState.isDuplicateModalOpen} onClose={() => { toggleModal('isDuplicateModalOpen', false); setNewAutomationName('') }}>
            <Box sx={{ display: 'flex', position: 'relative', padding: '2rem 2rem 0 2rem' }}>
                <Typography data-testid="duplicate-title" variant="h4" mb={2} pb={2} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                    Duplicate
                </Typography>
                <CloseIcon sx={{ opacity: .6, position: 'absolute', right: 28}} onClick={() => toggleModal('isDuplicateModalOpen', false)} />
            </Box>
            <Box sx={{ padding: '0 2rem 2rem 2rem', position: 'relative'}}>
                <TextField 
                    id="outlined-basic" 
                    data-testid="automation-name-input"
                    value={newAutomationName}
                    label="Automation Name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewAutomationName(event.target.value)}
                    placeholder='type automation name here' 
                    variant="outlined" 
                    sx={{ margin: '1rem 0 0 0', width: '100%'}} 
                    inputProps={{
                        maxLength: 65
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, padding: '2rem'}}>
                <MDButton 
                    color='light'
                    onClick={() => toggleModal('isDuplicateModalOpen', false)} 
                    sx={{ borderRadius: '5px !important' }} 
                    data-testid="duplicate-cancel-btn"
                    disabled={loading}>
                    Cancel
                </MDButton>
                <MDButton 
                    data-testid="duplicate-btn"
                    onClick={handleDuplicate} 
                    sx={{ borderRadius: '5px !important' }} 
                    disabled={loading || newAutomationName === selectedAutomation?.name}>
                    { loading ? <CircularProgress size={15} color='inherit' /> : 'Duplicate'}
                </MDButton>
            </Box>
        </Dialog>
    );
}

export default DuplicateAutomationModal;