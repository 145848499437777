import React, { useState } from 'react';

import { Box } from '@mui/system';
import { Step } from 'pages/automations/context/types';
import { useQuery } from '@tanstack/react-query';
import { IPlatform } from 'services/automations/automatios.service.types';
import { useAutomationContext } from 'pages/automations/context';
import { Chip, DialogContent, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from 'assets/icons/search';
import OpenAPILogo from 'assets/images/Logo - Open API.png';
import LeadRevealLogo from 'assets/images/Logo - LeadReveal.png';
import KlaviyoLogo from 'assets/images/klaviyo-logo.png';
import ShopifyLogo from 'assets/images/shopify-logo.svg';
import ZapierLogo from 'assets/images/Logo - Zapier.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApiKeyService from 'services/api-key-service';

import './styles.scss';

interface IAutomationTriage {
    data: IPlatform[]
}

const AutomationsTriage: React.FC<IAutomationTriage> = ({ data }) => {
    const { getApiKeys } = ApiKeyService();
    const { toggleModal, setAutomationToSetup, setStepToRender } = useAutomationContext();

    const zapierKey = { value: 1, description: 'Zapier'};
    const openAPIKey = { value: 2, description: 'Open API'};

    const [anchorEl, setAnchorEl] = useState<Record<string, HTMLElement | null>>({});

    const [searchTerm, setSearchTerm] = useState<string>('');

    const { data: zapierAPIs, isLoading: loadingZapierKeys } = useQuery({
        queryKey: ["zapierKeys", zapierKey.value],
        queryFn: () => getApiKeys(zapierKey.value)
    })

    const { data: openAPIKeys, isLoading: loadingOpenAPIKeys } = useQuery({
        queryKey: ["openAPIKeys", openAPIKey.value],
        queryFn: () => getApiKeys(openAPIKey.value)
    })

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, platform: IPlatform) => {
        setAnchorEl((prev) => ({ ...prev, [String(platform.id)]: event.currentTarget }));
    };

    const handleMenuClose = () => {
        setAnchorEl({});
    };

    const handleOptionClick = (platform: IPlatform) => {
        handleMenuClose();

        if(platform.displayName.toLocaleLowerCase() === 'zapier' && zapierAPIs) { setStepToRender(Step.ZAPIER_MANAGE); return } 
        if(platform.displayName.toLocaleLowerCase() === 'zapier' && zapierAPIs?.length === 0) { setStepToRender(Step.ZAPIER); return };

        if(platform.displayName.toLocaleLowerCase() === 'open api' && openAPIKeys) { setStepToRender(Step.OPENAPI_MANAGE); return } 
        if(platform.displayName.toLocaleLowerCase() === 'open api' && openAPIKeys?.length === 0) { setStepToRender(Step.OPENAPI); return };

        switch(platform.displayName) {
            case 'Klaviyo' :
                setStepToRender(Step.KLAVIYO);
            break;
            case 'Shopify' :
                setStepToRender(Step.SHOPIFY);
            break;
            case  'Lead Reveal' :
                setStepToRender(Step.LEAD_RAVEAL);
            break;
            default:
                setStepToRender(Step.INITIAL);
        }
    };

    const platforms: IPlatform[ ] =[
        {
            id: 2,
            displayName: 'Klaviyo',
            logoImageUrl: KlaviyoLogo,
            description: 'Integrate direct mail into your SMS and Email campaigns by leveraging your Klaviyo segments.',
        },
        {
            id: 1,
            displayName: 'Shopify',
            logoImageUrl: ShopifyLogo,
            description: 'Retarget your Shopify customers by segmenting first time buyers, win-back campaigns and more.'
        },
        {
            id: 3,
            displayName: 'Lead Reveal',
            logoImageUrl: LeadRevealLogo,
            description: 'Reveal your website traffic and retarget them using LettrLabs LeadReveal.',
        },
        {
            id: 6,
            displayName: 'Open API',
            logoImageUrl: OpenAPILogo,
            description: 'Integrate and automate your sending through the LettrLabs Open API.'
        },
        {
            id: 7,
            displayName: 'Zapier',
            logoImageUrl: ZapierLogo,
            description: "Connect to over 7,000+ apps through Zapier's workflow automation platform.",
        }
    ];

    const getChip = (platform: IPlatform) => {
        if(!data) return

        switch(data?.find((p: IPlatform) => p.displayName === platform.displayName)?.authenticationStatus) {
            case 'Authenticated' :
                return  <Chip label="Active" sx={{ background: '#d1fadf', color: 'green', height: '22px'}} />
            case 'Pending' :
                return <Chip label="Finish Setup" sx={{ background: '#fef0df', color: '#f05a2a', height: '22px'}} />
            default: 
                return
        }
    }

    const getStatusName = (platform: IPlatform) => {
        if(!platform) return

        return data?.find((p: IPlatform) => p.displayName === platform.displayName)?.authenticationStatus
    }

    const handleClick = (platform: IPlatform) => {
        if(getStatusName(platform) === 'Authenticated' || getStatusName(platform) === 'Pending' || platform.displayName.toLowerCase() === 'zapier' || platform.displayName.toLowerCase() === 'open api') return

        switch(platform.displayName) {
            case 'Klaviyo' :
                setStepToRender(Step.KLAVIYO);
            break;
            case 'Shopify' :
                setStepToRender(Step.SHOPIFY);
            break;
            case  'Lead Reveal' :
                setStepToRender(Step.LEAD_RAVEAL);
            break;
            case 'Zapier' :
                setStepToRender(Step.ZAPIER_MANAGE);
            break;
            case 'Open API' :
                setStepToRender(Step.OPENAPI_MANAGE);
            break;
            default:
                setStepToRender(Step.INITIAL);
        }
    }

    const filteredPlataforms = platforms.filter((platform: IPlatform) =>
        platform.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <DialogContent sx={{ padding: '2rem 4rem'}}>
            <Box sx={{ display: 'flex', position: 'relative'}}>
                <Box />
                <CloseIcon data-testid="setup-modal-close-btn" sx={{ top: '20px', opacity: .6, position: 'absolute', right: 0}} onClick={() => { toggleModal('isSetupModalOpen', false); setAutomationToSetup('') }} />
            </Box>

            <Typography variant="h3" sx={{ margin: '1rem 0 0 0', fontSize: 26}}>
                Automation Management 
            </Typography>

            <Box mb={6} sx={{ opacity: .6 }}>Setup or edit your automations quickly and easily using the options below.</Box>

            <TextField 
                placeholder={'Type a automation name to search'} 
                data-testid="automation-name-input-search"
                onChange={(e) => setSearchTerm(e.target.value)}
                error={false}
                sx={{ width: '285px', marginBottom: '2rem' }}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                    ),
                }}
            />

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, minHeight: '600px' }}>
                { (!loadingOpenAPIKeys && !loadingZapierKeys) && filteredPlataforms.map((platform, index) => (
                    <React.Fragment>
                        <Box
                            key={index}
                            onClick={() => handleClick(platform)}
                            p={2}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '271px',
                                height: '396px',
                                border: '1px solid rgba(0,0,0,.1)',
                                transition: 'border 0.15s',
                                borderRadius: '5px',
                                position: 'relative',
                                '&:hover': {
                                    border: '1px solid #F05A2A',
                                    cursor: 'pointer',
                                },
                            }}
                        >
                                <small>{platform.status}</small>
                            <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                                {
                                    (getStatusName(
                                        platform)?.toLowerCase() === 'authenticated' || 
                                        getStatusName(platform)?.toLowerCase() === 'pending' || 
                                        platform.displayName.toLowerCase() === 'zapier' ||
                                        platform.displayName.toLowerCase() === 'open api'
                                    ) && (
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation();
                                            handleMenuOpen(event, platform);
                                        }}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    )
                                }
                            </Box>

                            <Box height={10}>{getChip(platform)}</Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '1rem',
                                    minHeight: '150px',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    data-testid="automation-image"
                                    src={platform.logoImageUrl}
                                    alt={platform.displayName}
                                    width="80%"
                                    height="auto"
                                />
                            </Box>

                            <b
                                data-testid="automation-logo"
                                style={{ fontSize: '20px', padding: '1rem 0 .5rem 0' }}
                            >
                                {platform.displayName}
                            </b>

                            <Box
                                data-testid="automation-description"
                                sx={{ height: '150px' }}
                            >
                                {platform.description}
                            </Box>
                        </Box>
                        <Menu
                            anchorEl={anchorEl[String(platform.id)] ?? null}
                            open={Boolean(anchorEl[String(platform.id)])}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleOptionClick(platform)}>Settings</MenuItem>
                        </Menu>
                    </React.Fragment>
                ))}

                {
                    (loadingOpenAPIKeys || loadingZapierKeys) && (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2}}>
                            {
                                [1,2,3,4,5].map(box => (
                                    <Box
                                        key={box}
                                        p={2}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            transition: 'border 0.15s',
                                            borderRadius: '5px',
                                            position: 'relative',
                                            width: '271px',
                                            height: '396px',
                                            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
                                            backgroundSize: '200% 100%',
                                            animation: 'loadingAnimation 1.5s infinite',
                                            '&:hover': {
                                                border: '1px solid #F05A2A',
                                                cursor: 'pointer',
                                            },
                                            '@keyframes loadingAnimation': {
                                                '0%': { backgroundPosition: '200% 0' },
                                                '100%': { backgroundPosition: '-200% 0' },
                                            },
                                        }}
                                    />

                                ))
                            }
                        </Box>
                                
                    )
                }
            </Box>
        </DialogContent>
    );
}

export default AutomationsTriage;