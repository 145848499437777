import { useState } from 'react';
import { Box, Chip, FormControlLabel, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import moment from 'moment';

import { checkIsPrintedCard } from 'newStandard/src/features/Editor/utils/templateHelper';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { IOrder, ProductionSpeed } from 'newStandard/src/services/order/types';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import MDDatePicker from 'material-ui/components/MDDatePicker';
import { formatDate } from 'helpers/formatters';
import { ICheckout } from 'models/checkout';

interface SelectProductionSpeedProps {
  checkout?: ICheckout;
  onChange: (order: IOrder) => void;
}

export default function SelectProductionSpeed({ checkout, onChange }: SelectProductionSpeedProps) {
  const { template } = useWorkflowContext<IOrder>();
  const [selected, setSelected] = useState<ProductionSpeed | 'scheduled'>(
    template.holdUntilDate ? 'scheduled' : template.productionSpeed
  );

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const productionSpeed = evt.target.value as ProductionSpeed | 'scheduled';
    setSelected(productionSpeed);
    if (productionSpeed !== 'scheduled') onChange({ ...template, productionSpeed, holdUntilDate: undefined });
  };

  return (
    <Paper variant="outlined" sx={{ borderRadius: 4, py: 1, px: 2, mt: 2 }}>
      <Typography variant="h4" mb={1}>
        Production Speed
      </Typography>

      <RadioGroup value={selected} onChange={handleChange}>
        <Stack spacing={1}>
        {checkout && checkout?.productionSpeeds && checkout?.productionSpeeds?.ExpeditedFastest && moment(checkout?.productionSpeeds.ExpeditedFastest).format('dddd, MMM DD') !== moment(checkout?.productionSpeeds.ExpeditedFaster).format('dddd, MMM DD') && (
          <RadioSelectWrapper selected={selected === ProductionSpeed.ExpeditedFastest} padding={'2px 10px'}>
            <FormControlLabel
              control={<Radio />}
              value={ProductionSpeed.ExpeditedFastest}
              label={
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }}>
                    {moment(checkout?.productionSpeeds.ExpeditedFastest).format('dddd, MMM DD')}
                  </Typography>
                  <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }} fontWeight={'normal'}>
                    (Fastest)
                  </Typography>
                  <Chip
                    label={
                      checkout?.productionSpeedPrices?.ExpeditedFastest
                        ? `+$${checkout.productionSpeedPrices.ExpeditedFastest.toFixed(2)}`
                        : checkIsPrintedCard(template.product)
                        ? '+$0.15'
                        : '+$0.50'
                    }
                  />
                </Box>
              }
            />
          </RadioSelectWrapper>
          )}
          {checkout && checkout?.productionSpeeds && checkout?.productionSpeeds?.ExpeditedFaster && moment(checkout?.productionSpeeds.ExpeditedFaster).format('dddd, MMM DD') !== moment(checkout?.productionSpeeds?.Normal).format('dddd, MMM DD') && (
          <RadioSelectWrapper selected={selected === ProductionSpeed.ExpeditedFaster} padding={'2px 10px'}>
            <FormControlLabel
              control={<Radio />}
              value={ProductionSpeed.ExpeditedFaster}
              label={
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }}>
                    {moment(checkout?.productionSpeeds.ExpeditedFaster).format('dddd, MMM DD')}
                  </Typography>
                  <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }} fontWeight={'normal'}>
                    (Faster)
                  </Typography>
                  <Chip
                    label={
                      checkout?.productionSpeedPrices?.ExpeditedFaster
                        ? `+$${checkout.productionSpeedPrices.ExpeditedFaster.toFixed(2)}`
                        : checkIsPrintedCard(template.product)
                        ? '+$0.07'
                        : '+$0.25'
                    }
                  />
                </Box>
              }
            />
          </RadioSelectWrapper>
        )}
          <RadioSelectWrapper selected={selected === ProductionSpeed?.Normal} padding={'2px 10px'}>
            <FormControlLabel
              control={<Radio />}
              value={ProductionSpeed?.Normal}
              sx={{ '.MuiFormControlLabel-label': { fontSize: { xs: 14, xl: 16 } } }}
              label={moment(checkout?.productionSpeeds?.Normal).format('dddd, MMM DD')}
            />
          </RadioSelectWrapper>
          <RadioSelectWrapper selected={selected === 'scheduled'} padding={'2px 10px'}>
            <FormControlLabel
              control={<Radio />}
              value={'scheduled'}
              label={'Schedule Production'}
              sx={{ '.MuiFormControlLabel-label': { fontSize: { xs: 14, xl: 16 } } }}
            />
          </RadioSelectWrapper>
          {selected === 'scheduled' && (
            <MDDatePicker
              options={{ minDate: checkout?.estimatedMailDate && formatDate(checkout.estimatedMailDate) }}
              onChange={(_: Date, holdUntilDate: string) => {
                onChange({ ...template, holdUntilDate, productionSpeed: ProductionSpeed?.Normal });
              }}
              input={{
                fullWidth: true,
                placeholder: 'MM/DD/YYYY',
                helperText: 'Your order will be mailed no sooner than the date specified.',
                value: template.holdUntilDate ? moment(template.holdUntilDate).format('MM/DD/YYYY') : null,
              }}
            />
          )}
        </Stack>
      </RadioGroup>
    </Paper>
  );
}
