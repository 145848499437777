import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { IPaymentMethod } from 'services/profile/profile.types';

import ACHIcon from 'assets/images/ach-icon.png';
import MDRadio from 'material-ui/components/MDRadio';

interface IACHList {
    loadingProfilePayments: boolean,
    userPaymentMethods: IPaymentMethod[],
    selectedPaymentMethodId: string,
    handleSelectPaymentMethodId: (id: string) => void
}

const ACHList: React.FC<IACHList> = ({ loadingProfilePayments, userPaymentMethods, selectedPaymentMethodId, handleSelectPaymentMethodId}) => {

  const getCreditCardBrandToRender = () => {
    return <Box component="img" src={ACHIcon} width="40px" marginLeft={1.5} marginRight={1} />;
  };

return (
    <React.Fragment>
        {!loadingProfilePayments &&
            Array.isArray(userPaymentMethods) &&
            userPaymentMethods.map((p: IPaymentMethod) => (
                <Box
                    key={p.id}
                    sx={{
                        padding: '1rem',
                        margin: '1rem 0',
                        border: '1px solid rgba(0,0,0,.1)',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        height: '70px',
                        gap: 2,
                        position: 'relative',
                    }}
                >
                    <MDRadio
                        value={p.id}
                        checked={selectedPaymentMethodId === p.id}
                        onChange={() => handleSelectPaymentMethodId(p.id)}
                    />
                    {getCreditCardBrandToRender()}
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="body2">
                            Banking Account ending in {p?.us_bank_account?.last4}
                        </Typography>
                        <Typography variant="caption" sx={{ padding: '.25rem 0', opacity: 0.8 }}>
                            {p?.us_bank_account?.bank_name}
                        </Typography>
                    </Box>
                </Box>
            )
        )}
    </React.Fragment>
);
}

export default ACHList;