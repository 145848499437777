import { useEffect, useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Stack,
  Grid,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentMethod, loadStripe } from '@stripe/stripe-js';
import Loader from 'components/loader';
import useSmartAutomationService from 'services/smart-automation';
import { IMoverAutomation, ISmartAutomationPayload } from 'services/smart-automation/smart-automation.types';
import MoversStripe from './movers-stripe';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import MDButton from 'material-ui/components/MDButton';
import { useGlobal } from 'context/global-context';
import { formatPrice } from 'helpers/formatters';
import { useNavigate } from 'react-router-dom';
import CheckoutSummary from 'components/checkout-summary';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import PaymentCard from 'components/payment-card';

interface MoversEnableProps {
  open: boolean;
  payload?: ISmartAutomationPayload;
  setOpen: (open: boolean) => void;
}

const MoversEnable = ({ open, payload, setOpen }: MoversEnableProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();
  const { getMoversCheckout, enableMoversAutomation } = useSmartAutomationService();

  const [paymentMethod, setPaymentMethod] = useState<string>('new');
  const [selectedCard, setSelectedCard] = useState<PaymentMethod>();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');
  const automation: IMoverAutomation = payload?.payload
    ? { ...convertKeysToCamelCase(JSON.parse(payload.payload)) }
    : {};

  const { mutate: enableAutomation } = useMutation({
    mutationFn: enableMoversAutomation,
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allSmartAutomations', 'allAutomations'] });
      navigate('/automations');
      setShowLoader(false);
    },
  });

  const { data, mutate: getCheckout, isPending } = useMutation({ mutationFn: getMoversCheckout });
  const checkout = data?.payload;
  const hasSavedPaymentMethod = !!checkout?.savedPaymentMethods?.length;
  const isCheckout = automation?.filter?.lookBackMonths && checkout;
  const buttonText = `Enable Automation${isCheckout ? ' & Checkout' : ''}`;
  const showPaymentMethod = !hasSavedPaymentMethod || (isCheckout && !(hasSavedPaymentMethod && !checkout.balance))

  useEffect(() => {
    if (payload?.id) getCheckout(payload);
  }, [getCheckout, payload]);

  useEffect(() => {
    const currentPaymentMethod = hasSavedPaymentMethod ? 'existing' : 'new';
    setPaymentMethod(currentPaymentMethod);
    if (hasSavedPaymentMethod) setSelectedCard(checkout.savedPaymentMethods[0]);
  }, [checkout?.savedPaymentMethods, hasSavedPaymentMethod]);

  const submit = (paymentId?: string) => {
    enableAutomation({ ...payload!, paymentMethodId: paymentId });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
      <DialogContent>
        <DialogTitle>
          <Typography variant="h4" fontSize={24}>
            MoverMail Automation
          </Typography>
        </DialogTitle>
        {isPending ? (
          <Box display="flex" justifyContent="center">
            <Loader />
          </Box>
        ) : (
          <>
            {isCheckout && (
              <Typography variant="h4" mb={2} mt={3}>
                Estimates for the Next Months
              </Typography>
            )}
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs>
                <Typography variant="h5" fontSize={16} textAlign={'center'}>
                  Average
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h5" fontSize={16} textAlign={'center'}>
                  Maximum
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" fontSize={16}>
                  Cards Mailed{' '}
                  <Typography component="span" variant="h5" fontSize={16} fontWeight={'normal'}>
                    (Per Month)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.monthlyAverageMovers ?? '-'}
              </Grid>
              <Grid item xs textAlign={'center'}>
                {(automation?.sendingOptions?.maxNumberCardsSentPerMonth && automation?.sendingOptions?.maxNumberCardsSentPerMonth > 0) ? automation?.sendingOptions?.maxNumberCardsSentPerMonth : 'No Limit'}
              </Grid>
            </Grid>
            <Grid container mb={1}>
              <Grid item xs={6}>
                <Typography variant="h5" fontSize={16}>
                  Cost{' '}
                  <Typography component="span" variant="h5" fontSize={16} fontWeight={'normal'}>
                    (Per Month)
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.monthlyAverageEstimatedCost ? formatPrice(checkout?.monthlyAverageEstimatedCost) : '-'}
              </Grid>
              <Grid item xs textAlign={'center'}>
                {checkout?.maxEstimatedCost
                  ? formatPrice(checkout.maxEstimatedCost)
                  : 'No Limit'}
              </Grid>
            </Grid>
            <Typography variant="h5" fontSize={16}>
              Please Note:
            </Typography>
            <Box component={'ul'} ml={3} mb={2}>
              <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
                Existing credits will be used before charging your default payment method
              </Typography>
              <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
                This automation can be paused at any time, but cannot be edited once it has been enabled
              </Typography>
            </Box>

            {isCheckout && (
              <Box my={3}>
                <Typography variant="h4" mb={2}>
                  Lookback Period Checkout
                </Typography>
                <CheckoutSummary checkout={checkout} />
              </Box>
            )}
            {showPaymentMethod ? (
              <>
                <FormControl fullWidth>
                  <FormLabel id="payment-method-label">
                    <Typography variant="h4" fontSize={18} mb={1}>
                      Choose a payment method
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    value={paymentMethod}
                    aria-labelledby="payment-method-label"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    sx={{ '& > div': { py: 0, display: 'flex', flex: 1, mr: 1 } }}
                  >
                    <RadioSelectWrapper selected={paymentMethod === 'existing'} disabled={!hasSavedPaymentMethod}>
                      <FormControlLabel
                        disabled={!hasSavedPaymentMethod}
                        label={'Charge card on file'}
                        control={<Radio />}
                        value={'existing'}
                      />
                    </RadioSelectWrapper>
                    <RadioSelectWrapper selected={paymentMethod === 'new'}>
                      <FormControlLabel control={<Radio />} value={'new'} label={'New payment method'} />
                    </RadioSelectWrapper>
                  </RadioGroup>
                </FormControl>
                {paymentMethod === 'new' && stripePromise ? (
                  <Elements stripe={stripePromise}>
                    <MoversStripe setOpen={setOpen} submit={submit} buttonText={buttonText} />
                  </Elements>
                ) : (
                  <Stack spacing={1} mt={1}>
                    {checkout?.savedPaymentMethods?.map((card) => (
                      <PaymentCard
                        card={card}
                        variant={'small'}
                        selected={card.id === selectedCard?.id}
                        onClick={() => setSelectedCard(card)}
                      />
                    ))}
                    <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                      <MDButton fullWidth onClick={() => submit(selectedCard?.id)}>
                        {buttonText}
                      </MDButton>
                      <MDButton color="light" onClick={() => setOpen(false)}>
                        Cancel
                      </MDButton>
                    </Box>
                  </Stack>
                )}
              </>
            ) : (
              <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <MDButton fullWidth onClick={() => submit()}>
                  {buttonText}
                </MDButton>
                <MDButton color="light" onClick={() => setOpen(false)}>
                  Cancel
                </MDButton>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MoversEnable;
