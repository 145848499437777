import {CircularProgress, Dialog, DialogContent, Stack} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "material-ui/components/MDButton";
import {ReactNode} from "react";

export interface InfoModalProps{
    show: boolean
    setShow: Function
    headerText: ReactNode | string
    showCancelButton?: boolean
    cancelButtonOnClick?: Function
    showConfirmButton?: boolean
    confirmButtonOnClick?: Function
    customWidth?: number
    isLoading?: boolean
}

function InfoModal({show, setShow, isLoading, headerText, showCancelButton, cancelButtonOnClick, showConfirmButton, confirmButtonOnClick, customWidth}: InfoModalProps){
    return (<Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{minWidth: customWidth? `${customWidth}px` : "300px", maxWidth: customWidth? "unset" : "600px"}}>
            <DialogTitle p={0} whiteSpace={"pre-wrap"}>
                {headerText}
            </DialogTitle>

            <Stack
                direction="row"
                spacing={1}
                marginTop={"35px"}
                justifyContent="flex-end"
            >
                {showCancelButton?
                    <MDButton
                        color="light"
                        onClick={() => cancelButtonOnClick?.()}
                    >
                        Cancel
                    </MDButton>
                    :
                    null
                }

                {showConfirmButton?
                    <MDButton
                        disabled={isLoading}
                        onClick={() => confirmButtonOnClick?.()}
                    >
                        {
                            isLoading ? (<CircularProgress size={24} />) : 'Confirm'
                        }
                    </MDButton>
                    :
                    null
                }

            </Stack>
        </DialogContent>
    </Dialog>)
}

export default InfoModal;