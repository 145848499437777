import { ReactNode, createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ITemplate } from 'models/template';

interface ITemplateFlow {
  // TODO: remove any
  order?: any;
  showModal?: boolean;
  originalRoute?: string;
  shouldCheckNotSupported?: boolean;
  onTemplateChange?: (template: ITemplate) => void;
  customCreateCallback?: () => void;
}

interface GlobalProps {
  navigationCallback?: Function;
  setNavigationCallback: React.Dispatch<React.SetStateAction<Function | undefined>>;
  showLoader: boolean;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  templateFlow: ITemplateFlow;
  setTemplateFlow: React.Dispatch<React.SetStateAction<ITemplateFlow>>;
  allowDiscount: boolean;
}

const defaultGlobalContext: GlobalProps = {
  setNavigationCallback: () => {},
  showLoader: false,
  setShowLoader: () => {},
  templateFlow: {},
  setTemplateFlow: () => {},
  allowDiscount: false,
};

const GlobalContext = createContext<GlobalProps>(defaultGlobalContext);
GlobalContext.displayName = 'GlobalContext';

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const { pathname } = useLocation();
  const [navigationCallback, setNavigationCallback] = useState<Function>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [templateFlow, setTemplateFlow] = useState<ITemplateFlow>({});

  const allowDiscount =
    templateFlow?.originalRoute?.includes('source=2') || //if Klaviyo
    (pathname.includes('template') && !templateFlow.originalRoute) || // if template creation
    pathname.includes('order/'); // if single order

  return (
    <GlobalContext.Provider
      value={{
        navigationCallback,
        setNavigationCallback,
        showLoader,
        setShowLoader,
        templateFlow,
        setTemplateFlow,
        allowDiscount,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }
  return context;
};
