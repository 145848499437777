// @ts-nocheck
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import MDTypography from "material-ui/components/MDTypography";
import { Divider, Grid, MenuItem, Select, Tooltip, Alert, Box, Switch } from "@mui/material";
import MDInput from "material-ui/components/MDInput";
import React, { useEffect, useState } from "react";
import RadioSelectWrapper from "components/radio-select-wrapper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ProfileService from "services/profile";
import { IAnnualMailVolumeVm, IProfile } from "models/profile";
import UploadIcon from "assets/icons/upload";
import MDButton from "material-ui/components/MDButton";
import ProfileArtifactService from "services/profile-artifact";
import { ProfileArtifact } from "models/profileArtifact";
import DeleteIcon from "assets/icons/delete";
import { SUPPORTED_IMAGE_MIME_TYPES } from "models/enums/supportedMimeTypes";
import { IProfileContact } from "models/profileContact";
import { IMaskInput } from "react-imask";
import InfoModal from "components/info-modal";
import Info2Icon from "assets/icons/info-2";
import { useGlobal } from "context/global-context";
import { uploadFile } from "./utils/upload";

const TextMaskPhoneNumber = React.forwardRef<HTMLElement>(
  function TextMaskCustom(props: any, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(000) 000-0000"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
)

function ProfilePage() {
  const queryClient = useQueryClient()
  const { setShowLoader } = useGlobal()

  const { getLoggedInProfile, postProfile, postProfileContact } = ProfileService()
  const { getProfileArtifacts, postProfileArtifacts, deleteProfileArtifact } = ProfileArtifactService()

  const [profile, setProfile] = useState<IProfile>()
  const [hasInvalidImageSize, setHasInvalidImageSize] = useState<boolean>(false)

  const [profileContact, setProfileContact] = useState<IProfileContact>()
  const [profileContactType, setProfileContactType] = useState<"None" | "New Contact">()

  const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false)

  const industries = [
    "Personal Use",
    "Real Estate",
    "E-Commerce",
    "Home Services",
    "Marketing",
    "Tech",
    "Politics",
    "Non-Profit",
    "Insurance",
    "Healthcare",
    "Professional Services",
    "Other",
  ];

  const getLoggedInProfileQuery = useQuery({
    queryKey: ["loggedInProfile"],
    queryFn: () => {
      return getLoggedInProfile()
    },
  })

  const getProfileArtifactsQuery = useQuery({
    queryKey: ["profileArtifacts"],
    queryFn: () => {
      return getProfileArtifacts()
    },
  })

  const postProfileMutation = useMutation({
    mutationFn: () => {
      return postProfile(profile)
    }
  })

  const postProfileContactMutation = useMutation({
    mutationFn: () => {
      return postProfileContact(profileContact!)
    }
  })

  const deleteProfileArtifactMutation = useMutation({
    mutationFn: (variables: any) => {
      return deleteProfileArtifact(variables.id)
    },
    onMutate: () => {
      setShowLoader(true)
    },
    onSuccess: () => {
      setShowLoader(false)

      queryClient.invalidateQueries({ queryKey: ["profileArtifacts"] })
    }
  })

  useEffect(() => {
    if (getLoggedInProfileQuery.data) {
      setProfile(getLoggedInProfileQuery.data)

      if (!getLoggedInProfileQuery.data.firstName || !getLoggedInProfileQuery.data.lastName || !getLoggedInProfileQuery.data.industry || !getLoggedInProfileQuery.data.annualMailVolumeId) {
        setShowNewUserModal(true)
      }

      if (getLoggedInProfileQuery.data.profileContact) {
        setProfileContact(getLoggedInProfileQuery.data.profileContact)

        setProfileContactType("New Contact")
      }
      else {
        setProfileContact({
          id: 0,
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: ""
        })

        setProfileContactType("None")
      }
    }
  }, [getLoggedInProfileQuery.data])

  useEffect(() => {
    setShowLoader(getLoggedInProfileQuery.isLoading || getProfileArtifactsQuery.isLoading)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLoggedInProfileQuery.isLoading, getProfileArtifactsQuery.isLoading]);

  async function uploadProfileArtifact() {
    let files = await uploadFile({
      supportedMimeTypes: SUPPORTED_IMAGE_MIME_TYPES,
      multiple: false,
    });

    const maxSizeBytes = 25 * 1024 * 1024; // 25 MB in bytes
    if (files[0].size >= maxSizeBytes) {
      setHasInvalidImageSize(true)
      return
    }

    setHasInvalidImageSize(false)
    setShowLoader(true)

    await postProfileArtifacts(files[0])

    setShowLoader(false)

    queryClient.invalidateQueries({ queryKey: ["profileArtifacts"] })
  }

  async function saveProfile() {
    let _hasError = false

    if (!profile?.firstName) {
      _hasError = true
    }
    if (!profile?.lastName) {
      _hasError = true
    }
    if (!profile?.industry) {
      _hasError = true
    }
    if (!profile?.annualMailVolumeId) {
      _hasError = true
    }

    if (_hasError) {
      return
    }

    setShowLoader(true)

    await postProfileMutation.mutateAsync()
    await postProfileContactMutation.mutateAsync()

    setShowLoader(false)
    //eslint-disable-next-line
    window.location.reload();
  }

  return <MDBox sx={{ position: 'relative', margin: '.25rem'}}>
    <MDCard color={"white"} boxShadow={false} border={false}>
      <MDBox px={4} py={3}>
        <MDBox sx={{ height: '65px'}}>
          <MDTypography variant={"h4"} fontWeight={"bold"} fontSize={18}>Profile Info</MDTypography>

          <Grid container mt={4} alignItems={"flex-start"} justifyContent={"space-between"} gap={2} flexWrap={"nowrap"}>
            <Grid item xs={3}>
              <MDTypography variant={"h4"} sx={{ fontSize: "18px" }}>First Name</MDTypography>

              <MDBox mt={2}>
                <MDInput
                  fullWidth
                  value={profile?.firstName}
                  onChange={(event: any) => {
                    let val = event.target.value

                    setProfile(prevState => {
                      return { ...prevState, firstName: val }
                    })
                  }}
                />

                {profile && !profile.firstName ?
                  <MDTypography
                    variant={"h6"}
                    fontWeight={"regular"}
                    color="error"
                  >
                    {'This field is required'}
                  </MDTypography>
                  :
                  null
                }
              </MDBox>

            </Grid>
            <Grid item xs={3}>
              <MDTypography variant={"h4"} sx={{ fontSize: "18px" }}>Last Name</MDTypography>

              <MDBox mt={2}>
                <MDInput
                  fullWidth
                  value={profile?.lastName}
                  onChange={(event: any) => {
                    let val = event.target.value

                    setProfile(prevState => {
                      return { ...prevState, lastName: val }
                    })
                  }}
                />

                {profile && !profile.lastName ?
                  <MDTypography
                    variant={"h6"}
                    fontWeight={"regular"}
                    color="error"
                  >
                    {'This field is required'}
                  </MDTypography>
                  :
                  null
                }
              </MDBox>

            </Grid>
            <Grid item xs={3}>
              <MDTypography variant={"h4"} sx={{ fontSize: "18px" }}>Email Address</MDTypography>

              <MDBox mt={2}>
                <MDInput
                  disabled={true}
                  fullWidth
                  value={profile?.email}
                />
              </MDBox>
            </Grid>
            <Grid item xs={3}>
              <MDTypography variant={"h4"} sx={{ fontSize: "18px" }}>Industry</MDTypography>

              <MDBox mt={2}>
                <Select
                  fullWidth
                  value={profile ? profile.industry : ""}
                  onChange={(event: any) => {
                    let val = event.target.value

                    setProfile(prevState => {
                      return { ...prevState, industry: val }
                    })
                  }}
                >
                  {industries.map((ind: string, index: number) => {
                    return <MenuItem key={index} value={ind}>{ind}</MenuItem>
                  })}
                </Select>

                {profile && !profile.industry ?
                  <MDTypography
                    variant={"h6"}
                    fontWeight={"regular"}
                    color="error"
                  >
                    {'This field is required'}
                  </MDTypography>
                  :
                  null
                }
              </MDBox>
            </Grid>
            <Grid item xs={3}>
              <MDTypography variant={"h4"} sx={{ fontSize: "18px" }}>Annual Mail Volume</MDTypography>

              <MDBox mt={2}>
                <Select
                  fullWidth
                  value={profile ? profile.annualMailVolumeId : ""}
                  onChange={(event: any) => {
                    let val = event.target.value

                    setProfile(prevState => {
                      return { ...prevState, annualMailVolumeId: val }
                    })
                  }}
                >
                  {/* ToDo: Implement Map from Annual Mail Volume List */}
                  {profile?.annualMailVolumes.map((volume: IAnnualMailVolumeVm, index: number) => {
                    return <MenuItem key={index} value={volume.id}>{volume.name}</MenuItem>
                  })}
                </Select>

                {profile && !profile.annualMailVolumeId ?
                  <MDTypography
                    variant={"h6"}
                    fontWeight={"regular"}
                    color="error"
                  >
                    {'This field is required'}
                  </MDTypography>
                  :
                  null
                }
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Box sx={{ borderBottom: '4px solid #eef2ff', width: '100%', position: 'absolute', left: 0, top: '65px'}} />

        <MDBox mt={4}>
          <MDTypography variant={"h4"} fontWeight={"bold"}>Alternative Contact Info</MDTypography>

          <MDTypography fontWeight={"normal"} sx={{ fontSize: "18px" }} mt={4}>What is the best contact information to reach you in the event our customer service needs to assist you and you aren't online?</MDTypography>

          <Grid container mt={2} alignItems={"center"} gap={1.5} flexWrap={"nowrap"}>
            <Grid item>
              <RadioSelectWrapper selected={profileContactType === "None"} backgroundColor={"light"} onClick={() => {
                setProfileContactType("None")
                setProfileContact(undefined)
              }}>
                <MDTypography variant={"h5"} fontWeight={"bold"}>My Profile Information Above</MDTypography>
              </RadioSelectWrapper>
            </Grid>

            <Grid item>
              <RadioSelectWrapper selected={profileContactType === "New Contact"} backgroundColor={"light"} onClick={() => {
                setProfileContactType("New Contact")

                setProfileContact({
                  id: 0,
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: ""
                })
              }}>
                <MDTypography variant={"h5"} fontWeight={"bold"}>Add Contact Info for Support Contact</MDTypography>
              </RadioSelectWrapper>
            </Grid>
          </Grid>

          {profileContactType === "New Contact" ?
            <MDBox mt={3}>
              <Grid container gap={2} flexWrap={"nowrap"} alignItems={"center"}>
                <Grid item xs={3}>
                  <MDInput
                    fullWidth
                    value={profileContact?.firstName}
                    label={"First name"}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event: any) => {
                      setProfileContact(prevState => {
                        return { ...prevState, firstName: event.target.value }
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MDInput
                    fullWidth
                    value={profileContact?.lastName}
                    label={"Last name"}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event: any) => {
                      setProfileContact(prevState => {
                        return { ...prevState, lastName: event.target.value }
                      })
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2} gap={2} flexWrap={"nowrap"}>
                <Grid item xs={3}>
                  <MDInput
                    fullWidth
                    value={profileContact?.email}
                    label={"Email"}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event: any) => {
                      setProfileContact(prevState => {
                        return { ...prevState, email: event.target.value }
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MDInput
                    fullWidth
                    label={"Phone number"}
                    InputLabelProps={{ shrink: true }}
                    value={profileContact?.phoneNumber}
                    onChange={(event: any) => {
                      setProfileContact(prevState => {
                        return { ...prevState, phoneNumber: event.target.value }
                      })
                    }}
                    InputProps={{
                      inputComponent: TextMaskPhoneNumber
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
            :
            null
          }
        </MDBox>

        <Box maxWidth={350}>
          <Box display={"flex"} gap={1} alignItems={"center"} m={1} mt={3}>
            <MDTypography sx={{ fontSize: 20 }} fontWeight={"bold"}>
              Re-Sending Settings
            </MDTypography>
            <Switch
              size={"small"}
              checked={profile?.resendAfterWeeks !== null}
              onClick={() => setProfile((prev) => ({ ...prev, resendAfterWeeks: profile?.resendAfterWeeks === null ? 1 : null }))}
            />
            <Tooltip title={"After how many weeks we can send cards to the same recipients"}>
              <Box display={"flex"}>
                <Info2Icon color={"light2"} />
              </Box>
            </Tooltip>
          </Box>
          <Select
            fullWidth
            displayEmpty
            disabled={profile?.resendAfterWeeks === null}
            value={profile?.resendAfterWeeks || ""}
            onChange={(e) => setProfile(prev => ({ ...prev, resendAfterWeeks: Number(e.target.value) }))}
          >
            <MenuItem value={""} disabled />
            {Array.from({ length: 12 }, (_, i) => i + 1).map(vl =>
              <MenuItem key={vl} value={vl}>
                {vl} {vl > 1 ? 'weeks' : 'week'}
              </MenuItem>
            )}
          </Select>
        </Box>

        <MDBox mt={3}>
          <MDButton color={"primary"} onClick={saveProfile}>Save</MDButton>
        </MDBox>

        <MDBox my={4}>
          <Divider light={true} />
        </MDBox>

        <MDBox>
          <MDTypography variant={"h4"} fontWeight={"bold"}>Brand Artwork</MDTypography>

          <MDTypography fontWeight={"normal"} sx={{ fontSize: "18px" }} mt={2}>Upload your brand assets like logos and other artwork that you'd like to re-use for your card designs.</MDTypography>

          <MDBox mt={2}>
            <MDCard color={"white"} borderColor={"light"} border={true} boxShadow={false} borderRadiusSize={"lg"}>
              <MDBox p={2}>
                {hasInvalidImageSize && (
                  <Alert severity="warning" onClose={() => setHasInvalidImageSize(false)}>
                    We do not currently support images over 25 megabytes in size
                  </Alert>
                )}
                <Grid container gap={3} mt={2} alignItems={"stretch"}>
                  <Grid item>
                    <MDButton sx={{ width: "120px", paddingLeft: 0, paddingRight: 0, flexDirection: "column", alignItems: "center", paddingTop: "20px", paddingBottom: "20px" }} color={"light"} onClick={() => {
                      uploadProfileArtifact()
                    }}>
                      <UploadIcon />

                      Upload Image
                    </MDButton>
                  </Grid>

                  <Grid item>
                    <Divider orientation={"vertical"} light={true} />
                  </Grid>

                  {getProfileArtifactsQuery.data?.payload.map((artifact: ProfileArtifact, index: number) => {
                    return <Grid item key={index}>
                      <Grid container alignItems={"center"} gap={2}>
                        <Grid item flex={1}>
                          <MDInput
                            disabled={true}
                            disabledWithoutOpacity={true}
                            value={artifact.artifactName}
                          />
                        </Grid>

                        <Grid item>
                          <Tooltip title={"Delete artifact"}>
                            <MDButton
                              color={"light"}
                              onClick={() => {
                                deleteProfileArtifactMutation.mutate({ id: artifact.id })
                              }}
                            >
                              <DeleteIcon color={"error"} />
                            </MDButton>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      <MDBox mt={2}>
                        <img alt={""} src={artifact.blobUri} style={{ height: 200 }} />
                      </MDBox>
                    </Grid>
                  })}
                </Grid>
              </MDBox>
            </MDCard>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDCard >

    <InfoModal
      show={showNewUserModal}
      setShow={setShowNewUserModal}
      headerText={"Please fill out your profile details before creating orders"}
      showConfirmButton={true}
      confirmButtonOnClick={() => {
        setShowNewUserModal(false)
      }}
    />
  </MDBox >
}

export default ProfilePage
