import React from 'react';

import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';

const LoadingProfilePayments: React.FC = () => {
  return (
    <Box>
        {
            [0,1,2].map(l => (
                <Box key={l} sx={{ padding: '1rem', margin: '1rem 0', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px', display: 'flex', alignItems: 'center', height: '70px', gap: 2 , position: 'relative'}}>
                    <Skeleton width={'20px'} height={'30px'} sx={{ borderRadius: '100%'}}/>
                    <Skeleton width={'60px'} height={'60px'} />
                    <Box>
                        <Skeleton width={'212px'} height={'30px'} />
                        <Skeleton width={'110px'} height={'30px'} />
                    </Box>
                </Box>
            ))
        }
    </Box>
  )
}

export default LoadingProfilePayments;