import React, { useEffect, useState } from "react";

import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import PlanInfo from "./components/PlanInfo";
import MDButton from "material-ui/components/MDButton";
import InfoModal from "components/info-modal";
import DeleteIcon from '@mui/icons-material/Delete';
import MDTypography from "material-ui/components/MDTypography";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ProfileService from "services/profile";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddPaymentMethodModal from "./components/add-payment-method-modal";
import CreditCardColorizedIcon from 'assets/images/credit-card.png';

import { Box } from "@mui/system";
import { IProfile} from "models/profile";
import { useGlobal } from "context/global-context";
import { formatPrice } from "helpers/formatters";
import { useSnackbar } from "notistack";
import { IBillingPaymentMethod } from "services/profile/profile.types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserData, handleUpdateUserData } from "helpers/getUserInfos";
import { CircularProgress, Grid, Skeleton, Switch, Typography } from "@mui/material";

function Billing(){
    const queryClient = useQueryClient()

    const { showLoader } = useGlobal();
    
    const { enqueueSnackbar } = useSnackbar();

    const {getProfile, postProfile, getBillingInfo, deleteProfilePaymentMethod, updateDefaultPaymentMethod} = ProfileService()

    const [profile, setProfile] = useState<IProfile | null>(null)

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [updatingAutoBill, setUpdatingAutoBill] = useState<boolean>(false)
    const [updatingPaymnentMethod, setUpdatingPaymentMethod] = useState<boolean>(false)
    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>("")

    const [showDeleteCardModal, setShowDeleteCardModal] = useState<boolean>(false)
    const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<IBillingPaymentMethod | null>(null)

    const { data, isLoading }  = useQuery({
        queryKey: ["profilePaymentMethods"],
        queryFn: () => {
            return getBillingInfo()
        },
        refetchOnWindowFocus: false
    })

    const handleUpdatePaymentMethod = (defaultPaymentMethodId: string) => {
        if(getUserData()?.defaultPaymentMethodId === defaultPaymentMethodId ) return;

        setUpdatingPaymentMethod(true);

        const objEnv = {
            defaultPaymentMethodId: defaultPaymentMethodId,
            stripeCustomerId: getUserData().stripeCustomerId
        }

        updateDefaultPaymentMethod(objEnv).then((response1) => {
            setShowDeleteCardModal(false)

            if(response1.hasErrors){
                enqueueSnackbar(response1.errors[0], { variant: 'error' } );
                setUpdatingPaymentMethod(false);
                return
            } else {
                handleUpdateUserData({
                    ...getUserData(),
                    defaultPaymentMethodId: defaultPaymentMethodId
                })
                setUpdatingPaymentMethod(false);
                enqueueSnackbar('Default payment method updated successfully', { variant: 'success' } );
            }
        })

    }

    function _deleteProfilePaymentMethod(){
        setIsDeleting(true)

        deleteProfilePaymentMethod(paymentMethodToDelete?.id!).then((response1) => {
            setShowDeleteCardModal(false)

            if(response1.hasErrors){
                setErrorModalText(response1.errors[0])
                setShowErrorModal(true)
                setIsDeleting(false)
                return
            } else {
                setIsDeleting(false)
                enqueueSnackbar('Payment method removed successfully', { variant: 'success' } );
            }

            getProfile().then((response2) => {
                setProfile((prevState) => {
                    return {...prevState!, defaultPaymentMethodId: response2.defaultPaymentMethodId}
                })
            })

            queryClient.invalidateQueries({queryKey: ["profilePaymentMethods"]})
        })
    }

    function updateAutobill(e: React.MouseEvent) {
        let checked = !profile?.autobill

        if(checked){
            if(!profile?.defaultPaymentMethodId){
                setErrorModalText("You need to have a default payment method to be able to auto-billing")
                setShowErrorModal(true)
                e.preventDefault()
                return
            }
        }

        let updatedProfile = {
            ...profile!,
            autobill: checked
        }

        setUpdatingAutoBill(true);

        postProfile(updatedProfile).then(() => {
            setProfile(updatedProfile);
            setUpdatingAutoBill(false);
            handleUpdateUserData(updatedProfile);
            enqueueSnackbar('Update completed successfully', { variant: 'success' } );
        }, () => {
            setUpdatingAutoBill(false);
        })


    }

    const getPlan = (plan: string | undefined) => {
        if (!plan) return

        if (plan.includes('Starter')) {
            return 'Starter'
        } else if (plan.includes('Core')) {
            return 'Core'
        } else if (plan.includes('Premium')) {
            return 'Premium'
        } else if (plan.includes('Enterprise')) {
            return 'Enterprise'
        } else if (plan.includes('Platform Access - Legacy')) {
            return 'Platform Access - Legacy'
        } else {
            return 'Platform Access - Legacy'
        }
    }

    const formatDate = (dateString: string) => {
        if(!dateString) return '';

        const date = new Date(dateString);
        const options: any = { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric' 
        };
        return date.toLocaleDateString('en-US', options);
    }

    useEffect(() => {
        setProfile(getUserData());
    }, []);

    return (
        <MDBox sx={{ maxWidth: '1376px', margin: '0 auto' }}>
            <MDCard color={"white"} boxShadow={false} border={false} sx={{ position: 'relative', margin: '.25rem'}}>
                <MDBox px={4}>
                    <MDBox>
                        <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                            <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                                Current Plan
                            </MDTypography>
                            {/* <MDButton variant="transparent" color="secondary">Manage</MDButton> */}
                        </Grid>
                        <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ minHeight: '150px', width: '550px' }}>
                                <PlanInfo isLoading={isLoading || showLoader} plan={getPlan(data?.payload?.saaSSubscription?.name)} price={data?.payload?.saaSSubscription?.price} recorrency={data?.payload?.saaSSubscription?.billingPeriodId} />
                            </Box>

                            <Box sx={{ width: '33%'}}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px'}}>
                                        { (isLoading || showLoader) && <Skeleton width={'170px'} height={'25px'} />  }
                                        { ((!isLoading && !showLoader )&& (data?.payload?.volumeCommitment && data?.payload?.volumeCommitment?.used >= 0)) && 'Volume Commitment'  }
                                    </Typography>
                                    {
                                        (isLoading || showLoader) && (
                                            <Box sx={{display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Skeleton width={'60px'} height={'25px'} /> /
                                                <Skeleton width={'60px'} height={'25px'} />
                                            </Box>
                                        ) 
                                    }

                                    {
                                        ((!isLoading && !showLoader) && (data?.payload?.volumeCommitment && data?.payload?.volumeCommitment?.used >= 0))  && (
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px'}}>
                                                {data?.payload?.volumeCommitment?.used ? formatPrice(data?.payload?.volumeCommitment?.used) : formatPrice(0)} / {data?.payload?.volumeCommitment?.price ? formatPrice(data?.payload?.volumeCommitment?.price) : formatPrice(0)}
                                            </Typography>
                                        )

                                    }
                                </Box>
                                
                                {
                                    (data?.payload?.volumeCommitment && data?.payload?.volumeCommitment?.used >= 0) && (
                                        <Box
                                            mt={1}
                                            sx={{
                                                width: '100%',
                                                height: 10,
                                                backgroundColor: '#e0e0e0',
                                                borderRadius: 5,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: `${data?.payload?.volumeCommitment?.used
                                                            ? (data?.payload?.volumeCommitment?.used / data?.payload?.volumeCommitment?.price) * 100
                                                            : 0
                                                        }%`,
                                                    height: '100%',
                                                    backgroundColor: '#F05A2A',
                                                    transition: 'width 0.3s ease-in-out',
                                                }}
                                            />
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box sx={{ width: '33%', flexDirection: 'column', alignItems: 'end', display: !isLoading ? 'flex' : 'none' }}>
                                <Typography variant="body2"> 
                                    {
                                        (isLoading || showLoader) ? (
                                            <Skeleton width={'60px'} height={'25px'} />
                                        ) : (
                                            <React.Fragment>
                                                Balance
                                            </React.Fragment>
                                        )
                                    }
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>
                                {
                                        (isLoading || showLoader) ? (
                                            <Skeleton width={'60px'} height={'25px'} />
                                        ) : (
                                            <React.Fragment>
                                                { getUserData()?.customerSubscription?.balance ? formatPrice(getUserData()?.customerSubscription?.balance) : formatPrice(0) }
                                            </React.Fragment>
                                        )
                                    }
                                </Typography>
                            </Box>
                            {
                                !isLoading && (
                                    <Box sx={{ width: '33%', flexDirection: 'column', alignItems: 'end', display: ((getPlan(data?.payload?.saaSSubscription?.name) === 'Starter') || !(data?.payload?.saaSSubscription?.nextPaymentOn)) ? 'none' : 'flex' }}>
                                        <Typography variant="body2"> Billed Monthly</Typography>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Renews {formatDate(data?.payload?.saaSSubscription?.nextPaymentOn ?? '')}</Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    </MDBox>
                </MDBox>
            </MDCard>

            <MDCard color={"white"} boxShadow={false} border={false} sx={{ position: 'relative', margin: '1.5rem .25rem'}}>
                
                <Box p={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Auto-Refill Balance for Integration Orders</Typography>
                        { updatingAutoBill ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Switch sx={{ marginTop: '2px' }} color={"primary"} checked={getUserData()?.autobill} onClick={(e) => { updateAutobill(e) }}/>
                        )}
                    </Box>
                    <Typography variant="body2" sx={{ margin: '1rem 0 0 0 '}}>
                        If your account has insufficient funds to process an automated order we will automatically bill your default credit card with the amount that’s needed to pay for the order or, $100, whichever is greater.   
                    </Typography>

                </Box>
            </MDCard>

            <MDCard color={"white"} boxShadow={false} border={false} sx={{ position: 'relative', margin: '.25rem'}}>
                <MDBox px={4}>
                    <MDBox>
                        <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                            <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                                Payment Methods
                            </MDTypography>
                            {
                                data?.payload?.paymentMethods && data?.payload?.paymentMethods?.length > 0 && (
                                    <MDButton variant="transparent" color="secondary" onClick={() => setShowAddPaymentMethodModal(true)}>Add new</MDButton>
                                )
                            }
                        </Grid>
                        <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />
                    </MDBox>
                </MDBox>
                <MDBox p={4} sx={{ minHeight: '280px' }}>
                    {
                        (!isLoading && !showLoader) && data?.payload?.paymentMethods?.length === 0 && (
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: 2,
                                height: 200,
                                alignItems: "center"
                            }}>
                                <Box component={"img"} src={CreditCardColorizedIcon} sx={{ width: 160}}/>
                                <Typography variant="body2">
                                    You don't have any payment methods registered yet.
                                </Typography>
                                <MDButton onClick={() => setShowAddPaymentMethodModal(true)}>
                                    Add a payment method
                                </MDButton>
                            </Box>
                        )
                    }
                    {
                        (!isLoading && !showLoader) && data?.payload?.paymentMethods?.map((p: IBillingPaymentMethod, idx: number) => (
                            <Box key={idx} sx={{ display: 'flex', alignItems: 'center', borderTop: idx !== 0 ?'1px solid rgba(0,0,0,.05)' : 'none', paddingTop: idx !== 0 ? '1.5rem' : 0,  marginBottom: idx === data?.payload?.paymentMethods.length - 1 ? '0' : '1.5rem' }}>
                                <Box sx={{ display: 'flex',  alignItems: 'center', gap: 2, width: '30%'}}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'whitesmoke', borderRadius: '100%', width: '50px', height: '50px'}}>
                                        {
                                            p?.type !== "us_bank_account" ? (
                                                <CreditCardIcon />
                                            ) : (
                                                <AccountBalanceIcon />
                                            )
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        {
                                            p?.type !== "us_bank_account" ? (
                                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>
                                                    {p?.brand ? (p?.brand.charAt(0).toUpperCase() + p?.brand.slice(1)) : ''} ending in {p?.last4}
                                                </Typography>
                                            ) : (
                                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>
                                                    Banking Account ending in {p?.last4}
                                                </Typography>
                                            )
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '25%'}}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'whitesmoke', borderRadius: '100%', width: '50px', height: '50px'}}>
                                        {
                                            p?.type !== "us_bank_account" ? (
                                                <CalendarMonthIcon />
                                            ) : (
                                                <AccountBalanceIcon />
                                            )
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {
                                            p?.type !== "us_bank_account" ? (
                                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>Valid Thru {p?.expMonth < 10 ? '0' + p?.expMonth : p?.expMonth} / {p?.expYear}</Typography>
                                            ) : (
                                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{p?.bankName}</Typography>
                                            )
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '50%', gap: 2 }}>
                                <Typography
                                    onClick={() => handleUpdatePaymentMethod(p.id)}
                                    variant="body2"
                                    sx={{
                                        cursor: data?.payload?.paymentMethods?.length === 1 || updatingPaymnentMethod || p.id === getUserData()?.defaultPaymentMethodId ? 'not-allowed' : 'pointer',
                                        fontWeight: getUserData()?.defaultPaymentMethodId === p.id ? 'bold' : 'normal',
                                        color: getUserData()?.defaultPaymentMethodId === p.id ? 'inherit' : 'inherit',
                                        '&:hover': {
                                        color: getUserData()?.defaultPaymentMethodId === p.id ? 'inherit' : '#f05a2a',
                                        },
                                    }}
                                    >
                                    { 
                                        (getUserData()?.defaultPaymentMethodId === p.id || (data?.payload?.paymentMethods && data?.payload?.paymentMethods?.length === 1)) 
                                        ? 'Default Payment' 
                                        : 'Set as Default'
                                    }
                                    { 
                                        updatingPaymnentMethod && (
                                        <CircularProgress size={18} sx={{ marginLeft: '.5rem' }} />
                                        ) 
                                    }
                                </Typography>
                                    <Box 
                                        sx={{
                                            borderRadius: '5px',
                                            width: '45px',
                                            height: '45px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: '1px solid rgba(0,0,0,.1)',
                                            background: 'transparent',
                                            transition: 'background 0.3s ease-in-out',
                                            '&:hover': {
                                            background: 'whitesmoke',
                                            },
                                            opacity: (updatingPaymnentMethod || getUserData()?.defaultPaymentMethodId === p.id || data?.payload?.paymentMethods?.length <= 1) ? .5 : 1,
                                            cursor: (updatingPaymnentMethod || getUserData()?.defaultPaymentMethodId === p.id || data?.payload?.paymentMethods?.length <= 1) ?  'not-allowed': 'pointer'
                                        }}>
                                        <DeleteIcon  style={{  cursor: (data?.payload?.paymentMethods?.length <= 1 || updatingPaymnentMethod) ?  'not-allowed': 'pointer', width: '30px', height: '30px' }} onClick={() => {
                                            if((getUserData()?.defaultPaymentMethodId !== p.id && data?.payload?.paymentMethods?.length > 1 && !updatingPaymnentMethod) ) {
                                                setPaymentMethodToDelete(p)
                                                setShowDeleteCardModal(true)
                                            }
                                        }} />
                                    </Box>
                                </Box>
                            </Box>
                        ))
                    }

                    {
                        (isLoading || showLoader) && [1,2,3].map(i => (
                            <Box key={i} sx={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
                                <Box sx={{ display: 'flex',  alignItems: 'center', gap: 2, width: '25%'}}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'whitesmoke', borderRadius: '100%', width: '50px', height: '50px'}} />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body2" ><Skeleton width={110} /></Typography>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold'}}>
                                        <Skeleton width={180} />
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '25%'}}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'whitesmoke', borderRadius: '100%', width: '50px', height: '50px'}} />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body2" ><Skeleton width={110} /></Typography>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold'}}><Skeleton width={180} /></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '50%', gap: 2 }}>
                                    <Typography variant="body2" ><Skeleton  width={90} /></Typography>
                                    <Skeleton  width={45} height={80}/>
                                </Box>
                            </Box>
                        ))
                    }
                </MDBox>
            </MDCard>


            <InfoModal
                show={showDeleteCardModal}
                setShow={setShowDeleteCardModal}
                headerText={`Are you sure you want to delete ${paymentMethodToDelete?.type !== 'us_bank_account' ? 'card' : 'account'} ending with ${paymentMethodToDelete?.last4}?`}
                showCancelButton={true}
                showConfirmButton={true}
                cancelButtonOnClick={() => {
                    setShowDeleteCardModal(false)
                }}
                isLoading={isDeleting}
                confirmButtonOnClick={() => {
                    _deleteProfilePaymentMethod()
                }}
            />

            <InfoModal
                show={showErrorModal}
                setShow={setShowErrorModal}
                headerText={errorModalText}
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonOnClick={() => {
                    setShowErrorModal(false)
                }}
            />

            <AddPaymentMethodModal
                show={showAddPaymentMethodModal}
                setShow={setShowAddPaymentMethodModal}
                setProfile={setProfile}
            />
        </MDBox>
    )
}

export default Billing