import React, { useState } from 'react';

import { Box } from '@mui/system';
import { Step } from 'pages/automations/context/types';
import { IPlatform } from 'services/automations/automatios.service.types';
import { useAutomationContext } from 'pages/automations/context';
import { CircularProgress, DialogContent, Skeleton, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import MDButton from 'material-ui/components/MDButton';
import StoreIcon from '@mui/icons-material/Store';
import useDataSourcesService from 'services/data-sources';
import useSourceInstallRequest from 'hooks/useSourceInstallRequest';

interface IShopifyInstructions {
  platform: IPlatform
}

const ShopfyInstructions: React.FC<IShopifyInstructions> = ({ platform }) => {
  const queryClient = useQueryClient();

  const { setStepToRender } = useAutomationContext();

  const { setInstallSource } = useSourceInstallRequest();

  const { getShopifyStores, revokeDataSource } = useDataSourcesService();

  const [isRevoking, setIsRevoking] = useState(false);
  const [revokeId, setRevokeId] = useState<string>('');

  const { data: { payload: stores = [] } = {}, isLoading: loadingStores } = useQuery({
    queryKey: ['getShopifyStores'],
    queryFn: () => getShopifyStores(),
    refetchOnWindowFocus: false,
  });

  const { isPending: isRevokePending } = useMutation({
    mutationFn: () => revokeDataSource('shopify', revokeId),
    onSuccess: () => {
      setIsRevoking(false);
      queryClient.invalidateQueries({ queryKey: ['getShopifyStores'] });
      queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      setRevokeId('')
      getShopifyStores();
    },
  });


  return (
    <DialogContent sx={{ padding: '2rem 4rem', minHeight: '697px'}}>
      <Typography variant="h3" sx={{ margin: '1rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 26}}>
        Shopify Automation
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1rem 0 0 0'}}>
        <img style={{ marginLeft: '-20px'}} width={'131px'} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa4SDcdI-DZNvD6e55FBmtyWr2abAdC03hXA&s' alt="Shopify" />
      </Box>

    { platform?.authenticationStatus?.toLowerCase() === 'not authenticated' && (
      <Box sx={{ display: 'flex', height: '400px', padding: '1rem 0 0 0', width: '100%'}} mt={2}>
        <Box sx={{ padding: '0 0 2rem 0', width: '100%'}}>
          <Typography variant='body2'>
            Shopify automation requires a direct setup on the Shopify website to get you started with your automations.
          </Typography>
          <Typography variant='body2' sx={{ margin: '.5rem 0 0 0'}}>
            But don’t worry, we’ll explain how it works right below:
          </Typography>
          <Typography variant='body2' sx={{ margin: '2rem 0 0 0'}}>
            <b>Step 1:</b> Click the <b>Setup Now</b> button below to start the setup.
          </Typography>
          <Typography variant='body2' sx={{ margin: '1rem 0'}}>
            <b>Step 2:</b> Sign up or log in if you already have an account.
          </Typography>
          <Typography variant='body2'>
            <b>Step 3:</b> After completing step two, you’ll be automatically redirected back to our application.
          </Typography>
          <Typography variant='body2' sx={{ margin: '2rem 0'}}>
            This setup will allow you to start creating and managing your automations easily and efficiently.
          </Typography>
          <Typography variant='body2'> 
            Enjoy the benefits of Shopify automation to optimize your strategies!
          </Typography>

          <Box sx={{  display: 'flex', justifyContent: 'flex-end', margin: '1rem 0', gap: 2}}>
            <MDButton color="light" sx={{ borderRadius: '5px !important', marginTop: '2rem' }} onClick={() => setStepToRender(Step.INITIAL)}>
                Back
            </MDButton>
            <MDButton color="primary" sx={{ borderRadius: '5px !important', marginTop: '2rem' }} onClick={() => setInstallSource('shopify')}>
                Setup Now
            </MDButton>
          </Box>
        </Box>
      </Box>
    )}

    { platform?.authenticationStatus?.toLowerCase() === 'authenticated' && (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2}}>
        <Typography variant='body2' sx={{ margin: '1rem 0 0 0'}}>Everything is set with your Shopify automation! You're all ready to engage your customers seamlessly.</Typography>
        <Typography variant='body2'>Now, you can focus on what matters: growing your business.</Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '300px',
          overflowY: 'auto'
        }}>
          {
            !loadingStores && stores?.map(s => (
              <Box key={s?.id} sx={{  display: 'flex', justifyContent: 'space-between', margin: '2rem 0 0 0', gap: 2, borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom: '.5rem' }}>
                  <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StoreIcon sx={{ color: '#96bf45'}} />
                    {s?.name}
                  </Typography>
                  
                  <MDButton 
                    disabled={isRevokePending || isRevoking} 
                    color="error" 
                    sx={{ 
                    borderRadius: '5px !important',
                    '&:hover': {
                      backgroundColor: '#d32f2f',
                    },
                  }} onClick={() => { setIsRevoking(true); setRevokeId(s.id); revokeDataSource('shopify', s.id)}}>
                    { (isRevokePending || isRevoking) ? <CircularProgress size={15} color='inherit'  /> : 'Revoke'}
                  </MDButton>
              </Box>
            ))
          }
          {
            loadingStores && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{  display: 'flex', justifyContent: 'space-between', margin: '1rem 0', gap: 2, borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom: '.5rem' }}>
                    <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StoreIcon sx={{ color: '#96bf45'}} />
                      <Skeleton width={'400px'} height={40} />
                    </Typography>
                </Box>
                <Box sx={{  display: 'flex', justifyContent: 'space-between', margin: '1rem 0', gap: 2, borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom: '.5rem' }}>
                    <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StoreIcon sx={{ color: '#96bf45'}} />
                      <Skeleton width={'400px'} height={40} />
                    </Typography>
                </Box>
                <Box sx={{  display: 'flex', justifyContent: 'space-between', margin: '1rem 0', gap: 2, borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom: '.5rem' }}>
                    <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StoreIcon sx={{ color: '#96bf45'}} />
                      <Skeleton width={'400px'} height={40} />
                    </Typography>
                </Box>
              </Box>
            )
          }
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2}}>
          <MDButton
            color="light" 
            sx={{ 
              borderRadius: '5px !important',
              '&:hover': {
                backgroundColor: '#d3d5db',
                color: '#2c2d36'
              },
            }} onClick={() =>setStepToRender(Step.INITIAL)}>
            Back
          </MDButton>
          <MDButton   
            disabled={loadingStores}
            sx={{ 
              borderRadius: '5px !important',
              '&:hover': {
                backgroundColor: '#d3d5db',
                color: '#2c2d36'
              },
            }} onClick={() => setInstallSource('shopify')}>
            Add New Store
          </MDButton>
        </Box>
      </Box>
    )}
    </DialogContent>
  );
}

export default ShopfyInstructions;