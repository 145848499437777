import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import ProfileService from 'services/profile';
import useDataSourcesService from 'services/data-sources';

const ShopifyCallback = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [callbackError, setCallbackError] = useState<boolean>(false);
  const { getProfile } = ProfileService();
  const { setupShopify } = useDataSourcesService();

  const queryParams = new URLSearchParams(search);
  const shopId = queryParams.get('shopId');
  const shopDomain = queryParams.get('shopDomain');
  const email = queryParams.get('email');
  const error = queryParams.get('error');

  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: getProfile,
    refetchOnWindowFocus: false,
  });

  const { mutate: setup } = useMutation({
    mutationFn: () => setupShopify(shopId ?? '', shopDomain ?? '', email ?? ''),
    onSuccess: (response) => {
      if (response.payload) navigate('/automations?action=setup&status=success&automationType=shopify');
      else navigate(`${pathname}?error=true`, { replace: true });
    },
    onError: () => navigate(`${pathname}?error=true`, { replace: true }),
  });

  useEffect(() => {
    if (!profile) return;
    if (profile.id === 0) return navigate(`settings/profile${search}`);

    if (shopId && shopDomain && email) setup();
    else if (error === 'true') setCallbackError(true);
  }, [email, error, navigate, profile, search, setup, shopDomain, shopId]);

  return (
    <Box
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      color={'#F05A2A'}
    >
      <Typography variant="h3" color={callbackError ? 'darkred' : '#130B46'} mb={3} textAlign={'center'} width={'90vw'}>
        {callbackError ? `Something went wrong with Shopify set up` : `Setting up Shopify...`}
      </Typography>
      {!callbackError && <CircularProgress color="inherit" />}
    </Box>
  );
};

export default ShopifyCallback;
