import React from 'react';

import { Box } from '@mui/system';
import { CardElement } from '@stripe/react-stripe-js';
import { Chip, Typography } from '@mui/material';

import MDRadio from 'material-ui/components/MDRadio';

interface INewCreditCard {
  loadingProfilePayments: boolean,
  selectedPaymentMethodId: string,
  setSelectedPaymentMethodId: (newPaymentMethodId: string) => void,
  setCanSubmitPayment: (value: boolean) => void,
  errorMessage: string | null
}

const NewCreditCard: React.FC<INewCreditCard> = ({ 
    errorMessage,
    loadingProfilePayments, 
    selectedPaymentMethodId, 
    setCanSubmitPayment, 
    setSelectedPaymentMethodId
}) => {
  return (
    <React.Fragment>
        {!loadingProfilePayments && (
            <Box
                sx={{
                    margin: '1rem 0',
                    padding: '1rem',
                    border: '1px solid rgba(0,0,0,.1)',
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <MDRadio
                        value="new credit card"
                        checked={selectedPaymentMethodId === 'new credit card'}
                        onChange={() => setSelectedPaymentMethodId('new credit card')}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="body2">Add a new Credit Card</Typography>
                    </Box>
                </Box>

                {selectedPaymentMethodId === 'new credit card' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
                        <CardElement onChange={(e) => setCanSubmitPayment(e.complete)} />
                        {errorMessage && (
                            <Chip
                                color="error"
                                label={
                                    <Box
                                        sx={{
                                            padding: '0 1rem',
                                            wordWrap: 'break-word',
                                            overflowWrap: 'break-word',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {errorMessage}
                                    </Box>
                                }
                                sx={{
                                    height: 'auto',
                                    borderRadius: '8px',
                                    background: '#f8d7da',
                                    color: '#721c24',
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    display: 'block',
                                }}
                            />
                        )}
                    </Box>
                )}
            </Box>
        )}
    </React.Fragment>
  );
}

export default NewCreditCard;