import { Suspense, useState } from 'react';

import { createGlobalStyle } from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';

import MDCard from 'material-ui/components/MDCard';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import { EditorContextProvider } from './contexts/useEditorContext';
import { checkHasEnvelope } from './utils/templateHelper';
import EnvelopeEditor from './components/EnvelopeEditor';
import OverviewPanel from './components/OverviewPanel';
import { IFont } from '../../services/font/types';
import useFontService from '../../services/font';
import CardEditor from './components/CardEditor';

import './styles.scss';

const HandwritingFonts = createGlobalStyle<{ fonts: IFont[] }>`
  ${({ fonts }) =>
    fonts
      .map((font) => {
        return `
          @font-face {
            src: url('${font.url}');
            font-family: '${font.label}';
          }
        `;
      })
      .join('')}
`;

export default function TemplateEditor() {
  const { getFonts } = useFontService();
  const { template } = useWorkflowContext();
  const { data: fonts } = useQuery({ queryKey: ['getFonts'], queryFn: getFonts });
  const [viewType, setViewType] = useState<'Card' | 'Envelope'>('Card');

  return (
    <EditorContextProvider>
      {fonts?.length && <HandwritingFonts fonts={fonts} />}
      <MDCard sx={{ flexDirection: 'row', position: 'relative', pb: 2}}>
        <Box display={viewType === 'Card' ? 'contents' : 'none'}>
          <CardEditor />
        </Box>
        {checkHasEnvelope(template.product) && (
          <Box display={viewType === 'Envelope' ? 'contents' : 'none'}>
            <EnvelopeEditor />
          </Box>
        )}
        <Suspense fallback={<OverviewPanel.Skeleton />}>
          <OverviewPanel viewType={viewType} setViewType={setViewType} />
        </Suspense>
      </MDCard>
    </EditorContextProvider>
  );
}
