import useFetchWithMsal from 'hooks/useFetch';

import { IBillingInfoSelfPayment, ICheckoutData } from './checkout.types';

import API_ENDPOINT, { IObjectResult } from '../endpoint';

const useCheckoutDataService = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/deals`;

  const getDataByDealId = (hubSpotDealId: string): Promise<IObjectResult<ICheckoutData>> => {
    return execute({
      endpoint: `${API_PATH}/hubspot/${hubSpotDealId}`,
      method: 'GET',
    });
  };

  const getDataBySubscriptionType = (subscriptionType: string): Promise<IObjectResult<IBillingInfoSelfPayment>> => {
    return execute({
      endpoint: `${API_ENDPOINT}/v2/subscriptions/${subscriptionType}`,
      method: 'GET',
    });
  };

  const forwardPaymentLink = (payload: Partial<ICheckoutData>): Promise<IObjectResult<ICheckoutData>> => {
    return execute({
      endpoint: `${API_PATH}/forward`,
      method: 'POST',
      data: payload
    });
  };

  const makeSubscription = (payload: Partial<ICheckoutData>): Promise<IObjectResult<ICheckoutData>> => {
    return execute({
      endpoint: `${API_PATH}`,
      method: 'POST',
      data: payload
    });
  };

  const makeUpgradeSubscription = (payload: Partial<IBillingInfoSelfPayment>): Promise<IObjectResult<IBillingInfoSelfPayment>> => {
    return execute({
      endpoint: `${API_ENDPOINT}/v2/subscriptions`,
      method: 'POST',
      data: payload
    });
  };

  const enterpriseUpgradeContactSalesTeam = () => {
    return execute({
      endpoint: `${API_ENDPOINT}/v2/subscriptions/enterprise-subscription-request`,
      method: 'POST'
    });
  };

  return {
    getDataByDealId,
    makeSubscription,
    makeUpgradeSubscription,
    forwardPaymentLink,
    getDataBySubscriptionType,
    enterpriseUpgradeContactSalesTeam
  };
};



export default useCheckoutDataService;
