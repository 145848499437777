import React, { useState } from 'react';

import { Box } from '@mui/system';
import { IProfile } from 'models/profile';
import { Alert, CircularProgress, DialogContent, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import MDInput from 'material-ui/components/MDInput';
import MDButton from 'material-ui/components/MDButton';
import useDataSourcesService from 'services/data-sources';

interface IGetPixel {
    profile: IProfile,
    handleRefresh: () => void
}

const IntroductionStep: React.FC<IGetPixel> = ({ profile, handleRefresh  }) => {

    const queryClient = useQueryClient();

    const [websiteAddress, setWebsiteAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { createLeadRevealSource } = useDataSourcesService();

    const {
        mutate: createLeadReveal,
        isPending,
      } = useMutation({
        mutationFn: createLeadRevealSource,
        onSuccess: ({ payload }) => {
          if (payload.projectId) {
              queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
              handleRefresh();
              setErrorMessage('');
          } else {
            setErrorMessage(payload.Message ?? '')
          }
        },
      });

    const handleWebsiteUrlChange = (value: string) => {
        const url = value.startsWith('http') ? value : `https://${value}`;
        setWebsiteAddress(url);
    };

    const handleSubmit = () => {
        const objEnv = {
            hasActiveSubscription: profile.hasSubscription,
            hasDefaultCreditCard: profile.defaultPaymentMethodId ? true : false,
            monthlyLeads: 500,
            useServiceAccount: true,
            websiteUrl: websiteAddress
        }

        createLeadReveal(JSON.stringify(objEnv))
    }

    return (
        <DialogContent sx={{ padding: '2rem 0'}}>
            <Typography variant="h3" sx={{ margin: '1rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 26}}>
                Lead Reveal Automation
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem 0'}}>
                <Typography variant="body2" mb={2}>
                    Welcome to Lead Reveal website visitor tool!
                </Typography>
                <Typography variant="body2" mb={4}>
                By setting up the Lead Reveal pixel on your website, you'll be able to:
                </Typography>

                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} variant='body2' mb={1}>
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '100%', background: '#F05A2A'}} />
                    Identify and contact the people visiting your website.
                </Typography>

                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} variant='body2' mb={1}>
                    <Box sx={{ width: '8px', height: '8px', borderRadius: '100%', background: '#F05A2A'}} />
                    Filter your website visitors to create targeted outreach compaigns.
                </Typography>

                <Typography variant='body2' mt={4}>
                For any questions about how this works, compliance requirements and how to implement, just go here.
                </Typography>

                <Box sx={{ margin: '2rem 0 1rem 0', display: 'flex', justifyContent: 'center'}}>
                    <MDInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/^https?:\/\//, '');
                        handleWebsiteUrlChange(sanitizedValue);
                        }}
                        label="Type in your domain to start"  
                        value={websiteAddress}
                        sx={{ width: '500px'}}
                    />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <MDButton style={{ width: '150px' }} disabled={websiteAddress === '' || isPending} variant='transparent' color="primary" sx={{ borderRadius: '5px !important' }} onClick={() => handleSubmit()}>
                        { isPending ? <CircularProgress  size={15} color='inherit' /> : 'GET THE PIXEL'}
                    </MDButton>
                </Box>
                {
                    errorMessage && (
                        <Alert severity="error" sx={{ margin: '1rem 0 0 0'}}>
                            {errorMessage}
                        </Alert>
                    )
                }
            </Box>
        </DialogContent>
    );
}

export default IntroductionStep;