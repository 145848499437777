import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import PhoneNumberService from 'services/phone-number';
import { addTrackablePhoneNumber } from 'helpers/ImgLyHelper';
import { formatUSPhoneNumber } from 'helpers/phoneNumberFormat';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import useArtifactService from 'newStandard/src/features/Editor/services/artifact';
import { ArtifactType, IArtifact } from 'newStandard/src/features/Editor/services/artifact/types';

import { ProductOptions } from 'newStandard/src/services/order/types';
import { useEditorContext } from '../../contexts/useEditorContext';
import { checkIsPrintedCard } from '../../utils/templateHelper';
import { handwrittenTextNames } from '../../utils/errorHelper';
import { createImage } from '../../utils/imageHelper';
import { BlockNames } from '../../utils/sceneEnums';

export default function TrackingAndAttribution() {
  const { getPhoneNumbers } = PhoneNumberService();
  const { engine, textCursor } = useEditorContext();
  const { template, setTemplate } = useWorkflowContext();
  const { removeArtifact, generateQrCode } = useArtifactService();
  const [showQRCodeModal, setShowQRCodeModal] = useState<boolean>(false);
  const [qrCodeArtifact, setQrCodeArtifact] = useState<IArtifact>();

  const { data: { payload: phoneNumbers } = {} } = useQuery({ queryKey: ['phoneNumbers'], queryFn: getPhoneNumbers });

  const getQrCodePosition = (isFrontSide: boolean): { positionX: number, positionY: number } => {
    if (!engine) return { positionX: 0, positionY: 0 };
    const currentPage = engine.scene.getCurrentPage();
    if (!currentPage) return { positionX: 0, positionY: 0 };
    if (isFrontSide && checkIsPrintedCard(template.product)) {
      return { positionX: engine.block.getWidth(currentPage) - 1, positionY: 0.2 };
    }
    if (isFrontSide && template.product === ProductOptions.HandwrittenPostCardA8) {
      const keepout = engine.block.findByName(BlockNames.HWPCKeepOutZone)[0];
      return { positionX: engine.block.getWidth(currentPage) - 2, positionY: engine.block.getPositionY(keepout) - 0.8 };
    }
    return { positionX: engine.block.getWidth(currentPage) - 1, positionY: engine.block.getHeight(currentPage) - 1 };
  };

  const { mutate: createQrCode, isPending: isCreating } = useMutation({
    mutationFn: (qrUrl: string) => generateQrCode(template.id, qrUrl),
    onSuccess: (savedArtifact) => {
      if (!engine) return;

      const pages = engine.scene.getPages() || [];
      const currentPage = engine.scene.getCurrentPage();
      if(!currentPage) return;
      const qrCodeSide = pages[0] === currentPage ? 'Front' : 'Rear';

      setQrCodeArtifact(savedArtifact);
      const orderArtifacts: IArtifact[] = [...(template.orderArtifacts || [])];
      const existingArtifact = orderArtifacts.find((artifact) => artifact.id === savedArtifact.id);
      if (existingArtifact) orderArtifacts.splice(orderArtifacts.indexOf(existingArtifact), 1);
      orderArtifacts.push(savedArtifact);
      setTemplate((prev) => ({ ...prev, orderArtifacts, qrCodeSide, useQr: true }));

      const qrCodeData = {
        ...getQrCodePosition(qrCodeSide === 'Front'),
        name: BlockNames.QRCode,
        uri: savedArtifact.blobUri,
        parent: currentPage,
        height: 0.8,
        width: 0.8,
      };
      const block = createImage(engine, qrCodeData);
      engine.block.setScopeEnabled(block, 'layer/crop', false);
      engine.block.setScopeEnabled(block, 'lifecycle/duplicate', false);
      engine.block.setScopeEnabled(block, 'fill/change', false);
      engine.block.setScopeEnabled(block, 'lifecycle/destroy', false);
      engine.block.setAlwaysOnTop(block, true);
      engine.block.sendToBack(block); // Always on top, but behind the keep out area
    },
  });

  const { mutate: deleteArtifact, isPending: isDeleting } = useMutation({
    mutationFn: (id: number) => removeArtifact(template.id, id),
    onSuccess: (_, id) => {
      if (!engine) return;

      const qrCodeBlockId = engine.block.findByName(BlockNames.QRCode)[0];
      engine.block.setScopeEnabled(qrCodeBlockId, 'lifecycle/destroy', true);
      engine.block.destroy(qrCodeBlockId);

      setShowQRCodeModal(false);
      setQrCodeArtifact(undefined);
      setTemplate((prev) => ({
        ...prev,
        orderArtifacts: template.orderArtifacts.filter((artifact) => artifact.id !== id),
        useQr: false,
        qrAngle: null,
        qrCodeSide: null,
        qrCodeHeight: null,
        qrCodeWidth: null,
        qrCodeX: null,
        qrCodeY: null,
        qrUrl: null,
      }));
    },
  });

  const removeQrCode = () => {
    const qrArtifact = template.orderArtifacts?.find((artifact) => artifact.artifactType === ArtifactType.QRCode);
    if (qrArtifact) deleteArtifact(qrArtifact.id);
  };

  const handleQRCodeGeneration = () => {
    if (!template.qrUrl) return;
    setShowQRCodeModal(true);
    createQrCode(template.qrUrl);
  };

  const handleSelectPhoneNumber = (phoneNumber: string) => {
    if (!engine) return;
    const formatedPhoneNumber = formatUSPhoneNumber(phoneNumber);
    const pages = engine.scene.getPages() || [];
    const currentPage = engine.scene.getCurrentPage();
    const callTrackingSide = pages[0] === currentPage ? 'Front' : 'Rear';
    if (checkIsPrintedCard(template.product) || pages[1] === currentPage) {
      addTrackablePhoneNumber(engine, currentPage, formatedPhoneNumber);
    } else {
      const isBlockValid = engine.block.isValid(textCursor.block);
      const block = isBlockValid ? textCursor.block : engine.block.findByName(BlockNames.HandwrittenText)[0];
      engine.block.replaceText(block, formatedPhoneNumber, textCursor.from, textCursor.to);
    }
    setTemplate((prev) => ({ ...prev, trackingPhoneNumber: phoneNumber, callTrackingSide }));
  };

  const handleRemovePhoneNumber = () => {
    if (!engine) return;
    const textBlocks = engine.block.findByKind('text');
    textBlocks.forEach((block) => {
      const text = engine.block.getString(block, 'text/text');
      const updatedText = text.replaceAll(formatUSPhoneNumber(template.trackingPhoneNumber ?? ''), '');
      if (text !== updatedText) {
        if (!handwrittenTextNames.includes(engine.block.getName(block) as BlockNames) && !updatedText.length) {
          engine.block.destroy(block);
        } else engine.block.replaceText(block, updatedText);
      }
      setTemplate((prev) => ({ ...prev, trackingPhoneNumber: '' }));
    });
  };

  return (
    <Stack spacing={1}>
      <Box display={'flex'} gap={1} alignItems={'center'}>
        <Typography variant="body2" fontWeight={'bold'}>
          Tracking & Attribution
        </Typography>
        <Tooltip title={'Add a trackable QR Code or phone number to see who engages with your cards in real time!'}>
          <InfoOutlined sx={{ height: 20 }} />
        </Tooltip>
      </Box>
      <TextField
        fullWidth
        type="text"
        size="small"
        label="QR Code"
        disabled={template.useQr}
        value={template.qrUrl ?? ''}
        placeholder={'Enter the URL of your QR Code'}
        onChange={(event) => setTemplate((prev) => ({ ...prev, qrUrl: event.target.value }))}
        InputProps={{
          sx: { px: 0.5 },
          endAdornment: template.qrUrl && (
            <MDButton
              fullWidth
              size={'small'}
              color={template.useQr ? 'light' : 'primary'}
              disabled={isCreating || isDeleting}
              onClick={template.useQr ? removeQrCode : handleQRCodeGeneration}
              sx={{ maxWidth: 76 }}
            >
              {isCreating || isDeleting ? (
                <CircularProgress color="inherit" size={18} />
              ) : template.useQr ? (
                'Remove'
              ) : (
                'Generate'
              )}
            </MDButton>
          ),
        }}
      />

      <Tooltip title={phoneNumbers?.length ? '' : 'A tracking number must be purchased to enable call tracking.'}>
        <FormControl fullWidth>
          <InputLabel id="call-tracking-label" disabled={!phoneNumbers?.length || !!template.trackingPhoneNumber}>
            {phoneNumbers?.length ? 'Call Tracking' : 'No Call Tracking Available'}
          </InputLabel>
          <Select
            fullWidth
            size="small"
            label="Call Tracking"
            labelId="call-tracking-label"
            value={template.trackingPhoneNumber ?? ''}
            disabled={!!template.trackingPhoneNumber || !phoneNumbers?.length}
            onChange={(evt) => handleSelectPhoneNumber(evt.target.value)}
            IconComponent={
              !template.trackingPhoneNumber
                ? undefined
                : () => (
                    <MDButton
                      fullWidth
                      size={'small'}
                      color={'light'}
                      onClick={() => handleRemovePhoneNumber()}
                      sx={{ maxWidth: 76, mr: 0.5 }}
                    >
                      Remove
                    </MDButton>
                  )
            }
          >
            {phoneNumbers?.map((phone) => (
              <MenuItem key={phone.id} value={phone.number}>
                {formatUSPhoneNumber(phone.number)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
      <Dialog open={showQRCodeModal} onClose={() => setShowQRCodeModal(false)} maxWidth="xs">
        <DialogContent>
          <DialogTitle>Please scan the QR code to confirm that it works as expected</DialogTitle>
          <Stack spacing={2} alignItems={'center'}>
            <Box position={'relative'}>
              {qrCodeArtifact && (
                <Box position={'absolute'} zIndex={1}>
                  <img alt={'QR Code Preview'} src={qrCodeArtifact?.blobUri} width={250} />
                </Box>
              )}
              <Skeleton variant="rectangular" width={250} height={250} />
            </Box>
            <Box display={'flex'} gap={1} alignSelf={'flex-end'}>
              <MDButton
                color="light"
                disabled={isDeleting}
                onClick={qrCodeArtifact ? () => deleteArtifact(qrCodeArtifact.id) : undefined}
              >
                {isDeleting ? <CircularProgress color="inherit" size={18} /> : 'Remove'}
              </MDButton>
              <MDButton
                color="primary"
                disabled={!qrCodeArtifact || isDeleting}
                onClick={() => setShowQRCodeModal(false)}
              >
                Confirm
              </MDButton>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
