// @ts-nocheck
import {useEffect, useState} from "react";
import {IVM_GridOrder} from "models/views/gridOrderVm";
import OrderService from "services/order";
import { DialogContent, Divider, Grid, IconButton, Paper, Stack, Tooltip} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import MDTypography from "material-ui/components/MDTypography";
import {OrderType} from "models/enums/OrderType";
import {OrderStatus} from "models/enums/orderStatus";
import {formatNumber} from "helpers/formatters";
import {formatDate} from "helpers/formatters";
import RenameIcon from "assets/icons/rename";
import DeleteIcon from "assets/icons/delete";
import EditPencilIcon from "assets/icons/edit-pencil";
import DuplicateIcon from "assets/icons/duplicate";
import {useNavigate} from "react-router-dom";
import InfoModal from "../info-modal";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import TwoButtonWithOptionalFormFieldModal from "../modal-with-two-buttons-and-textfield";
import StyledDataGrid from "../styled-data-grid";
import TemplateIcon from "assets/icons/template";
import TemplateService from "services/template";
import {GridColDef} from "@mui/x-data-grid-pro";
import CopyOrderModal from "./components/copy-order-modal";
import IntegrationOrderService from "services/integration-order";
import {DeprecatedProductTypes, orderProductHasNoEnvelope} from "models/enums/ProductTypes";
import {IOrderRecipient} from "models/orderRecipient";
import CloseIcon from "assets/icons/close";
import { useGlobal } from "context/global-context";
import { isLettrLabsUser } from "helpers/profileHelper";
import ProfileService from "services/profile";
import { ProductOptions } from "newStandard/src/services/order/types";
import TemplateScenePreview from "components/template-scene-preview";

interface OrderDetailsModalProps {
    show: boolean
    setShow: Function
    order: IVM_GridOrder
}

function OrderDetailsModal({show, setShow, order}: OrderDetailsModalProps) {
    const navigate = useNavigate()
    const {setShowLoader} = useGlobal()
    const queryClient = useQueryClient()

    const {getOrderRecipients, deleteOrder, changeOrderName} = OrderService()
    const {getIntegrationOrderRecipients, copyIntegrationOrder, deleteIntegrationOrder} = IntegrationOrderService()
    const {createTemplateFromOrder} = TemplateService()
    const { getProfile } = ProfileService();
    const { data: profile } = useQuery({ queryKey: ['profile'], queryFn: getProfile, refetchOnWindowFocus: false });

    const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false)
    const [showCopyOrderModal, setShowCopyOrderModal] = useState<boolean>(false)
    const [showCopyIntegrationOrderModal, setShowCopyIntegrationOrderModal] = useState<boolean>(false)
    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState<boolean>(false)
    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState<boolean>(false)

    const [newName, setNewName] = useState<string>("")
    const [templateName, setTemplateName] = useState<string>("")
    const [newIntegrationOrderName, setNewIntegrationOrderName] = useState<string>("")

    const [recipients, setRecipients] = useState<IOrderRecipient[]>([])
    const [usedColumns, setUsedColumns] = useState<string[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])

    const [showPreviewImagesModal, setShowPreviewImagesModal] = useState<boolean>(false)
    const isBifold = order?.product === ProductOptions.HandwrittenBiFoldCard 

    const getOrderRecipientsQuery = useQuery({
        queryKey: ["orderRecipients", order.id],
        queryFn: () => {
            if(order.orderType === OrderType.Normal){
                return getOrderRecipients(order.id)
            }
            else if(order.orderType === OrderType.Integration){
                return getIntegrationOrderRecipients(order.id)
            }

            return null
        },
        enabled: show
    })

    const changeOrderNameMutation = useMutation({
        mutationFn: () => {
            order.name = newName
            return changeOrderName(order)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
            setShowEditNameModal(false)
            queryClient.invalidateQueries({queryKey: ["orders"]})
        }
    })

    const deleteOrderMutation = useMutation({
        mutationFn: () => {
            if(order.orderType === OrderType.Integration){
                return deleteIntegrationOrder(order.id)
            }

            return deleteOrder(order.id)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
            setShowDeleteOrderModal(false)
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
        }
    })

    const createTemplateFromOrderMutation = useMutation({

        mutationFn: () => {
            return createTemplateFromOrder(order.orderType === OrderType.Integration ? order.templateId : order.id, templateName)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
            setShowDeleteOrderModal(false)
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
        }
    })

    const copyIntegrationOrderMutation = useMutation({
        mutationFn: () => {
            let postData = order
            postData.name = newIntegrationOrderName

            return copyIntegrationOrder(postData)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
            setShowCopyIntegrationOrderModal(false)
            queryClient.invalidateQueries({queryKey: ["orders"]})
            setShow(false)
        }
    })

    useEffect(() => {
        if (showCreateTemplateModal) {
            setTemplateName("")
        }
    }, [showCreateTemplateModal]);

    useEffect(() => {
        if (showEditNameModal) {
            setNewName(order.name)
        }
    }, [showEditNameModal, order.name]);

    useEffect(() => {
        if (getOrderRecipientsQuery.isFetched && getOrderRecipientsQuery.data) {
            setRecipients(getOrderRecipientsQuery?.data?.payload?.recipients)
            setUsedColumns(getOrderRecipientsQuery?.data?.payload?.usedColumns)
        }
    }, [getOrderRecipientsQuery]);

    function getColumns() {
        const columns: GridColDef[] = [
            {
                field: 'firstName',
                headerName: 'First Name',
                width: 150
            },
            {
                field: 'lastName',
                headerName: 'Last Name',
                width: 150
            },
            {
                field: 'toOrganization',
                headerName: 'To Organization',
                width: 150
            },
            {
                field: 'address1',
                headerName: 'Address 1',
                width: 200
            },
            {
                field: 'address2',
                headerName: 'Address 2',
                width: 200
            },
            {
                field: 'city',
                headerName: 'City',
                width: 150
            },
            {
                field: 'state',
                headerName: 'State',
                width: 150
            },
            {
                field: 'zipCode',
                headerName: 'Zip Code',
                width: 120
            },
            {
                headerName: "Custom 1",
                field: "custom1",
                minWidth: 200
            },
            {
                headerName: "Custom 2",
                field: "custom2",
                minWidth: 200
            },
            {
                headerName: "Custom 3",
                field: "custom3",
                minWidth: 200
            },
            {
                headerName: "Custom 4",
                field: "custom4",
                minWidth: 200
            },
            {
                headerName: "Custom 5",
                field: "custom5",
                minWidth: 200
            },
            {
                headerName: "Custom 6",
                field: "custom6",
                minWidth: 200
            }
        ];

        if (isLettrLabsUser(profile)) {
            columns.push({
                field: "status",
                headerName: "Status",
                minWidth: 200
            });
        }

        if (order.orderType === OrderType.Integration) {
            columns.push({
                field: 'updatedDate',
                headerName: 'Submitted Date',
                valueFormatter: (params) => {
                    return formatDate(params.value)
                }
            });

            columns.push({
                field: 'mailedDate',
                headerName: 'Mailed Date',
                valueFormatter: (params) => {
                    return formatDate(params.value)
                }
            });
        }

        columns.push(
            {
                field: 'qrScanCount',
                headerName: 'QR Scans',
                minWidth: 100
            },
            {
                field: 'lastQrScanDate',
                headerName: 'Last QR Scan',
                minWidth: 150,
                valueFormatter: (params) => {
                    return formatDate(params.value)
                }
            }
        );

        return columns;
    }

    useEffect(() => {
        if(show){
            setColumns(getColumns())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const hasEnvelope = !orderProductHasNoEnvelope(order.product)

    return <Dialog open={show} onClose={() => {setShow(false)}} maxWidth={false} fullWidth={true}>
        <DialogContent sx={{maxHeight: "90vh", minWidth: "1300px"}}>
            <MDBox>
                <Grid container alignItems={"flex-start"} justifyContent={"space-between"}>
                    <Grid item>
                        <Grid container gap={1}>
                            <Grid item>
                                <DialogTitle color={"secondary"}>Order Details:</DialogTitle>
                            </Grid>

                            <Grid item>
                                <DialogTitle color={"secondary"}> {order.id}</DialogTitle>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <MDBox display={"flex"} onClick={() => {setShow(false)}}>
                            <CloseIcon/>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>

            <MDBox>
                <MDCard border={true} boxShadow={false} borderRadiusSize={"lg"}>
                    <Grid container p={4} justifyContent={"space-between"} flexWrap={"nowrap"}>
                        <Grid item>
                            <Grid container gap={3} flexWrap={"nowrap"}>
                                <Grid item width={"340px"}>
                                    <MDTypography color={"secondary"} fontWeight={"bold"}>General Info:</MDTypography>

                                    <MDBox mt={2}>
                                        <MDBox>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <MDTypography variant={"h5"}>Name:</MDTypography>
                                                <MDTypography fontWeight={"regular"}
                                                              variant="h5">{order.name}</MDTypography>
                                            </Stack>
                                        </MDBox>
                                        <MDBox mt={1}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <MDTypography variant={"h5"}>Status:</MDTypography>
                                                <MDTypography fontWeight={"regular"}
                                                              variant="h5">{order.status}</MDTypography>
                                            </Stack>
                                        </MDBox>

                                        {order.orderType === OrderType.Normal && (
                                            <>
                                                <MDBox mt={1}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <MDTypography variant={"h5"}>Recipients:</MDTypography>
                                                        <MDTypography
                                                            fontWeight={"regular"}
                                                            variant="h5">{formatNumber(order.recipientCount)}</MDTypography>
                                                    </Stack>
                                                </MDBox>
                                                {/* not in draft */}
                                                {order.status !== OrderStatus.Draft &&
                                                    order.paidDate && (
                                                        <MDBox mt={1}>
                                                            <Stack direction="row" spacing={1} alignItems="center">
                                                                <MDTypography variant={"h5"}>Paid Date:</MDTypography>

                                                                <MDTypography fontWeight={"regular"} variant="h5">
                                                                    {formatDate(order.paidDate)}
                                                                </MDTypography>
                                                            </Stack>
                                                        </MDBox>
                                                    )}
                                                {/* in production */}
                                                {(
                                                    order.status === OrderStatus.InProduction 
                                                    || order.status === OrderStatus.ReadyForProduction
                                                    || order.status === OrderStatus.InShipping
                                                ) &&  (
                                                        <MDBox mt={1}>
                                                            <Stack
                                                                direction="row"
                                                                spacing={1}
                                                                alignItems="center"
                                                            >
                                                                <MDTypography variant={"h5"}>In Production
                                                                    Date:{" "}</MDTypography>

                                                                <MDTypography fontWeight={"regular"} variant="h5">
                                                                    {formatDate(order.statusDate)}
                                                                </MDTypography>
                                                            </Stack>
                                                        </MDBox>
                                                    )}
                                                {(order.status === OrderStatus.Mailed) &&
                                                    order.mailedDate && (
                                                        <MDBox mt={1}>
                                                            <Stack direction="row" spacing={1} alignItems="center">
                                                                <MDTypography variant={"h5"}>Mailed
                                                                    Date:{" "}</MDTypography>

                                                                <MDTypography fontWeight={"regular"} variant="h5">
                                                                    {formatDate(order.mailedDate)}
                                                                </MDTypography>
                                                            </Stack>
                                                        </MDBox>
                                                    )}
                                                     {(order.estimatedDeliveryDateMin && order.estimatedDeliveryDateMax) && (
                                                        <MDBox mt={1}>
                                                            <Stack direction="row" spacing={1} alignItems="center">
                                                                <MDTypography variant={"h5"}>
                                                                    {
                                                                        order.status === OrderStatus.Mailed &&
                                                                            order.mailedDate
                                                                            ? "Delivery Date:"
                                                                            : "Est. Delivery Date:"
                                                                    } {" "}</MDTypography>

                                                                <MDTypography fontWeight={"regular"} variant="h5">
                                                                    {formatDate(order.estimatedDeliveryDateMin)} - {formatDate(order.estimatedDeliveryDateMax)}
                                                                </MDTypography>
                                                            </Stack>
                                                        </MDBox>
                                                    )}
                                            </>
                                        )}
                                        {order.orderType === OrderType.Integration && (
                                            <>
                                                <MDBox mt={1}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <MDTypography variant={"h5"}>Queued:{" "}</MDTypography>

                                                        <MDTypography
                                                            fontWeight={"regular"}
                                                            variant="h5">{formatNumber(order.queueCount)}</MDTypography>
                                                    </Stack>
                                                </MDBox>

                                                <MDBox mt={1}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <MDTypography variant={"h5"}>In Production:{" "}</MDTypography>

                                                        <MDTypography
                                                            fontWeight={"regular"}
                                                            variant="h5">{formatNumber(order.inProductionCount)}</MDTypography>
                                                    </Stack>
                                                </MDBox>

                                                <MDBox mt={1}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <MDTypography variant={"h5"}>Mailed:{" "}</MDTypography>

                                                        <MDTypography
                                                            fontWeight={"regular"}
                                                            variant="h5">{formatNumber(order.mailedCount)}</MDTypography>
                                                    </Stack>
                                                </MDBox>

                                                <MDBox mt={1}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <MDTypography variant={"h5"}>Status:{" "}</MDTypography>

                                                        <MDTypography fontWeight={"regular"}
                                                                      variant="h5">{order.status}</MDTypography>
                                                    </Stack>
                                                    {order.mailedDate ? (
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <MDTypography variant={"h4"}>Mailed
                                                                Date:{" "}</MDTypography>

                                                            <MDTypography fontWeight={"regular"} variant="h5">
                                                                {formatDate(order.mailedDate)}
                                                            </MDTypography>
                                                        </Stack>
                                                    ) : null}
                                                </MDBox>
                                            </>
                                        )}
                                    </MDBox>
                                </Grid>

                                <Grid item>
                                    <Divider orientation={"vertical"} light={true}/>
                                </Grid>

                                <Grid item width={"200px"}>
                                    <MDTypography color={"secondary"} fontWeight={"bold"}>Analytics:</MDTypography>

                                    <MDBox mt={2}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <MDTypography variant={"h5"}>Qr Scans:</MDTypography>
                                            <MDTypography fontWeight={"regular"} variant="h5">
                                                {getOrderRecipientsQuery.data?.payload?.recipients?.reduce((total: number, orderRecipient: any) => total + orderRecipient.qrScanCount, 0)}
                                            </MDTypography>
                                        </Stack>
                                    </MDBox>

                                    <MDBox mt={1}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <MDTypography variant={"h5"}>Calls Received:</MDTypography>
                                            <MDTypography fontWeight={"regular"}
                                                          variant="h5">{order.callsReceivedCount ? formatNumber(order.callsReceivedCount) : 0}</MDTypography>
                                        </Stack>
                                    </MDBox>

                                    <MDBox mt={1}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <MDTypography variant={"h5"}>Total Call Time:</MDTypography>
                                            <MDTypography
                                                fontWeight={"regular"}
                                                variant="h5">{order.totalCallTime ? formatNumber(order.totalCallTime) : 0} minutes</MDTypography>
                                        </Stack>
                                    </MDBox>
                                </Grid>

                                <Grid item>
                                    <Divider orientation={"vertical"} light={true}/>
                                </Grid>

                                <Grid item flex={1}>
                                    <MDBox display={"flex"} justifyContent={"center"}>
                                        <Grid container gap={2} flexWrap={"nowrap"} sx={{cursor: "pointer", width: "100%"}} onClick={() => setShowPreviewImagesModal(true)}>
                                            <Grid item flex={1}>
                                                <MDTypography mb={1} fontWeight={"bold"} color={"secondary"}>Card {isBifold? "Inside" : "Front"}:</MDTypography>

                                                <MDCard borderRadiusSize={0} boxShadow={false} border={true}>
                                                    <img alt={""} style={{width: "100%", height: "100%"}} src={order.sceneFrontFull}/>
                                                </MDCard>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography mb={1} fontWeight={"bold"} color={"secondary"}>Card {isBifold? "Front" : "Rear"}:</MDTypography>
                                                <Paper
                                                    square
                                                    variant={'outlined'}
                                                    elevation={4}
                                                    sx={{ display: 'flex', overflow: 'hidden', height: isBifold ? 'calc(50% - 28px)' : 'auto' }}
                                                    >
                                                    <img alt="" src={order.sceneBackFull} style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'bottom' }} />
                                                </Paper>
                                            </Grid>

                                            {hasEnvelope?
                                                <Grid item xs={4} flex={1}>
                                                    <MDTypography mb={1} fontWeight={"bold"} color={"secondary"}>Envelope:</MDTypography>

                                                    <MDCard borderRadiusSize={0} boxShadow={false} border={true}>
                                                        <img alt={""} style={{width: "100%", height: "100%"}} src={order.envelopeFull}/>
                                                    </MDCard>
                                                </Grid>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Stack
                                className={"edit-buttons-container"}
                                direction="row"
                                alignItems="center"
                                gap={2}
                            >
                                <Tooltip title="Edit Name">
                                    <IconButton sx={{padding: 0}} onClick={() => setShowEditNameModal(true)}>
                                        <RenameIcon htmlColor={"#646367"}/>
                                    </IconButton>
                                </Tooltip>

                               {order.orderType === OrderType.Normal && order.product !== null && order.product !== '' && order.product !== DeprecatedProductTypes.MediumHandwrittenCardA7 && (
                                    <>
                                        <Tooltip title="Create Template">
                                            <IconButton sx={{padding: 0}} onClick={() => setShowCreateTemplateModal(true)}>
                                              <TemplateIcon htmlColor={"#646367"}/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Copy">
                                            <IconButton sx={{padding: 0}}
                                                        onClick={() =>
                                                            setShowCopyOrderModal(true)
                                                        }
                                            >
                                                <DuplicateIcon htmlColor={"#646367"}/>
                                            </IconButton>
                                        </Tooltip>

                                        {(order.status === OrderStatus.Draft ||
                                            order.status === OrderStatus.PaymentNeeded ||
                                            order.status === OrderStatus.EditsNeeded) && (
                                            <>
                                                <Tooltip title="Edit Order">
                                                    <IconButton sx={{padding: 0}}
                                                                onClick={() =>
                                                                    navigate("/order/" + order.id)
                                                                }
                                                    >
                                                        <EditPencilIcon htmlColor={"#646367"}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton sx={{padding: 0}} onClick={() => setShowDeleteOrderModal(true)}>
                                                        <DeleteIcon htmlColor={"#646367"}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )}
                                    </>
                                )}

                                {order.orderType === OrderType.Integration && (<>
                                    <Tooltip title="Copy">
                                        <IconButton
                                            sx={{padding: 0}}
                                            onClick={() =>
                                                setShowCopyIntegrationOrderModal(true)
                                            }
                                        >
                                            <DuplicateIcon htmlColor={"#646367"}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <IconButton
                                            sx={{padding: 0}}
                                            onClick={() =>
                                                navigate("/template/" + order.templateId)
                                            }
                                        >
                                            <EditPencilIcon htmlColor={"#646367"}/>
                                        </IconButton>
                                    </Tooltip>
                                    {order.recipientCount === 0 &&
                                        order.mailedCount === 0 && (
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    sx={{padding: 0}}
                                                    onClick={() => setShowDeleteOrderModal(true)}
                                                >
                                                    <DeleteIcon htmlColor={"#646367"}/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                </>)}
                            </Stack>
                        </Grid>
                    </Grid>
                </MDCard>
            </MDBox>

            <MDBox mt={3}>
                <MDTypography mb={1} fontWeight={"bold"} variant={"h4"} color={"secondary"}>Recipients</MDTypography>

                <MDBox height={"400px"} mt={2}>
                    {columns.length?
                        <StyledDataGrid
                            loading={getOrderRecipientsQuery.isLoading}
                            rows={recipients ?? []}
                            columns={[
                                ...columns.filter((value, index) => usedColumns.includes(value.field.toLowerCase()))
                            ]}
                            initialState={{
                                pagination: {paginationModel: {pageSize: 25}},
                            }}
                            pageSizeOptions={[25]}
                            pagination={true}
                            disableRowSelectionOnClick={true}
                        />
                        :
                        null
                    }
                </MDBox>
            </MDBox>
        </DialogContent>

        <InfoModal
            show={showDeleteOrderModal}
            setShow={setShowDeleteOrderModal}
            headerText={"Are you sure you want to delete this order?"}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteOrderModal(false)
            }}
            confirmButtonOnClick={() => {
                deleteOrderMutation.mutate()
            }}
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showEditNameModal}
            onClose={() => {
                setShowEditNameModal(false)
            }}
            formFieldValue={newName}
            formFieldOnChange={(value) => {
                setNewName(value)
            }}
            formFieldLabel={"New Name"}
            formFieldHelperText={"Please name your order, this will help you identify it later"}
            primaryButtonText={"Okay"}
            primaryButtonOnClick={() => {
                changeOrderNameMutation.mutate()
            }}
            secondaryButtonOnClick={() => {
                setShowEditNameModal(false)
            }}
            secondaryButtonText={"Cancel"}
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showCreateTemplateModal}
            onClose={() => setShowCreateTemplateModal(false)}
            formFieldLabel={"Template Name"}
            formFieldHelperText="Please name your template, this will help you identify it later"
            formFieldValue={templateName}
            formFieldOnChange={setTemplateName}
            primaryButtonText="Create"
            primaryButtonOnClick={() => createTemplateFromOrderMutation.mutate()}
            secondaryButtonText="Cancel"
            secondaryButtonOnClick={() => setShowCreateTemplateModal(false)}
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showCopyIntegrationOrderModal}
            onClose={() => setShowCopyIntegrationOrderModal(false)}
            formFieldLabel={"Order Name"}
            formFieldHelperText="Please name your order, this will help you identify it later"
            formFieldValue={newIntegrationOrderName}
            formFieldOnChange={setNewIntegrationOrderName}
            primaryButtonText="Copy"
            primaryButtonOnClick={() => copyIntegrationOrderMutation.mutate()}
            secondaryButtonText="Cancel"
            secondaryButtonOnClick={() => setShowCreateTemplateModal(false)}
        />

        <CopyOrderModal
            show={showCopyOrderModal}
            setShow={setShowCopyOrderModal}
            order={order}
        />
        
        <Dialog
          fullWidth
          open={showPreviewImagesModal}
          maxWidth={false}
          sx={{ maxWidth: 2000, marginX: 'auto' }}
          onClose={() => setShowPreviewImagesModal(false)}
        >
          <TemplateScenePreview {...order} sx={{ padding: 2 }} isBifold={isBifold} />
        </Dialog>
    </Dialog>
}

export default OrderDetailsModal
