import {IProfile} from "../models/profile";

export function isLettrLabsUser(profile?: IProfile){
    if(!profile) return false
    return profile.email.toLowerCase().endsWith("lettrlabs.com") || profile.loggedInUser.email.toLowerCase().endsWith("lettrlabs.com")
}

export function isLettrLabsAdminUser(profile?: IProfile){
    if(!profile) return false
    return profile.email.toLowerCase().endsWith("fabio@lettrlabs.com") || 
    profile.loggedInUser.email.toLowerCase().endsWith("fabio@lettrlabs.com") ||
    profile.email.toLowerCase().endsWith("kornel@lettrlabs.com") || 
    profile.loggedInUser.email.toLowerCase().endsWith("kornel@lettrlabs.com")
}

export function isLettrLabsExternalIntegrationsUser(profile?: IProfile){
    if(!profile) return false
    return profile.email.toLowerCase() === "lettrlabsintegrations@gmail.com" || profile.loggedInUser.email.toLowerCase() === "lettrlabsintegrations@gmail.com"
}

export function userHasHailTrace(profile: IProfile){
    return profile.industry === "Home Services" || isLettrLabsUser(profile)
}