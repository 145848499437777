export enum ProductTypes {
  HandwrittenPostCardA8 = "Handwritten Post Card",
  LargeHandwrittenCardA8 = "Handwritten Card + Envelope",
  HandwrittenBiFoldCard = "Handwritten Bi-Fold Card",
  HandwrittenProductInserts = "Handwritten Product Inserts",
  PrintedPostcard6x11 = "Printed Postcard (6x11)",
  PrintedPostcard4x6 = "Printed Postcard (4x6)",
  PrintedPostcard6x9 = "Printed Postcard (6x9)",
  PrintedCardNo10 = "Handwritten #10 Envelope & Printed Letter"
}

export enum DeprecatedProductTypes {
  MediumHandwrittenCardA7 = "Medium Handwritten Card (A7)"
}

export function orderProductIsPrintedCard (orderProduct: string){
  return orderProduct === ProductTypes.PrintedPostcard6x11
  || orderProduct === ProductTypes.PrintedPostcard4x6
  || orderProduct === ProductTypes.PrintedPostcard6x9
  || orderProduct === ProductTypes.PrintedCardNo10
}

export function orderProducUsesLetterUSPSPricing (orderProduct: string){
  return orderProduct === ProductTypes.LargeHandwrittenCardA8
  || orderProduct === ProductTypes.HandwrittenBiFoldCard
  || orderProduct === ProductTypes.PrintedPostcard6x11
  || orderProduct === ProductTypes.PrintedCardNo10
}

export function  orderProductHasNoEnvelope (orderProduct: string){
  return orderProduct === ProductTypes.HandwrittenPostCardA8
  || orderProduct === ProductTypes.PrintedPostcard6x11
  || orderProduct === ProductTypes.PrintedPostcard4x6
  || orderProduct === ProductTypes.PrintedPostcard6x9
}