import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import {IVM_CopyOrder, VM_CopyOrder} from "models/views/copyOrderVm"
import MDButton from "material-ui/components/MDButton"
import {DialogContent, Divider, Grid, Stack, Switch} from "@mui/material"
import MDTypography from "material-ui/components/MDTypography"
import {useEffect, useState} from "react"
import {IVM_GridOrder} from "models/views/gridOrderVm";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import OrderService from "services/order";
import {useNavigate} from "react-router-dom";
import { useGlobal } from "context/global-context"

export interface CopyOrderModalProps {
    show: boolean
    setShow: Function
    order?: IVM_GridOrder
}

// Data
function CopyOrderModal({show, setShow, order}: CopyOrderModalProps) {
    const navigate = useNavigate()
    const {setShowLoader} = useGlobal()
    const queryClient = useQueryClient()
    const {copyOrder} = OrderService()

    const [copyOrderVm, setCopyOrderVm] = useState<IVM_CopyOrder>(new VM_CopyOrder(order?.id!))
    const [selectAll, setSelectAll] = useState<boolean>(true)

    const copyOrderMutation = useMutation({
        mutationFn: () => {
            return copyOrder(copyOrderVm)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            queryClient.invalidateQueries({queryKey: ["orders"]})
            navigate(`/order/${data.id}`)
        }
    })

    useEffect(() => {
        setCopyOrderVm(prevState => {
            return {
                ...prevState,
                cardDesign: selectAll,
                returnAddress: selectAll,
                recipients: selectAll,
                handwrittenText: selectAll
            }
        })
    }, [selectAll]);

    return (
        <Dialog
            open={show}
            onClose={() => {
                setShow(false)
            }}
        >
            <DialogContent>
                <DialogTitle>Create new order from order</DialogTitle>

                <MDTypography sx={{fontSize: 18}}>
                    Select what components of the order you would like to copy
                </MDTypography>

                <Divider light={true}/>

                <Grid container alignItems={"center"} gap={1} sx={{cursor: "pointer"}} onClick={() => {
                    setSelectAll(prevState => !prevState)
                }}>
                    <Grid item>
                        <Switch checked={selectAll}/>
                    </Grid>

                    <Grid item>
                        <MDTypography
                            sx={{fontSize: 16}}
                            fontWeight="bold"
                            color="text"
                        >
                            Select/Unselect all
                        </MDTypography>
                    </Grid>
                </Grid>

                <Divider light={true}/>

                <Grid container alignItems={"center"} gap={1} sx={{cursor: "pointer"}} onClick={() => {
                    setCopyOrderVm(prevState => {
                        return {...prevState, cardDesign: !prevState.cardDesign}
                    })
                }}>
                    <Grid item>
                        <Switch
                            checked={copyOrderVm.cardDesign}
                        />
                    </Grid>

                    <Grid item>
                        <MDTypography
                            sx={{fontSize: 16}}
                            fontWeight="bold"
                            color="text"
                        >
                            Card Design
                        </MDTypography>
                    </Grid>
                </Grid>

                <Grid container mt={1} alignItems={"center"} gap={1} sx={{cursor: "pointer"}} onClick={() => {
                    setCopyOrderVm(prevState => {
                        return {...prevState, handwrittenText: !prevState.handwrittenText}
                    })
                }}>
                    <Switch
                        checked={copyOrderVm.handwrittenText}
                    />
                    <MDTypography
                        sx={{fontSize: 16}}
                        fontWeight="bold"
                        color="text"
                    >
                        Handwritten Text
                    </MDTypography>
                </Grid>

                <Grid container mt={1} alignItems={"center"} gap={1} sx={{cursor: "pointer"}} onClick={() => {
                    setCopyOrderVm(prevState => {
                        return {...prevState, returnAddress: !prevState.returnAddress}
                    })
                }}>
                    <Switch
                        checked={copyOrderVm.returnAddress}
                    />
                    <MDTypography
                        sx={{fontSize: 16}}
                        fontWeight="bold"
                        color="text"
                    >
                        Return Address
                    </MDTypography>
                </Grid>

                <Grid container mt={1} alignItems={"center"} gap={1} sx={{cursor: "pointer"}} onClick={() => {
                    setCopyOrderVm(prevState => {
                        return {...prevState, recipients: !prevState.recipients}
                    })
                }}>
                    <Switch
                        checked={copyOrderVm.recipients}
                    />
                    <MDTypography
                        sx={{fontSize: 16}}
                        fontWeight="bold"
                        color="text"
                    >
                        Recipients
                    </MDTypography>
                </Grid>

                <Stack direction="row" spacing={1} marginTop={2} justifyContent="flex-end">
                    <MDButton color="light" onClick={() => setShow(false)}>
                        Cancel
                    </MDButton>
                    <MDButton
                        color="primary"
                        onClick={() => {
                            copyOrderMutation.mutate()
                        }}
                        disabled={copyOrderMutation.isPending}
                    >
                        Submit
                    </MDButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default CopyOrderModal