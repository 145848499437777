import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import MDBox from 'material-ui/components/MDBox';
import MDCard from 'material-ui/components/MDCard';
import MDTypography from 'material-ui/components/MDTypography';
import React from 'react';
import EnhancedTable from './table';

const OrderSummary: React.FC = () => {
  return (
    <MDBox sx={{ maxWidth: '1376px', margin: '0 auto' }}>
        <MDCard color={"white"} boxShadow={false} border={false} sx={{ position: 'relative', margin: '.25rem'}}>
            <MDBox px={4}>
                <MDBox>
                    <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                        <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                            Order Summary
                        </MDTypography>
                        {/* <MDButton variant="transparent" color="secondary">Download Orders</MDButton> */}
                    </Grid>
                    <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />
                </MDBox>
                <Box p={4}>
                    <EnhancedTable />
                </Box>
            </MDBox>
        </MDCard>
    </MDBox>
  )
}

export default OrderSummary;