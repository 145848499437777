import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { debounce } from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions } from 'newStandard/src/services/order/types';
import useFontService from 'newStandard/src/services/font';
import { addGlyphs } from 'helpers/glyphHelper';

import { checkHasEnvelope, checkIsPrintedCard } from '../utils/templateHelper';
import { useEditorContext } from '../contexts/useEditorContext';
import { BlockNames } from '../utils/sceneEnums';

const PRINTED_POSTCARD_RETURN_ADDRESS_FONT_SIZE = 8;

export default function useReturnAddressUpdate() {
  const { template } = useWorkflowContext();
  const { getFontGlyphs } = useFontService();
  const { engine, envelopeEngine, selectedFont } = useEditorContext();
  const [retryEnvelopeFontSize, setRetryEnvelopeFontSize] = useState<number>(0);
  const [retry, setRetry] = useState<number>(0);

  const { data: fontGlyphs } = useQuery({
    queryKey: ['font-glyphs', selectedFont?.id],
    queryFn: () => getFontGlyphs(selectedFont?.id),
  });

  useEffect(() => {
    const hasEnvelope = checkHasEnvelope(template.product);
    const currentEngine = hasEnvelope ? envelopeEngine : engine;
    if (!currentEngine?.block || (hasEnvelope && !fontGlyphs?.value)) return;
    const hideGlyphs =
      template.product !== ProductOptions.LargeHandwrittenCardA8 &&
      template.product !== ProductOptions.HandwrittenBiFoldCard &&
      template.product !== ProductOptions.PrintedCardNo10;

    let text = '';
    if (template.returnFirstName) text = addGlyphs(template.returnFirstName, fontGlyphs?.value, hideGlyphs) + ' ';
    if (template.returnLastName) text += addGlyphs(template.returnLastName, fontGlyphs?.value, hideGlyphs);
    if (template.returnOrganization) {
      if (text) text += '\n';
      text += addGlyphs(template.returnOrganization, fontGlyphs?.value, hideGlyphs);
    }
    text += '\n';
    if (template.returnAddress1) text += addGlyphs(template.returnAddress1, fontGlyphs?.value, hideGlyphs);
    if (template.returnAddress2) text += ', ' + addGlyphs(template.returnAddress2, fontGlyphs?.value, hideGlyphs);
    text += '\n';
    if (template.returnCity) text += addGlyphs(template.returnCity, fontGlyphs?.value, hideGlyphs) + ', ';
    if (template.returnState) text += addGlyphs(template.returnState, fontGlyphs?.value, hideGlyphs) + ' ';
    if (template.returnZip) text += addGlyphs(template.returnZip, fontGlyphs?.value, hideGlyphs);
    if (!text.trim()) {
      text = checkHasEnvelope(template.product)
        ? '{{FIRST NAME}} {{LAST NAME}}\n{{ORGANIZATION}}\n{{ADDRESS 1}}, {{ADDRESS 2}}\n{{CITY}} {{STATE}} {{ZIP}}'
        : 'Your Name or Org\nReturn Address\nCity, State Zip';
    }

    const returnAddresses = currentEngine.block.findByName(BlockNames.ReturnAddress);
    const debouncedRetry = debounce((attempt: number) => setRetry(attempt), 300);
    if (!returnAddresses.length) return debouncedRetry(retry + 1);
    returnAddresses.forEach((blockId) => {
      currentEngine.block.setScopeEnabled(blockId, 'text/edit', true);
      currentEngine.block.setString(blockId, 'text/text', text);
    });
  }, [
    retry,
    engine,
    envelopeEngine,
    fontGlyphs,
    template.product,
    template.returnAddress1,
    template.returnAddress2,
    template.returnCity,
    template.returnFirstName,
    template.returnLastName,
    template.returnOrganization,
    template.returnState,
    template.returnZip,
  ]);

  // * Change envelope font size
  useEffect(() => {
    const currentEngine = checkHasEnvelope(template.product) ? envelopeEngine : engine;
    if (!currentEngine?.block) return;
    const texts = [
      ...currentEngine.block.findByName(BlockNames.ReturnAddress),
      ...currentEngine.block.findByName(BlockNames.Envelope_ToAddress),
    ];
    const debouncedRetry = debounce((attempt: number) => setRetryEnvelopeFontSize(attempt), 300);
    if (!texts.length) return debouncedRetry(retryEnvelopeFontSize + 1);
    const returnAddressFontSize = checkIsPrintedCard(template.product)
      ? PRINTED_POSTCARD_RETURN_ADDRESS_FONT_SIZE
      : template.fontSizeReturnAddress;
    texts.forEach((text) => currentEngine.block.setFloat(text, 'text/fontSize', returnAddressFontSize));
  }, [retryEnvelopeFontSize, envelopeEngine, template.fontSizeReturnAddress, template.product, engine]);
}
