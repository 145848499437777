import useFetchWithMsal from 'hooks/useFetch';

import API_ENDPOINT from '../endpoint';

import { IAutomationsResponse,  } from './automatios.service.types';

const useAutomationsService = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/automations`;

  const getAutomationByID = (automationId: number, automationType: number) => {
    return execute({
      endpoint: `${API_PATH}/${automationId}/type/${automationType}/detail`,
      method: 'GET',
    });
  };

  const getRecipients = (automationId: number, automationType: number) => {
    return execute({
      endpoint: `${API_PATH}/${automationId}/type/${automationType}/recipients`,
      method: 'GET',
    });
  };

  const getAllAutomations = (): Promise<IAutomationsResponse> => {
    return execute({
      endpoint: API_PATH,
      method: 'GET',
    });
  };

  const updateAutomationName = (
    automationId: number, automationType: number, newValue: string 
  ) => {
    return execute({
      endpoint: `${API_PATH}/${automationId}/type/${automationType}/rename`,
      method: 'PUT',
      data: { name: newValue},
    });
  };
  
  const deleteAutomation = (
    automationId: number, automationType: number 
  ) => {
    return execute({
      endpoint: `${API_PATH}/${automationId}/type/${automationType}`,
      method: 'DELETE'
    });
  };

  const duplicateAutomation = (
    automationId: number, automationType: number, newValue: string 
  ) => {
    return execute({
      endpoint: `${API_PATH}/${automationId}/type/${automationType}/duplicate`,
      method: 'POST',
      data: { name: newValue},
    });
  };

  const getStatusMessageBeforeUpdate = (automationId: number, automationType: number, status: string) => {
    return execute({
      endpoint: `${API_PATH}/${automationId}/type/${automationType}/change-status-message/${status}`,
      method: 'GET',
    });
  };

  const updateStatus = (
    automationId: number, automationType: number, newStatus: string, retroactiveProcessingDate: string
  ) => {
    return execute({
      endpoint: `${API_PATH}/${automationId}/type/${automationType}/change-status`,
      method: 'PUT',
      data: { status: newStatus, retroactiveProcessingDate: retroactiveProcessingDate},
    });
  };

  return {
    updateStatus,
    getRecipients,
    deleteAutomation,
    getAllAutomations,
    getAutomationByID,
    duplicateAutomation,
    updateAutomationName,
    getStatusMessageBeforeUpdate,
  };
};

export default useAutomationsService;
