import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Tooltip } from '@mui/material';

import useOrderRecipientService from 'newStandard/src/services/orderRecipient';
import { defaultSearchParams } from 'components/table/table.utils';
import ProfileRecipientService from 'services/profile-recipient';
import { GridSearchParams } from 'models/gridSearchParams';
import MDButton from 'material-ui/components/MDButton';
import RecipientTag from 'components/recipient-tag';
import Table from 'components/table';

interface AddRecipientsFromAddressBookModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const AddRecipientsFromAddressBookModal = ({ show, onClose, onSubmit }: AddRecipientsFromAddressBookModalProps) => {
  const params = useParams();
  const orderId = parseInt(params.id || '');

  const { getProfileRecipientsServerPagination } = ProfileRecipientService();
  const { addFromAddressBook } = useOrderRecipientService();

  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useState<GridSearchParams>(defaultSearchParams);

  const { data, isLoading } = useQuery({
    queryKey: ['profileRecipients', searchParams],
    queryFn: () => getProfileRecipientsServerPagination(...Object.values(searchParams)),
  });
  const addresses = data?.payload || [];
  const totalRowCount = data?.totalCount;

  const { mutate: updateSelectedAddress, isPending } = useMutation({
    mutationFn: () => addFromAddressBook(orderId, selectedRows, searchParams, allSelected),
    onSuccess: () => {
      setSelectedRows([]);
      onSubmit();
      onClose();
    },
  });

  const handleCloseModal = () => {
    if (isPending) return;
    setSelectedRows([]);
    onClose();
  };

  const renderTags = (params: GridRenderCellParams) => {
    if (!params.value) return null;
    const tagsList: string[] = params.value.split(',');
    const tags = tagsList.map((tag, index) => <RecipientTag name={tag} key={index} />);
    return (
      <Tooltip arrow={false} title={<Box>{tags}</Box>}>
        <Box overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
          {tags}
        </Box>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    { headerName: 'First Name', field: 'firstName', width: 150 },
    { headerName: 'Last Name', field: 'lastName', width: 150 },
    { headerName: 'Address 1', field: 'address1', flex: 1, minWidth: 200 },
    { headerName: 'Address 2', field: 'address2', width: 150 },
    { headerName: 'City', field: 'city', width: 110 },
    { headerName: 'State', field: 'state', width: 72, align: 'center' },
    { headerName: 'Zip Code', field: 'zipCode', width: 92, align: 'center' },
    { headerName: 'To Organization', field: 'toOrganization', width: 150 },
    { headerName: 'Tags', field: 'tags', flex: 1, minWidth: 200, renderCell: renderTags },
  ];

  return (
    <Dialog fullWidth open={show} onClose={handleCloseModal} maxWidth={false} sx={{ maxWidth: 2000, marginX: 'auto' }}>
      <DialogContent>
        <DialogTitle>Add recipients from your address book</DialogTitle>
        <Table
          serverDriven
          rows={addresses}
          columns={columns}
          isLoading={isLoading}
          tableHeight={'55vh'}
          totalRowCount={totalRowCount}
          selectedRows={selectedRows}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          setSearchParams={setSearchParams}
          setSelectedRows={setSelectedRows}
        />
        <Stack direction="row" spacing={2} marginTop={2} justifyContent="flex-end">
          <MDButton color="light" onClick={handleCloseModal}>
            Cancel
          </MDButton>
          <MDButton
            color="primary"
            onClick={() => updateSelectedAddress()}
            disabled={!selectedRows.length || isPending}
            sx={{ width: 84 }}
          >
            {isPending ? <CircularProgress size={18} color="inherit" /> : 'Save'}
          </MDButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddRecipientsFromAddressBookModal;
