import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { removeGlyphs } from 'helpers/glyphHelper';
import useFontService from 'newStandard/src/services/font';
import { formatUSPhoneNumber } from 'helpers/phoneNumberFormat';
import { ProductOptions, YesNo } from 'newStandard/src/services/order/types';
import { addressToReturnSchema } from 'models/modelValidations/returnAddress';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import {
  checkIsFrontSide,
  checkMailMergeError,
  checkUnsafeZone,
  handwrittenTextNames,
  EditorBackErrorsType,
  EditorFrontErrorsType,
  EditorBackWarningsType,
  ignoredImageNames,
  ignoredTextNames,
  areSetsEqual,
  EditorEnvelopeErrorsType,
} from '../utils/errorHelper';
import { checkHasEnvelope, checkIsPrintedCard, DefaultText } from '../utils/templateHelper';
import { BlockNames, DefaultArtifacts } from '../utils/sceneEnums';
import { useEditorContext } from '../contexts/useEditorContext';
import { getMailMergeFields } from '../utils/mailMergeOptions';
import { getImageDpi } from '../utils/imageHelper';

export default function useBlockValidations() {
  const { getFontGlyphs } = useFontService();
  const { template, setTemplate } = useWorkflowContext();
  const { engine, selectedFont, isApplyingTemplateId } = useEditorContext();

  const [backErrors, setBackErrors] = useState<Set<EditorBackErrorsType>>(new Set());
  const [frontErrors, setFrontErrors] = useState<Set<EditorFrontErrorsType>>(new Set());
  const [envelopeErrors, setEnvelopeErrors] = useState<Set<EditorEnvelopeErrorsType>>(new Set());
  const [backWarnings, setBackWarnings] = useState<Set<EditorBackWarningsType>>(new Set());

  const { data: fontGlyphs } = useQuery({
    queryKey: ['font-glyphs', selectedFont?.id],
    queryFn: () => getFontGlyphs(selectedFont?.id),
  });

  useEffect(() => {
    const isPrintedCard = checkIsPrintedCard(template.product);
    const isPrintedCardWithoutRearImage = template.product === ProductOptions.PrintedCardNo10;
    if (!engine?.event || !engine?.block || (!isPrintedCard && !fontGlyphs?.value)) return;

    const callback = async () => {
      if (isApplyingTemplateId) return;

      let doubleSided = YesNo.No;
      let callTrackingSide: 'Front' | 'Rear';
      const phoneNumbers: string[] = [];
      const isBifold = template.product === ProductOptions.HandwrittenBiFoldCard;
      const auxFrontErrors = new Set<EditorFrontErrorsType>(['noText']);
      const auxBackErrors = new Set<EditorBackErrorsType>(['missingRearImage']);
      const auxBackWarnings = new Set<EditorBackWarningsType>(['missingOutsideImage']);

      // * Text Validations

      const textBlocks = engine.block?.findByType('//ly.img.ubq/text') || [];

      for (const block of textBlocks) {
        if (!engine.block?.isValid(block)) continue;
        const isFrontSide = checkIsFrontSide(engine, block);
        const blockName = engine.block.getName(block) as BlockNames;
        let text = engine.block.getString(block, 'text/text');

        if (ignoredTextNames.includes(blockName)) continue;
        if (isPrintedCard) auxFrontErrors.delete('noText');
        if (handwrittenTextNames.includes(blockName)) {
          if (engine.block.getBool(block, 'text/hasClippedLines')) auxFrontErrors.add('textOutOfBounds');
          if (text.trim().length) auxFrontErrors.delete('noText');
          if (fontGlyphs?.value) {
            text = removeGlyphs(text, fontGlyphs?.value);
            if (checkMailMergeError(text)) auxFrontErrors.add('invalidMailMerge');
            if (text === DefaultText.Text || text === DefaultText.Text2) auxFrontErrors.add('defaultText');
          }
        } else {
          if ((isFrontSide && isBifold) || (!isFrontSide && !isBifold)) doubleSided = YesNo.Yes;
          if (getMailMergeFields(text)?.length) {
            if (isFrontSide) auxFrontErrors.add('printedMailMerge');
            else auxBackErrors.add('printedMailMerge');
          }
        }
        const match = text.match(/\+1\(\d{3}\) \d{3}-\d{4}/);
        if (match) {
          phoneNumbers.push(...match);
          callTrackingSide = isFrontSide ? 'Front' : 'Rear';
        }
      }

      // * Image Validations

      const shapeBlocks = engine.block.findByKind('shape');
      const genericImageBlocks = engine.block.findByKind('image');
      const placeholderImageBlocks = engine.block.findByKind('image/placeholder');

      let invalidImages = 0;
      const allImageBlocks = [...genericImageBlocks, ...shapeBlocks, ...placeholderImageBlocks];

      for (const block of allImageBlocks) {
        if (!engine.block?.isValid(block) || ignoredImageNames.includes(engine.block.getName(block) as BlockNames)) {
          invalidImages += 1;
          continue;
        }
        const isFrontSide = checkIsFrontSide(engine, block);
        if (!isPrintedCard || isPrintedCardWithoutRearImage) auxBackErrors.delete('missingRearImage');
        if (!isBifold) auxBackWarnings.delete('missingOutsideImage');

        let yMin = -0.1;
        let yMax = 0.1;
        if(isBifold){
          yMin = 5.25;
          yMax = 5.5
        };
        if(isPrintedCardWithoutRearImage){
          yMin = 10.5;
          yMax = 11
        };
        if (checkUnsafeZone(engine, block, yMin, yMax)) {
          const blockName = engine.block.getName(block);
          const errorName = blockName === BlockNames.QRCode ? 'qrCodeUnsafeZone' : 'imageUnsafeZone';
          if (isFrontSide) {
            if(blockName !== BlockNames.No10CardBorderWhiteBlocks){
              auxFrontErrors.add(errorName);
            }
          }
          else auxBackErrors.add(errorName);
        }
        const imageResolution = await getImageDpi(engine, block);
        if (!imageResolution || imageResolution === -1) continue;
        if (imageResolution < 200) {
          if (isFrontSide) auxFrontErrors.add('badImageResolution');
          else auxBackErrors.add('badImageResolution');
        }
        if (!isFrontSide) {
          try {
            const imageFill = engine.block.isFillEnabled(block) ? engine.block.getFill(block) : null;
            const imageUrl = imageFill ? engine.block.getString(imageFill, 'fill/image/imageFileURI') : null;
            const artifactName = isBifold ? DefaultArtifacts.OutsideFront : DefaultArtifacts.Rear;
            if (!isBifold && imageUrl && !imageUrl.includes('/scenes/')) doubleSided = YesNo.Yes;
            if (isPrintedCard && imageUrl && imageUrl !== artifactName) auxBackErrors.delete('missingRearImage');
            if (isPrintedCardWithoutRearImage) auxBackErrors.delete('missingRearImage');
            if (isBifold && imageUrl && imageUrl !== artifactName) auxBackWarnings.delete('missingOutsideImage');
          } catch (e) {
            console.error('Error validating image:', e);
          }
        } else if (isBifold) doubleSided = YesNo.Yes;
      }

      if (allImageBlocks.length === invalidImages) {
        if (!isPrintedCard) auxBackErrors.delete('missingRearImage');
        if (!isBifold) auxBackWarnings.delete('missingOutsideImage');
      }

      setFrontErrors((prev) => {
        if (prev.has('missingReturnAddress')) auxFrontErrors.add('missingReturnAddress');
        if (prev.has('invalidReturnAddress')) auxFrontErrors.add('invalidReturnAddress');
        return areSetsEqual(prev, auxFrontErrors) ? prev : auxFrontErrors;
      });
      setBackErrors((prev) => (areSetsEqual(prev, auxBackErrors) ? prev : auxBackErrors));
      setBackWarnings((prev) => (areSetsEqual(prev, auxBackWarnings) ? prev : auxBackWarnings));

      setTemplate((prev) => {
        const removeQrCode = engine.block.findByName(BlockNames.QRCode).length === 0 && prev.useQr;
        const removeCallTracking =
          prev.trackingPhoneNumber && !phoneNumbers.includes(formatUSPhoneNumber(prev.trackingPhoneNumber));
        if (
          prev.doubleSided === doubleSided &&
          !removeQrCode &&
          !removeCallTracking &&
          callTrackingSide === prev.callTrackingSide
        ) {
          return prev;
        }
        const updatedTemplate = { ...prev, doubleSided, callTrackingSide };
        if (removeCallTracking) updatedTemplate.trackingPhoneNumber = null;
        if (removeQrCode) {
          updatedTemplate.useQr = false;
          updatedTemplate.qrAngle = null;
          updatedTemplate.qrCodeHeight = null;
          updatedTemplate.qrCodeSide = null;
          updatedTemplate.qrCodeWidth = null;
          updatedTemplate.qrCodeX = null;
          updatedTemplate.qrCodeY = null;
          updatedTemplate.qrUrl = null;
        }
        return updatedTemplate;
      });
    };

    const unsubscribe = engine.event.subscribe([], callback);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engine, fontGlyphs?.value, template.product, setTemplate]);

  // * Return Address Validations

  useEffect(() => {
    const returnAddress = {
      returnAddress1: template.returnAddress1,
      returnAddress2: template.returnAddress2,
      returnCity: template.returnCity,
      returnFirstName: template.returnFirstName,
      returnLastName: template.returnLastName,
      returnOrganization: template.returnOrganization,
      returnState: template.returnState,
      returnZip: template.returnZip,
    };

    const handleErrors = (errors: string[], prev: Set<EditorEnvelopeErrorsType>) => {
      const requiredErrors = errors.filter((err) => err.includes('Required'));
      const updatedErrors = new Set(prev);
      if (requiredErrors.length) updatedErrors.add('missingReturnAddress');
      else updatedErrors.delete('missingReturnAddress');
      if (requiredErrors.length !== errors.length) updatedErrors.add('invalidReturnAddress');
      else updatedErrors.delete('invalidReturnAddress');
      return updatedErrors;
    };

    addressToReturnSchema
      .validate(returnAddress, { abortEarly: false })
      .then(() => {
        if (checkHasEnvelope(template.product)) setEnvelopeErrors((prev) => (prev.size ? new Set() : prev));
        else {
          setFrontErrors((prev) => {
            const updatedErrors = new Set(prev);
            updatedErrors.delete('missingReturnAddress');
            updatedErrors.delete('invalidReturnAddress');
            return areSetsEqual(prev, updatedErrors) ? prev : updatedErrors;
          });
        }
      })
      .catch((err) => {
        if (checkHasEnvelope(template.product)) setEnvelopeErrors((prev) => handleErrors(err.errors, prev));
        else setFrontErrors((prev) => handleErrors(err.errors, prev as Set<EditorEnvelopeErrorsType>));
      });
  }, [
    template.product,
    template.returnAddress1,
    template.returnAddress2,
    template.returnCity,
    template.returnFirstName,
    template.returnLastName,
    template.returnOrganization,
    template.returnState,
    template.returnZip,
  ]);

  return { backErrors, frontErrors, backWarnings, envelopeErrors };
}
