import { CreativeEngine } from '@cesdk/cesdk-js';

import { ProductOptions } from 'newStandard/src/services/order/types';

import { getMailMergeFields, allMailMergeFields, MailMergeFieldType } from './mailMergeOptions';
import { BlockNames } from './sceneEnums';

// * Types

type EditorErrorsType = 'printedMailMerge' | 'imageUnsafeZone' | 'qrCodeUnsafeZone' | 'badImageResolution';

export type EditorFrontErrorsType =
  | EditorErrorsType
  | 'invalidMailMerge'
  | 'defaultText'
  | 'noText'
  | 'textOutOfBounds'
  | 'missingReturnAddress'
  | 'invalidReturnAddress';

export type EditorBackErrorsType = EditorErrorsType | 'missingRearImage';

export type EditorEnvelopeErrorsType = 'missingReturnAddress' | 'invalidReturnAddress';

export type EditorBackWarningsType = 'missingOutsideImage';

// * Constants

export const errorMessages: { [key: string]: { title: string; description: string } } = {
  // ** Error messages
  invalidMailMerge: {
    title: 'Invalid mail merge fields',
    description: 'Refrain from typing your own text inside the {{brackets}}. Please use the Mail-Merge buttons instead',
  },
  printedMailMerge: {
    title: 'No printed mail merge',
    description: 'Mail merge fields should only be placed within the handwritten text blocks.',
  },
  defaultText: {
    title: 'Change Default Text',
    description: 'Please change the default text of your card',
  },
  noText: {
    title: 'No Text',
    description: 'Please add some text to your card',
  },
  textOutOfBounds: {
    title: 'Text out of bounds',
    description: 'Your handwritten text is outside of its safe zone, please adjust before moving forward',
  },
  imageUnsafeZone: {
    title: 'Image full bleed issue',
    description:
      'Your image must be adjusted to dotted purple full bleed lines to avoid uneven white space around your card',
  },
  qrCodeUnsafeZone: {
    title: 'QrCode full bleed issue',
    description: 'Your qr code must be adjusted within the green dotted safe zone lines, otherwise it might be cut off',
  },
  missingRearImage: {
    title: 'Missing card rear image',
    description: 'Printed postcards must have an image in the card rear, please adjust before moving forward',
  },
  badImageResolution: {
    title: 'Bad Image Resolution',
    description:
      'Unacceptable Image Resolution: Less then 200 DPI. Image will appear pixelated or blurry once printed. Reduce the image size to improve the DPI or replace with a higher resolution image',
  },
  missingReturnAddress: {
    title: 'Missing Return Address',
    description: 'Address 1, City, State, and Zip are required fields and must be filled out in the return address',
  },
  invalidReturnAddress: {
    title: 'Invalid Return Address',
    description: 'Please check the address fields to ensure they are in the correct format',
  },
  // ** Warning messages
  missingOutsideImage: {
    title: 'Missing Outside Image',
    description: 'Bi-fold cards look better with an image on the outside.',
  },
  invalidImageSize: {
    title: 'Image too large',
    description: 'We do not currently support images over 25 megabytes in size.',
  },
};

export const handwrittenTextNames = [BlockNames.HandwrittenText, BlockNames.HandwrittenText2];

export const ignoredImageNames = [
  BlockNames.Postcard_Stamp,
  BlockNames.Postcard_USPSBarCode,
  BlockNames.Line,
  BlockNames.InheritedShape,
  BlockNames.UspsStamp,
  BlockNames.USPSBarCode,
  BlockNames.USPSAddress,
];

export const ignoredTextNames = [
  BlockNames.SafeZoneText,
  BlockNames.SafeZoneSmallText,
  BlockNames.SafeZoneBigText,
  BlockNames.FullBleedSmallText,
  BlockNames.FullBleedBigText,
  BlockNames.OutsideKeepOutAreaText,
  BlockNames.SceneVersion,
  BlockNames.Version,
  BlockNames.Crease,
];

// * Functions

function formatBlockFloat(value: number) {
  return parseFloat(value.toFixed(2));
}

export function checkUnsafeZone(engine: CreativeEngine, blockId: number, yMin: number = -0.1, yMax: number = 0.1) {
  const page = engine.block.getParent(blockId);
  if (!page) return false;

  const pageWidth = formatBlockFloat(engine.block.getWidth(page));
  const pageHeight = formatBlockFloat(engine.block.getHeight(page));

  const blockX1 = formatBlockFloat(engine.block.getPositionX(blockId));
  const blockX2 = formatBlockFloat(engine.block.getPositionX(blockId) + engine.block.getWidth(blockId));
  const blockY1 = formatBlockFloat(engine.block.getPositionY(blockId));
  const blockY2 = formatBlockFloat(engine.block.getPositionY(blockId) + engine.block.getHeight(blockId));

  if (blockX1 < 0.1 && blockX1 > -0.1) return true;
  if (blockX2 > pageWidth - 0.1 && blockX2 < pageWidth + 0.1) return true;
  if (blockY1 < yMax && blockY1 > yMin) return true;
  if (blockY2 > pageHeight - 0.1 && blockY2 < pageHeight + 0.1) return true;
  if (blockX1 === 0.1 || blockX2 === pageWidth - 0.1 || blockY1 === 0.1 || blockY2 === pageHeight - 0.1) return true;
  return false;
}

export function checkIsFrontSide(engine: CreativeEngine, blockId: number) {
  const pages = engine.scene.getPages();
  const parent = engine.block.getParent(blockId);
  return parent === pages[0];
}

export function checkMailMergeError(text: string) {
  let hasError = false;
  const matches = getMailMergeFields(text);
  matches?.forEach((match) => {
    const field = match.replaceAll('{', '').replaceAll('}', '') as MailMergeFieldType;
    if (!allMailMergeFields.includes(field)) hasError = true;
  });
  return hasError;
}

export function checkIsDoubleSidedCharged(product: ProductOptions) {
  if (product === ProductOptions.HandwrittenBiFoldCard || product === ProductOptions.LargeHandwrittenCardA8) {
    return true;
  }
  return false;
}

export function areSetsEqual<T>(setA: Set<T>, setB: Set<T>) {
  if (setA.size !== setB.size) return false;
  for (let item of setA) if (!setB.has(item)) return false;
  for (let item of setB) if (!setA.has(item)) return false;
  return true;
}
