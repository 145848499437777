import React from 'react';

import { Box } from '@mui/system';
import { List, ListItem, Typography } from '@mui/material';

const Shopify: React.FC = () => {
  return (
    <Box sx={{ margin: '1rem 0'}}>
        <Typography variant='body2' sx={{ fontWeight: 'bold'}}>Shopify</Typography>
        <List>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2'>Log in to your Shopify admin panel</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Navigate to <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> Online Store <pre>{'>'}</pre> Themes</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Click <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> Actions <pre>{'>'}</pre> Edit code</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', gap: 1}}>In the Layout section, click on the <b>theme.liquid</b> file</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Insert the provided scripts just before the closing <b><pre>{'</head>'}</pre></b> and <b><pre>{'</body>'}</pre></b> tags</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Click save</Typography>
            </ListItem>
        </List>
    </Box>
  );
}

export default Shopify;