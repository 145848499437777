// @ts-nocheck

import {DialogContent, Divider, Grid, Tooltip} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {Circle, GoogleMap, OverlayView, Polygon as MapsPolygon} from "@react-google-maps/api";
import React, {useEffect, useState} from "react";
import MDBox from "material-ui/components/MDBox";
import {Coordinate} from "models/coordinate";
import MDButton from "material-ui/components/MDButton";
import RadiusIcon from "assets/icons/radius";
import PolygonIcon from "assets/icons/polygon";
import MDTypography from "material-ui/components/MDTypography";
import {ServiceArea} from "models/hail-trace/ServiceArea";
import {getCenterOfPolygon, getCoordinatesAddress, mapContainerStyle, mapInfoTextContainer} from "helpers/google-map-helper";
import colors from "material-ui/theme/base/colors";
import MDCard from "material-ui/components/MDCard";
import MDInput from "material-ui/components/MDInput";
import MDIconButton from "material-ui/components/MDIconButton";
import DeleteIcon from "assets/icons/delete";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "assets/icons/close";

interface ServiceAreaModalProps {
    show: boolean
    setShow: Function
    showCenter: Coordinate
    showZoom: number
    serviceArea: ServiceArea
    setServiceArea: Function
    geocoder: any
    addAddress: Function
    addPolygon: Function
    deleteAddress: Function
    deletePolygon: Function
    polygonsRef: any
    updateServiceArea: Function
    setShouldRefetch: Function
}

function ServiceAreaModal({show, setShow, showCenter, showZoom, serviceArea, setServiceArea, geocoder, addAddress, addPolygon, deletePolygon, deleteAddress, polygonsRef, updateServiceArea, setShouldRefetch}: ServiceAreaModalProps) {
    const [map, setMap] = useState<google.maps.Map>()

    const [isAddingAddress, setIsAddingAddress] = useState<boolean>(false)
    const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false)

    const [center, setCenter] = useState<Coordinate>()
    const [zoom, setZoom] = useState<number>(7)

    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)

    useEffect(() => {
        if(show){
            setCenter(showCenter)
            setZoom(showZoom)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }

    function onPolygonUnmount(){
        polygonsRef.current = []
    }

    function onPolygonLoad(polygon: google.maps.Polygon, id: number) {
        polygonsRef.current.push({id: id, polygon: polygon})
    }

    function onPolygonEdit(id: number) {
        let polygonToEdit = (polygonsRef.current.find((e: any) => e.id === id)).polygon

        let updatedPath = polygonToEdit.getPath().getArray().map((coordinate: google.maps.LatLng) => {
            return {lat: coordinate.lat(), lng: coordinate.lng()}
        })

        let centerOfPolygon = getCenterOfPolygon(updatedPath)

        getCoordinatesAddress(geocoder, centerOfPolygon.lat, centerOfPolygon.lng, (addressData: any) => {
            setServiceArea((prevState: ServiceArea) => {
                let copy = {...prevState}

                for (let el of copy.polygons) {
                    if (el.id === id) {
                        el.coordinates = updatedPath
                        el.name = addressData.shortAddress
                        break
                    }
                }

                return copy
            })
        })

        setHasMadeChanges(true)
    }

    function onModalClose(){
        setShow(false)

        if(hasMadeChanges){
            setShouldRefetch(false)
            updateServiceArea()
        }

        setHasMadeChanges(false)
    }

    return <Dialog open={show} maxWidth={false} onClose={onModalClose}>
        <DialogContent sx={{ width: "1200px"}}>
            <Grid container alignItems={"center"} justifyContent={"space-between"} mb={3}>
                <Grid item>
                    <DialogTitle sx={{marginBottom: "0 !important", fontSize: 24}}>Establish Service Area</DialogTitle>
                </Grid>

                <Grid item display={"flex"}>
                    <CloseIcon htmlColor={"secondary"} onClick={onModalClose}/>
                </Grid>
            </Grid>

            <MDBox sx={{height: "600px", position: "relative"}}>
                <Grid container gap={1.3} sx={{position: "absolute", left: "10px", top: "10px", zIndex: 2}} width={"fit-content"}>
                    <Grid item>
                        <MDButton
                            color="primary"
                            onClick={() => {
                                setIsAddingPolygon(false)
                                setIsAddingAddress(true)
                            }}
                        >
                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                <Grid item display={"flex"}>
                                    <RadiusIcon/>
                                </Grid>
                                <Grid item>
                                    Radius
                                </Grid>
                            </Grid>
                        </MDButton>
                    </Grid>

                    <Grid item>
                        <MDButton
                            color="primary"
                            onClick={() => {
                                setIsAddingAddress(false)
                                setIsAddingPolygon(true)
                            }}
                        >
                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                <Grid item display={"flex"}>
                                    <PolygonIcon/>
                                </Grid>
                                <Grid item>
                                    Polygon
                                </Grid>
                            </Grid>
                        </MDButton>
                    </Grid>
                </Grid>

                <GoogleMap
                    zoom={zoom}
                    center={center}
                    mapContainerStyle={mapContainerStyle}
                    options={mapOptions}
                    onLoad={(map) => {
                        setMap(map)
                    }}
                    onClick={(e: google.maps.MapMouseEvent) => {
                        let lat = e.latLng.lat()
                        let lng = e.latLng.lng()

                        if (isAddingAddress) {
                            getCoordinatesAddress(geocoder, lat, lng, (addressData: any) => {
                                addAddress(e.latLng.lat(), e.latLng.lng(), addressData)
                            })

                            setIsAddingAddress(false)

                            setHasMadeChanges(true)
                        }

                        if (isAddingPolygon) {
                            getCoordinatesAddress(geocoder, lat, lng, (addressData: any) => {
                                addPolygon(lat, lng, addressData)
                            })

                            setIsAddingPolygon(false)

                            setHasMadeChanges(true)
                        }
                    }}
                >
                    <MDBox sx={{
                        ...mapInfoTextContainer,
                        transform: isAddingAddress ? "translateY(0px)" : "translateY(50px)"
                    }}>
                        <MDTypography sx={{fontSize: 17}} fontWeight={"normal"} color={"white"}>
                            Click anywhere on the map to add an address
                        </MDTypography>
                    </MDBox>

                    <MDBox sx={{
                        ...mapInfoTextContainer,
                        transform: isAddingPolygon ? "translateY(0px)" : "translateY(50px)"
                    }}>
                        <MDTypography sx={{fontSize: 17}} fontWeight={"normal"} color={"white"}>
                            Click anywhere on the map to add a polygon
                        </MDTypography>
                    </MDBox>

                    {serviceArea.radii.map((radius, index) => {
                        return <MDBox key={index}>
                            <OverlayView
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                                position={{
                                    lat: radius.coordinate.lat,
                                    lng: radius.coordinate.lng
                                }}
                            >
                                <MDTypography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                            color={"white"}
                                            sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>
                                    {radius.name}
                                </MDTypography>
                            </OverlayView>

                            <Circle
                                center={{
                                    lat: radius.coordinate.lat,
                                    lng: radius.coordinate.lng
                                }}
                                radius={radius.radius * 1609.34}
                                options={{
                                    fillColor: colors.primary.main,
                                    fillOpacity: 0.30,
                                    strokeColor: colors.primary.main,
                                    strokeOpacity: 0.8
                                }}
                                onDragEnd={(e) => {
                                    let lat = e.latLng.lat()
                                    let lng = e.latLng.lng()

                                    getCoordinatesAddress(geocoder, lat, lng, function (addressData: any) {
                                        setServiceArea((prevState: ServiceArea) => {
                                            let copy = {...prevState}

                                            for (let e of copy.radii) {
                                                if (e.id === radius.id) {
                                                    e.coordinate.lat = lat
                                                    e.coordinate.lng = lng
                                                    e.name = addressData.shortAddress
                                                    break
                                                }
                                            }

                                            return copy
                                        })
                                    })

                                    setHasMadeChanges(true)
                                }}
                                draggable={true}
                            />
                        </MDBox>
                    })}

                    {serviceArea.polygons.map((polygon, index) => {
                        return <MDBox key={index}>
                            <OverlayView
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                                position={getCenterOfPolygon(polygon.coordinates)}
                            >
                                <MDTypography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                            color={"white"}
                                            sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>{polygon.name}</MDTypography>
                            </OverlayView>

                            <MapsPolygon
                                key={polygon.id}
                                draggable={true}
                                editable={true}
                                path={polygon.coordinates}
                                options={{
                                    fillColor: colors.primary.main,
                                    fillOpacity: 0.40,
                                    strokeColor: colors.primary.main,
                                    strokeOpacity: 1,
                                    strokeWeight: 3
                                }}
                                onLoad={(p) => {
                                    onPolygonLoad(p, polygon.id)
                                }}
                                onUnmount={() => onPolygonUnmount()}
                                onMouseUp={() => onPolygonEdit(polygon.id)}
                                onDragEnd={() => onPolygonEdit(polygon.id)}
                            />
                        </MDBox>
                    })}
                </GoogleMap>
            </MDBox>

            {serviceArea.radii.length || serviceArea.polygons.length?
                <MDBox mt={2}>
                    <MDCard border={true} borderColor={"dark"} color={"light"}>
                        <MDBox p={2}>
                            {serviceArea.radii.map((radius, index) => {
                                return <MDBox key={index}>
                                    <Grid container alignItems={"flex-end"} gap={2}>
                                        <Grid item flex={1}>
                                            <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>Address</MDTypography>

                                            <MDBox mt={1}>
                                                <MDInput
                                                    backgroundColor={"white"}
                                                    fullWidth={true}
                                                    value={radius.name}
                                                    onChange={(e: any) => {
                                                        setServiceArea((prevState: ServiceArea) => {
                                                            let newEntry = {...prevState}

                                                            for(let a of newEntry.radii){
                                                                if(a.id === radius.id){
                                                                    a.name = e.target.value
                                                                    break
                                                                }
                                                            }

                                                            return newEntry
                                                        })
                                                    }}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item flex={1}>
                                            <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>Radius (mi)</MDTypography>

                                            <MDBox mt={1}>
                                                <MDInput
                                                    backgroundColor={"white"}
                                                    fullWidth={true}
                                                    value={radius.radius}
                                                    type="number"
                                                    inputProps={{
                                                        step: 0.1
                                                    }}
                                                    onChange={(e: any) => {
                                                        setServiceArea((prevState: ServiceArea) => {
                                                            let newEntry = {...prevState}

                                                            for(let a of newEntry.radii){
                                                                if(a.id === radius.id){
                                                                    a.radius = parseFloat(e.target.value)
                                                                    break
                                                                }
                                                            }

                                                            return newEntry
                                                        })

                                                        setHasMadeChanges(true)
                                                    }}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item>
                                            <Grid container alignItems={"center"} gap={1} justifyContent={"center"}>
                                                <Grid item>
                                                    <Tooltip title={"See on map"}>
                                                        <MDIconButton backgroundColor={"white"} sx={{
                                                            padding: 0,
                                                            width: "44px",
                                                            height: "44px",
                                                            minWidth: "unset"
                                                        }} onClick={() => {
                                                            map?.setCenter({
                                                                lat: radius.coordinate.lat,
                                                                lng: radius.coordinate.lng
                                                            })
                                                            map?.setZoom(12)
                                                        }}>
                                                            <RadiusIcon color={"primary"}/>
                                                        </MDIconButton>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip title={"Delete"}>
                                                        <MDIconButton backgroundColor={"white"} sx={{
                                                            padding: 0,
                                                            width: "44px",
                                                            height: "44px",
                                                            minWidth: "unset"
                                                        }} onClick={() => {
                                                            deleteAddress(radius.id)
                                                            setHasMadeChanges(true)
                                                        }}>
                                                            <DeleteIcon color={"error"}/>
                                                        </MDIconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Divider light={true}/>
                                </MDBox>
                            })}

                            {serviceArea.polygons.map((polygon, index) => {
                                return <MDBox key={index}>
                                    <Grid container alignItems={"flex-end"} gap={2}>
                                        <Grid item flex={1}>
                                            <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>Address</MDTypography>

                                            <MDBox mt={1}>
                                                <MDInput
                                                    backgroundColor={"white"}
                                                    fullWidth={true}
                                                    value={polygon.name}
                                                    onChange={(e: any) => {
                                                        setServiceArea((prevState: ServiceArea) => {
                                                            let newEntry = {...prevState}

                                                            for(let a of newEntry.polygons){
                                                                if(a.id === polygon.id){
                                                                    a.name = e.target.value
                                                                    break
                                                                }
                                                            }

                                                            return newEntry
                                                        })
                                                    }}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item>
                                            <Grid container alignItems={"center"} gap={1} justifyContent={"center"}>
                                                <Grid item>
                                                    <Tooltip title={"See on map"}>
                                                        <MDIconButton backgroundColor={"white"} sx={{
                                                            padding: 0,
                                                            width: "44px",
                                                            height: "44px",
                                                            minWidth: "unset"
                                                        }} onClick={() => {
                                                            map?.setCenter(getCenterOfPolygon(polygon.coordinates))
                                                            map?.setZoom(12)
                                                        }}>
                                                            <RadiusIcon color={"primary"}/>
                                                        </MDIconButton>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip title={"Delete"}>
                                                        <MDIconButton backgroundColor={"white"} sx={{
                                                            padding: 0,
                                                            width: "44px",
                                                            height: "44px",
                                                            minWidth: "unset"
                                                        }} onClick={() => {
                                                            deletePolygon(polygon.id)
                                                            setHasMadeChanges(true)
                                                        }}>
                                                            <DeleteIcon color={"error"}/>
                                                        </MDIconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Divider light={true}/>
                                </MDBox>
                            })}
                        </MDBox>
                    </MDCard>
                </MDBox>
                :
                null
            }
        </DialogContent>
    </Dialog>
}

export default ServiceAreaModal