import { ReactNode } from 'react';
import { IAutomation } from 'services/automations/automatios.service.types';

export type Status = 'DRAFT' | 'PROCESSING' | 'TESTING' | 'ACTIVE' | 'INACTIVE';

export interface ModalState  {
    isSetupModalOpen: boolean;
    isDetailModalOpen: boolean;
    isDeleteModalOpen: boolean;
    isDuplicateModalOpen: boolean;
    isUpdateStatusModalOpen: boolean;
    isNewAutomationModalOpen: boolean;
    isUpdateAutomationNameModalOpen: boolean;
};

export interface IStatusUpdateInfo {
  nextStatus: string,
  requiresRetroactiveDate: boolean,
  statusMessageToShowBeforeSave: string | Status
  loading: boolean,
}

export interface IAutomationFilter {
  integrationOrderTypeName?: string;
  status?: string;
}

export enum Step {
  INITIAL = 'INITIAL',
  KLAVIYO = 'KLAVIYO',
  SHOPIFY = 'SHOPIFY',
  LEAD_RAVEAL = 'LEAD RAVEAL',
  ZAPIER = 'ZAPIER_INITIAL_SETUP',
  ZAPIER_MANAGE = 'ZAPIER_MANAGE',
  OPENAPI = 'OPENAPI_INITIAL_SETUP',
  OPENAPI_MANAGE = 'OPENAPI_MANAGE',
  STORM = 'STORM AUTOMATION',
}

export interface IAutomationContextType {
  automations: IAutomation[];
  setAutomations: (automations: IAutomation[]) => void;
  initialData: IAutomation[];
  setInitialData: (automations: IAutomation[]) => void;
  automationFilterData: IAutomationFilter;
  setAutomationFilterData: (value: IAutomationFilter) => void;
  stepToRender: Step;
  setStepToRender: (step: Step) => void;
  selectedAutomation: IAutomation | null;
  setSelectedAutomation: (automation: IAutomation | null) => void;
  automationTypeList: string[];
  automationStatusList: string[];
  modalState: ModalState;
  toggleModal: (modal: keyof ModalState, value: boolean) => void;
  handleUpdateListAfterSaveName: (id: number, name: string) => void;
  handleUpdateListAfterUpdteStatus: (id: number, status: Status) => void;
  handleupdateListAfterDelete: (id: number) => void;
  handleUploadListAfterDuplicated: (newItem: IAutomation) => void;
  automationDetail: Partial<IAutomation> | null;
  automationToSetup: string;
  setAutomationToSetup: (automation: string) => void;
  setAutomationDetail: (automation: Partial<IAutomation>) => void;
  statusUpdateInfo: { nextStatus: string; statusMessageToShowBeforeSave: string, loading: boolean, requiresRetroactiveDate: boolean };
  setStatusUpdateInfo: React.Dispatch<React.SetStateAction<{ nextStatus: string; statusMessageToShowBeforeSave: string; loading: boolean, requiresRetroactiveDate: boolean }>>;
}

export interface AutomationProviderProps {
  children: ReactNode;
}
