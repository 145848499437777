import React from 'react';
import DeleteIcon from 'assets/icons/delete';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Box } from '@mui/system';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IAutomation } from 'services/automations/automatios.service.types';
import { DataProviders } from '../enums/DataProviders.enum';
import { useAutomationContext } from 'pages/automations/context';

interface IActions {
    row: IAutomation
}

const Actions: React.FC<IActions> = ({ row }) => {
    const navigate = useNavigate();
    const { toggleModal, setSelectedAutomation } = useAutomationContext();

    const handleDuplicateAutomatation = async(rowData: IAutomation) => {
        setSelectedAutomation(rowData);
        toggleModal('isDuplicateModalOpen', true);
    }

    const handleOpenDetail = async(rowData: IAutomation) => {
        setSelectedAutomation(rowData);
        toggleModal('isDetailModalOpen', true);
    }

    const handleDeleteAutomation = (rowData: IAutomation) => {
        if(!rowData.canDelete) return
        setSelectedAutomation(rowData);
        toggleModal('isDeleteModalOpen', true);
    }

    const handleEdit = (rowData: IAutomation) => {
        const id = rowData.id;

        switch (rowData.integrationOrderType) {
            case DataProviders.Movers:
                navigate(`/smart-automation/${id}?source=${4}`);
                break;
            case DataProviders.Klaviyo:
                navigate(`/smart-automation/${id}?source=${2}`);
                break;
            case DataProviders.Shopify:
                navigate(`/smart-automation/${id}?source=${1}`);
                break;
            case DataProviders.LeadReveal:
                navigate(`/smart-automation/${id}?source=${3}`);
                break;
            case DataProviders.HailTrace:
                navigate(`/storm-automation/order/${id}`);
                break;
            case DataProviders.Normal:
                navigate(`/template/${id}`);
                break;
            default:
                console.warn('Unknown integration type');
        }
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title='Detail'>
                <Box>
                    <VisibilityIcon 
                        data-testid="automation-detail-btn"
                        onClick={() => handleOpenDetail(row)}
                        sx={{ 
                        fontSize: '16px !important', 
                        width: '26px', 
                        height: '26px', 
                        color: '##344767', 
                        background: '#f3f5fb', 
                        borderRadius: '100%' ,
                        padding: '4px'
                        }} 
                    />
                </Box>
            </Tooltip>
            <Tooltip title='Edit'>
                <Box>
                    <SettingsIcon 
                        data-testid="automation-edit-btn"
                        onClick={() => handleEdit(row)}
                        sx={{ 
                        fontSize: '16px !important', 
                        width: '26px', 
                        height: '26px', 
                        color: '#344767', 
                        background: '#f3f5fb', 
                        borderRadius: '100%' ,
                        padding: '4px'
                        }} 
                    />
                </Box>
            </Tooltip>
            <Tooltip title='Duplicate'>
                <Box>
                    <ContentCopyIcon 
                        data-testid="automation-duplicate-btn"
                        onClick={() => handleDuplicateAutomatation(row)}
                        sx={{ 
                        fontSize: '16px !important', 
                        width: '26px', 
                        height: '26px', 
                        color: '#344767', 
                        background: '#f3f5fb', 
                        borderRadius: '100%' ,
                        padding: '4px'
                        }} 
                    />
                </Box>
            </Tooltip>
            <Tooltip title={row.canDelete ? 'Delete' : 'Unable to delete this automation'} placement='bottom'>
                <Box>
                    <DeleteIcon 
                        data-testid="automation-delete-btn"
                        onClick={() => handleDeleteAutomation(row)}
                        sx={{ 
                        fontSize: '16px !important', 
                        width: '26px', 
                        height: '26px', 
                        color: '#344767', 
                        background: '#f3f5fb', 
                        borderRadius: '100%' ,
                        padding: '4px',
                        opacity: row.canDelete ? '1' : .4,
                        cursor: row.canDelete ? 'pointer' : 'not-allowed'
                        }} 
                    />
                </Box>
            </Tooltip>
        </Box>
    );
}

export default Actions;