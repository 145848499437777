import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';

import { IAutomation } from 'services/automations/automatios.service.types';
import { updateListAfterDuplicated, updateListAfterSaveName, updateListAfterUpdateStatus, updateListAfterDelete } from './helpers';
import { Step, IAutomationFilter, IAutomationContextType, AutomationProviderProps, ModalState, Status, IStatusUpdateInfo } from './types';

const AutomationContext = createContext<IAutomationContextType | undefined>(undefined);

export const AutomationProvider: React.FC<AutomationProviderProps> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>({
    isSetupModalOpen: false,
    isDetailModalOpen: false,
    isDeleteModalOpen: false,
    isDuplicateModalOpen: false,
    isUpdateStatusModalOpen: false,
    isNewAutomationModalOpen: false,
    isUpdateAutomationNameModalOpen: false,
  });

  const [automations, setAutomations] = useState<IAutomation[]>([]);
  const [initialData, setInitialData] = useState<IAutomation[]>([]);
  const [automationToSetup, setAutomationToSetup] = useState('');
  const [automationFilterData, setAutomationFilterData] = useState<IAutomationFilter>({
    integrationOrderTypeName: '',
    status: '',
  });
  const [stepToRender, setStepToRender] = useState<Step>(Step.INITIAL);
  const [selectedAutomation, setSelectedAutomation] = useState<IAutomation | null>(null);
  const [automationDetail, setAutomationDetail] = useState<Partial<IAutomation> | null>(null);
  const [statusUpdateInfo, setStatusUpdateInfo] = useState<IStatusUpdateInfo>({
    nextStatus: '',
    statusMessageToShowBeforeSave: '',
    requiresRetroactiveDate: false,
    loading: false
  })
  const toggleModal = (modal: keyof typeof modalState, value: boolean) => {
    setModalState(prev => ({ ...prev, [modal]: value }));
  };

  const automationTypeList = useMemo(() => 
    Array.from(
      new Set(
        initialData
          .map(item => item.integrationOrderTypeName)
          .filter((type): type is string => !!type)
      )
    ),
    [initialData]
  );
  
  const automationStatusList = useMemo(() => 
    Array.from(
      new Set(
        initialData
          .map(item => item.status)
          .filter((status): status is "DRAFT" | "PROCESSING" | "TESTING" | "ACTIVE" | "INACTIVE" => 
            !!status && ["DRAFT", "PROCESSING", "TESTING", "ACTIVE", "INACTIVE"].includes(status.toUpperCase())
          )  
      )
    ),
    [initialData]
  );

  const handleUpdateListAfterSaveName = (id: number, newName: string) => {
    setAutomations(updateListAfterSaveName(automations, id, newName));
  };
  
  const handleUpdateListAfterUpdteStatus = (id: number, newStatus: Status) => {
    setAutomations(updateListAfterUpdateStatus(automations, id, newStatus));
  };

  const handleupdateListAfterDelete = (id: number) => {
    setAutomations(updateListAfterDelete(automations, id));
  };
  
  const handleUploadListAfterDuplicated = (newItem: IAutomation) => {
    setAutomations(updateListAfterDuplicated(automations, newItem));
  }

  useEffect(() => {
    const filteredData = initialData.filter(item => {
      const matchesType = !automationFilterData.integrationOrderTypeName || 
        item.integrationOrderTypeName.includes(automationFilterData.integrationOrderTypeName);
      const matchesStatus = !automationFilterData.status || 
        item.status?.includes(automationFilterData.status);
      return matchesType && matchesStatus;
    });

    setAutomations(filteredData);
  }, [automationFilterData, initialData]);

  const contextValue = {
    automations,
    setAutomations,
    initialData,
    setInitialData,
    automationFilterData,
    setAutomationFilterData,
    stepToRender,
    setStepToRender,
    selectedAutomation,
    setSelectedAutomation,
    automationTypeList,
    automationStatusList,
    modalState,
    toggleModal,
    handleUpdateListAfterSaveName,
    handleUpdateListAfterUpdteStatus,
    handleUploadListAfterDuplicated,
    handleupdateListAfterDelete,
    automationDetail,
    setAutomationDetail,
    statusUpdateInfo,
    setStatusUpdateInfo,
    automationToSetup,
    setAutomationToSetup
  };

  return (
    <AutomationContext.Provider value={contextValue}>
      {children}
    </AutomationContext.Provider>
  );
};

export const useAutomationContext = (): IAutomationContextType => {
  const context = useContext(AutomationContext);
  if (!context) {
    throw new Error('useAutomationContext must be used within an AutomationProvider');
  }
  return context;
};