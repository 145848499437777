import React, { useState } from 'react';

import { Box } from '@mui/system';
import { Step } from 'pages/automations/context/types';
import { useGlobal } from 'context/global-context';
import { IPlatform } from 'services/automations/automatios.service.types';
import { ITemplate } from 'models/template';
import { useAutomationContext } from 'pages/automations/context';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CircularProgress, Dialog, DialogContent, Grid, Typography } from '@mui/material';

import copy from 'copy-to-clipboard';
import MDBox from 'material-ui/components/MDBox';
import MDCard from 'material-ui/components/MDCard';
import MDInput from 'material-ui/components/MDInput';
import MDButton from 'material-ui/components/MDButton';
import CloseIcon from 'assets/icons/close';
import APIKeyIcon from 'assets/images/gold-key.png';
import MDTypography from 'material-ui/components/MDTypography';
import ApiKeyService from 'services/api-key-service';
import IntegrationOrderModal from 'components/integration-order-modal';
import OpenAPIColorfullLogo from 'assets/images/Logo - Open API.png';

interface IOpenAPISetup {
  platform: IPlatform
}

const OpenAPISetup: React.FC<IOpenAPISetup> = ({ platform }) => {
  const queryClient = useQueryClient();
  const { setTemplateFlow } = useGlobal()
  const apiKeyType = { value: 2, description: 'Open API'}

  const {createApiKey} = ApiKeyService()
  const { toggleModal, setAutomationToSetup, setStepToRender } = useAutomationContext();

  const [isSaving, setIsSaving] = useState(false);
  const [apiKeyName, setApiKeyName] = useState('');
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [canCreate, setCanCreate] = useState(false);
  const [generatedAPIKey, setGeneratedAPIKey] = useState('');
  const [showNewAPIKeyInfoModal, setShowNewAPIKeyInfoModal] = useState(false);

  const [template, setTemplate] = useState<ITemplate>();
  const [showCreateNewIntegrationOrderModal, setshowCreateNewIntegrationOrderModal] = useState<boolean>(false);

  const postApiKeyMutation = useMutation({
      mutationFn: () => {
          return createApiKey(apiKeyName, apiKeyType.value)
      },
      onMutate: () => {
        setIsSaving(true)
      },
      onSuccess: (data: any, variables, context) => {
        setApiKeyName('');
        setIsSaving(false);
        setGeneratedAPIKey(data.key);
        setShowNewAPIKeyInfoModal(true);
        setCanCreate(true);
        queryClient.invalidateQueries({queryKey: ["openAPIKeys"]})
      }
  })

  const copyToClipboard = () => {
    copy(generatedAPIKey)
    setIsCopied(true)
  }

  const handleCreateNewIntegrationOrder = () => {
    setTemplateFlow({ 
    showModal: true, 
    shouldCheckNotSupported: true,
    originalRoute: '/automations',
    customCreateCallback:() => {
        setTemplate(undefined)
        setshowCreateNewIntegrationOrderModal(true)
    },
    onTemplateChange: (newTemplate) => {
        setTemplate(newTemplate)
        setshowCreateNewIntegrationOrderModal(true)
    }})
}

  return (
    <DialogContent sx={{ padding: '2rem 6rem', minHeight: '830px', position: 'relative'}}>
      <Typography variant="h3" sx={{ margin: '1rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 26}}>
        Open API
      </Typography>
      <CloseIcon data-testid="setup-modal-close-btn" sx={{ top: '3rem', opacity: .6, position: 'absolute', right: '6rem'}} onClick={() => { setStepToRender(Step.INITIAL); toggleModal('isSetupModalOpen', false); setAutomationToSetup('') }} />

      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '3rem 0 2rem 0'}}>
        <img style={{ marginLeft: '-30px'}} width={'180px'} src={OpenAPIColorfullLogo} alt="Zapier" />
      </Box>

      <Box sx={{ display: 'flex', height: '400px', padding: '1rem 0 0 0', width: '100%'}} mt={2}>
        <Box sx={{ padding: '0 0 2rem 0', width: '100%' }}>
          <Typography variant='body2'>
            The LettrLabs Open API allows your development team to integrate and automate the LettrLabs ordering process directly into your own custom applications.
          </Typography>
          <Typography variant='body2' mt={2}>
            For more information, <Typography variant='body2' onClick={() => window.open('https://app.lettrlabs.com/api/swagger/ui#/')} component={'a'} sx={{ cursor: 'pointer', color: '#F05A2A' }}>click here</Typography> to see our API reference.
          </Typography>
          <Typography variant='body2' sx={{ margin: '2rem 0 0 0' }}>
            <b>Step 1:</b> Create a new LettrLabs Open API Key to authenticate with our API.
          </Typography>
          <Typography variant='body2'>
            <b>Step 2:</b> You're all set! Use your API Key to start integrating LettrLabs into your applications. 🎉
          </Typography>

          <Box sx={{ margin: '2rem 0' }}>
            <Typography variant='body2'>
              For security purposes, your API key can only be seen once. Copy and save this key somewhere safe, and do not share it with anyone you don't
              want to have access to your LettrLabs account.
            </Typography>
          </Box>

          <Box sx={{ margin: '2rem 0' }}>
            <Typography variant='body2'>
              If you lose this key, you can always revoke access and generate a new one under <b>Manage Keys</b>.
            </Typography>
          </Box>
          
          {
            !canCreate && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <MDInput
                    fullWidth={true}
                    value={apiKeyName}
                    onChange={(e: any) => {
                      setApiKeyName(e.target.value);
                    }}
                    label={"Description*"}
                    helperText="Please name your API Key, this will help you identify it later"
                />
                <MDButton
                  sx={{ height: '40px', width: '180px'}}
                  color={"primary"}
                  disabled={!apiKeyName || isSaving}
                  onClick={() => {
                    postApiKeyMutation.mutate();
                  }}
                >
                  { isSaving ? <CircularProgress size={15} color='inherit' /> : 'Generate Key'}
                </MDButton>
              </Box>
            )
          }

          {
            canCreate && (
              <MDButton onClick={handleCreateNewIntegrationOrder} fullWidth>
                Create my first Automation
              </MDButton>
            )
          }
        </Box>
      </Box>

      <Dialog open={showNewAPIKeyInfoModal} onClose={() => setShowNewAPIKeyInfoModal(false)} maxWidth={'md'} fullWidth >
        <DialogContent>
            <Box sx={{ display: 'flex', position: 'relative' }}>
                <Typography variant="h4" pt={2} pb={4} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)'}}>
                  Your New API Key
                </Typography>
                <CloseIcon data-testid="update-status-modal-close-btn" sx={{ opacity: .6, position: 'absolute',top:'15px', right: 0}} onClick={() => { setShowNewAPIKeyInfoModal(false); }} />
            </Box>
            <Box sx={{display: 'flex', padding: '2rem 0'}}>
              <Box sx={{ width: '60%' }}>
                <Typography variant='body2' fontWeight={500}>All set!</Typography>
                <Typography variant='body2'>Your new API Key has been successfully created and is< br/> ready to use.</Typography>
                <Box>
                  <Typography variant='body2' marginTop={4} marginBottom={2} fontWeight={500}>It’s important to remember:</Typography>
                  <Typography variant='body2' sx={{ display: 'flex', gap: 1, fontSize: '14px', marginTop: '1rem'}}>
                    <Box mt={'7px'} minWidth={7} height={7} bgcolor={'red'} borderRadius={'100%'} />Please copy and save your API key below. Do not share this key with anyone.
                  </Typography>
                  <Typography variant='body2' sx={{ display: 'flex', gap: 1, fontSize: '14px', marginTop: '.5rem'}}>
                    <Box mt={'7px'} minWidth={7} height={7} bgcolor={'red'} borderRadius={'100%'} />For security reasons, your API key can only be viewed once. Once you exit this screen, you will not be able to retrieve it. Be sure to store it in a safe place.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40%' }}>
                <img src={APIKeyIcon} alt='API Key Icon' width={'150px'} height={'150px'} />
              </Box>
            </Box>
            <MDBox>
              <MDCard border={true} borderColor={"light"} borderRadiusSize={"xl"}>
                  <Grid container p={1} alignItems={"center"} justifyContent={"space-between"} gap={1}>
                      <Grid item>
                          <MDTypography variant={"h5"} color={"secondary"} fontWeight={"normal"}>{generatedAPIKey}</MDTypography>
                      </Grid>
                      <Grid item>
                          <MDButton color={"primary"} onClick={copyToClipboard}>{isCopied? "Copied!" : "Copy to Clipboard"}</MDButton>
                      </Grid>
                  </Grid>
              </MDCard>
          </MDBox>
        </DialogContent>
      </Dialog>

      <IntegrationOrderModal
          show={showCreateNewIntegrationOrderModal}
          setShow={setshowCreateNewIntegrationOrderModal}
          template={template}
      />

    </DialogContent>
  );
}

export default OpenAPISetup;