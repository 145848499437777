import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Step } from 'pages/automations/context/types';
import { DialogContent } from '@mui/material';
import { isLettrLabsUser } from 'helpers/profileHelper';
import { useAutomationContext } from 'pages/automations/context';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import CloseIcon from 'assets/icons/close';
import FinishStep from './components/FinishStep';
import PaymentStep from './components/PaymentStep';
import LoadingState from './components/LoadingState';
import ProfileService from 'services/profile';
import CheckoutWrapper from 'newStandard/src/components/Checkout/CheckoutWrapper';
import SubscriptionStep from './components/SubscriptionStep';
import IntroductionStep from './components/GetPixelStep';
import ManageLeadReveal from './components/ManageLeadReveal';
import useDataSourcesService from 'services/data-sources';

const LeadRevealSetup: React.FC = () => {
  const queryClient = useQueryClient();
  const { setStepToRender } = useAutomationContext();

  const { getProfile } = ProfileService();
  const { getLeadRevealSource } = useDataSourcesService();

  const [isLoadingRefetch, setIsLoadingRefetch] = useState(false);

  const { data: leadRevealData , isLoading: isLoadingRevelData} = useQuery({
    queryKey: ['getLeadRevealSource'],
    queryFn: getLeadRevealSource,
    notifyOnChangeProps: ['data', 'error'], 
  });

  const { data: profile , isLoading: isLoadingProfile} = useQuery({
    queryKey: ['profile'],
    queryFn: getProfile,
    notifyOnChangeProps: ['data', 'error'], 
  });

  const handleFetch = async () => {
    setIsLoadingRefetch(true);
    try {
      await Promise.all([
        queryClient.fetchQuery({ queryKey: ['getLeadRevealSource'], queryFn: getLeadRevealSource }),
        queryClient.fetchQuery({ queryKey: ['profile'], queryFn: getProfile }),
      ]);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setIsLoadingRefetch(false);
    }
  };

  const handleRefreshLeadRevealData = async () => {
    setIsLoadingRefetch(true);
    try {
      await Promise.all([
        queryClient.fetchQuery({ queryKey: ['getLeadRevealSource'], queryFn: getLeadRevealSource }),
      ]);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setIsLoadingRefetch(false);
    }
  };

  const getStepToRender = () => {
    if (!profile) return null;

    const hasSubscription = profile.hasSubscription || isLettrLabsUser(profile);
    const hasDefaultCreditCard = profile.defaultPaymentMethodId || isLettrLabsUser(profile);

    if (!hasDefaultCreditCard) return <PaymentStep handleRefresh={handleFetch} />;
    if (!hasSubscription) return <SubscriptionStep />;

    if (leadRevealData?.payload?.payload === '') {
      return <IntroductionStep profile={profile} handleRefresh={handleFetch}/>;
    }

    if (leadRevealData?.payload?.authenticationStatus === 'Pending') {
      const formattedData =
        typeof leadRevealData.payload.payload === 'string'
          ? JSON.parse(leadRevealData.payload.payload)
          : null;
      return <FinishStep data={formattedData} handleRefresh={handleRefreshLeadRevealData}/>;
    }

    if (leadRevealData?.payload?.authenticationStatus === 'Authenticated') {
      return <ManageLeadReveal handleRefresh={handleRefreshLeadRevealData} leadRevealData={leadRevealData.payload}/>;
    }
  };

  return (
    <DialogContent sx={{ padding: '0 6rem', position: 'relative' }}>
      <CloseIcon
        data-testid="setup-modal-close-btn"
        sx={{ top: '35px', right: '6rem', opacity: 0.6, position: 'absolute', zIndex: 999999 }}
        onClick={() => {
          setStepToRender(Step.INITIAL)
        }}
      />
      <Box>
        <CheckoutWrapper isLoading={false}>
          {(isLoadingRefetch || isLoadingRevelData || isLoadingProfile)? <LoadingState /> : getStepToRender()}
        </CheckoutWrapper>
      </Box>
    </DialogContent>
  );
};

export default LeadRevealSetup;