import React, { useState } from 'react';

import { Box } from '@mui/system';
import { Chip, CircularProgress, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import MDButton from 'material-ui/components/MDButton';
import ProfileService from 'services/profile';
import CreditCardsIcon from 'assets/images/credit-card.png';

interface IPaymentStep {
    handleRefresh: () => void
}

const PaymentStep: React.FC<IPaymentStep> = ({ handleRefresh }) => {

    const stripe = useStripe();
    const elements = useElements();
    const queryClient = useQueryClient();
    const { addProfilePaymentMethod } = ProfileService();

    const [isSubmitingPayment, setIsSubmitingPayment] = useState<boolean>(false);
    const [canSubmitPayment, setCanSubmitPayment] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { mutate: addPaymentMethod } = useMutation({
        mutationFn: addProfilePaymentMethod,
        onSuccess: (result) => {
            if (result.hasErrors) setErrorMessage(result.errors[0]);
            else {
                queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource', 'profile'] });
                setErrorMessage(null);
                handleRefresh();
            }
            setIsSubmitingPayment(false);
            setTimeout(() => {
                setErrorMessage(null);
            }, 8000)
        },
    });

    const handlePaymentMethodSubmit = async () => {
        if (!stripe || !elements) {
            setErrorMessage('System error. Please try again later.');
            return;
        }
    
        setIsSubmitingPayment(true);
        const card = elements.getElement(CardElement);
    
        if (!card) {
            setErrorMessage('Card element not found.');
            setIsSubmitingPayment(false);
            return;
        }
    
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: card
        });
    
        if (error) {
            setErrorMessage(error.message ?? 'An error occurred');
            setIsSubmitingPayment(false);
        } else {
            setIsSubmitingPayment(true);
            addPaymentMethod(paymentMethod.id);
        }
    };
    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '4rem' }}>
            <Typography variant="h3" sx={{ margin: '3rem 0 .5rem 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 26 }}>
                Payment Method
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%'}}>
                    <Box sx={{ padding: '2rem 0' }}>
                        <Typography variant="body2" fontWeight={'bold'}>Default Method Set</Typography>
                        <Typography variant="body2" sx={{ margin: '.5rem 0', opacity: .8 }}>
                            To proceed, please set a default payment method for future automations.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', border: '1px solid rgba(0,0,0,.08)', borderRadius: '5px', padding: '2rem .5rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%', padding: '0 1rem' }}>
                            <CardElement onChange={(e) => setCanSubmitPayment(e.complete)} />
                                {
                                    errorMessage && (
                                        <Chip 
                                            color='error' 
                                            label={<Box sx={{ padding: '0 1rem', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>{errorMessage}</Box>} 
                                            sx={{ 
                                                height: 'auto', 
                                                borderRadius: '8px', 
                                                background: '#f8d7da',
                                                color: '#721c24',
                                                wordWrap: 'break-word', 
                                                overflowWrap: 'break-word', 
                                                whiteSpace: 'normal',  
                                                display: 'block' 
                                            }}/>
                                    )
                                }
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <MDButton color="light" sx={{ borderRadius: '5px !important', marginTop: '2rem' }}>
                            Cancel
                        </MDButton>
                        <MDButton color="primary" sx={{ borderRadius: '5px !important', marginTop: '2rem' }} disabled={!canSubmitPayment || isSubmitingPayment} onClick={handlePaymentMethodSubmit}>
                            { isSubmitingPayment ? <CircularProgress size={15} color='inherit'  /> : 'Save'}
                        </MDButton>
                    </Box>
                </Box>
                <Box>
                    <img src={CreditCardsIcon} alt='Credit Cards Icon' width={'300px'} style={{ marginRight: '-30px', marginTop: '20px'}}/>
                </Box>
            </Box>
        </Box>
    );
};

export default PaymentStep;
