import { Formik, Form, Field, FieldProps } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from '@mui/material';

import AddressFormikFields from 'newStandard/src/components/AddressFormikFields';
import { ReturnAddressValidation } from 'models/modelValidations/returnAddress';
import ProfileReturnAddress from 'services/profile-return-address';
import MDButton from 'material-ui/components/MDButton';
import { ReturnAddress } from 'models/returnAddress';
import { IObjectResult } from 'services/endpoint';

interface ReturnAddressModalProps {
  isFirst: boolean;
  returnAddress?: ReturnAddress;
  onClose: (selectedAddress?: ReturnAddress) => void;
}

export default function ReturnAddressModal({ returnAddress, isFirst, onClose }: ReturnAddressModalProps) {
  const queryClient = useQueryClient();
  const { postReturnAddress, deleteReturnAddress } = ProfileReturnAddress();

  const onMutationSuccess = (data?: IObjectResult<ReturnAddress>) => {
    queryClient.invalidateQueries({ queryKey: ['returnAddresses'] });
    onClose(data?.payload);
  };

  const { mutate: upsertReturnAddress, isPending: isUpdating } = useMutation({
    mutationFn: (returnAddress: ReturnAddress) => postReturnAddress(returnAddress),
    onSuccess: onMutationSuccess,
  });

  const { mutate: removeReturnAddress, isPending: isDeleting } = useMutation({
    mutationFn: () => deleteReturnAddress(returnAddress?.id ?? -1),
    onSuccess: onMutationSuccess,
  });

  const addressFields = [
    { name: 'name', label: 'Name your return address', md: 12 },
    { name: 'firstName', label: 'First Name', md: 6 },
    { name: 'lastName', label: 'Last Name', md: 6 },
    { name: 'toOrganization', label: 'Organization (Optional)', md: 12 },
    { name: 'address1', label: 'Address 1', md: 12 },
    { name: 'address2', label: 'Address 2', md: 12 },
    { name: 'city', label: 'City', md: 5 },
  ];

  if (!returnAddress) return null;
  return (
    <Dialog open={!!returnAddress} onClose={() => onClose()}>
      <DialogContent>
        <DialogTitle>{returnAddress?.id ? 'Edit' : 'Add'} return address</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={returnAddress}
          validationSchema={ReturnAddressValidation}
          onSubmit={(values) => upsertReturnAddress(values)}
        >
          <Form>
            <Stack gap={2}>
              <AddressFormikFields fields={addressFields} zipName="zip" />
              <Field name={'isDefault'}>
                {({ field }: FieldProps) => (
                  <Box width={'fit-content'}>
                    <FormControlLabel
                      disabled={isFirst}
                      sx={{ opacity: isFirst ? 0.6 : 1 }}
                      control={<Checkbox {...field} checked={isFirst ? true : field.value} />}
                      label="Mark as default address"
                    />
                  </Box>
                )}
              </Field>

              <Stack direction="row" marginTop={3} justifyContent="space-between">
                {!!returnAddress?.id && (
                  <MDButton
                    color="error"
                    variant="transparent"
                    onClick={() => removeReturnAddress()}
                    disabled={isUpdating || isDeleting}
                    sx={{ width: 100 }}
                  >
                    {isDeleting ? <CircularProgress color="inherit" size={20} /> : 'Delete'}
                  </MDButton>
                )}
                <Stack direction="row" spacing={1} flex={1} justifyContent="flex-end">
                  <MDButton circular color="light" onClick={() => onClose()} disabled={isUpdating || isDeleting}>
                    Cancel
                  </MDButton>
                  <MDButton type="submit" disabled={isUpdating || isDeleting} sx={{ width: 100 }}>
                    {isUpdating ? <CircularProgress color="inherit" size={20} /> : 'Save'}
                  </MDButton>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
