import * as React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import LockIcon from '@mui/icons-material/Lock';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import useAutomationsService from 'services/automations';

import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import { isAllowedPermission } from 'helpers/getUserInfos';
import { useAutomationContext } from 'pages/automations/context';
import { useSelfManagementPlanModal } from 'context/selfmanageplan-context';

export default function AutomationDetailTable() {

  const { showModal } = useSelfManagementPlanModal();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { getRecipients } = useAutomationsService();
  const { selectedAutomation } = useAutomationContext();

  const {
    data,
    isLoading
} = useQuery({ 
    queryKey: ['getRecipients',selectedAutomation?.integrationOrderId, selectedAutomation?.integrationOrderType], 
    queryFn: () => getRecipients(selectedAutomation?.integrationOrderId ?? 0, selectedAutomation?.integrationOrderType ?? 0), 
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: Boolean(selectedAutomation?.integrationOrderId && selectedAutomation?.integrationOrderType && selectedAutomation?.integrationOrderTypeName !== 'Hail Trace')
});

  const columns = data?.payload?.columns || [];
  const recipients = data?.payload?.recipients || [];

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updatedRows = recipients?.map((row: any) => {
    const lowercasedRow: any = {};
  
    for (let key in row) {
      if (row.hasOwnProperty(key)) {
        const lowercaseKey = key.toLowerCase();
        lowercasedRow[lowercaseKey] = row[key];
      }
    }
  
    return lowercasedRow;
  });
  const visibleRows = updatedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - recipients.length) : 0;

  const getFormatedColumnName = (column: string): string => {
    switch (column.toLowerCase()) {
      case 'firstname':
          return 'First Name'
      case 'address1':
          return 'Address'
      case 'address2':
          return 'Address 2'
      case 'city':
          return 'City'
      case 'state':
          return 'State'
      case 'zipcode':
          return 'Zip Code'
      case 'lastname':
          return 'Last Name'
      case 'toorganization':
          return 'To Organization'
      case 'qrscancount':
          return 'QR Scans'
      case 'lastqrscandate':
          return 'Last QR Scan'
      case 'productionstatus':
          return 'Production Status'
      case 'deliverable':
          return 'Deliverable'
      case 'coupon':
          return 'Coupon'
      case 'custom1':
          return 'Custom 1'
      case 'custom2':
          return 'Custom 2'
      case 'custom3':
          return 'Custom 3'
      case 'custom4':
          return 'Custom 4'
      case 'custom5':
          return 'Custom 5'
      case 'custom6':
          return 'Custom 6'
      default:
        return column
    }
  }

  return (
    <Box sx={{ width: '100%', padding: '1rem', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px' }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} size="small">
          <TableHead>
            <TableRow>
              {isLoading
                ? Array(8).fill(null).map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton height={35} />
                    </TableCell>
                  ))
                : columns.map((column: string, index: number) => (
                    <TableCell key={index} align="left" sx={{ fontSize: '.8rem' }}>
                      { getFormatedColumnName(column)}
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array(10).fill(null).map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Array(columns.length || 8).fill(null).map((_, colIndex) => (
                      <TableCell key={colIndex}>
                        <Skeleton height={35} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : visibleRows.length > 0
              ? visibleRows.map((row: any, rowIndex: number) => (
                  <TableRow key={rowIndex} hover>
                    {columns.map((column: string, colIndex: number) => (
                      <TableCell key={colIndex} align="left" sx={{ fontSize: '.8rem' }}>
                        {['qrscancount', 'lastqrscandate'].includes(column.toLowerCase()) && isAllowedPermission('TrackableQRCodes') === false ? (
                          <LockIcon sx={{ fontSize: '1rem', color: '#F05A2A' }} onClick={() => showModal('upgrade')} />
                        ) : (
                          row[column] || '-'
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center" sx={{ fontSize: '.9rem', color: 'gray' }}>
                    No Records
                  </TableCell>
                </TableRow>
              )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={recipients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          margin: '2rem 0 0 0',
          '.MuiTablePagination-toolbar': { fontSize: '0.875rem' },
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': { fontSize: '0.75rem' },
          '.MuiTablePagination-select': { fontSize: '0.75rem' },
        }}
      />
    </Box>
  );
}
