import * as React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import moment from "moment";
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';

import { visuallyHidden } from '@mui/utils';
import { Skeleton, Tooltip } from '@mui/material';
import DeleteIcon from 'assets/icons/delete';

interface Data {
  id: number;
  description: string;
  createdDate: string;
}

interface OpenAPITableProps {
  data: Data[];
  handleDeleteAPIKey: (apiKeyId: number) => void;
  isLoading: boolean
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'description',
    numeric: true,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'createdDate',
    numeric: true,
    disablePadding: false,
    label: 'Created Date',
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontSize: '.7rem'}}
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align='center' sx={{ fontSize: '.7rem'}}>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

const OpenAPITable: React.FC<OpenAPITableProps> = ({ data, isLoading, handleDeleteAPIKey }) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    

  const visibleRows = React.useMemo(
    () =>
      [...data]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [data, order, orderBy, page, rowsPerPage],
  );

  return (
    <Box sx={{ width: '100%', padding: '1rem', border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750, fontSize: '.5rem !important', boxShadow: 'none !important' }}
            size='small'
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              { !isLoading && visibleRows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ fontSize: '.8rem'}}
                    >
                      {row.id}
                    </TableCell>
                    <TableCell sx={{ fontSize: '.8rem'}} align="left">{row.description}</TableCell>
                    <TableCell sx={{ fontSize: '.8rem'}} align="left">{ moment(row.createdDate).format('MM/DD/YYYY')}</TableCell>
                    <TableCell sx={{ fontSize: '.8rem'}} align="center" >
                      <Box sx={{ position: 'relative'}}>
                        <Tooltip title={'Remove'} placement='bottom'>
                          <Box>
                              <DeleteIcon
                                  data-testid="automation-delete-btn"
                                  onClick={() => handleDeleteAPIKey(row.id)}
                                  sx={{ 
                                  fontSize: '16px !important', 
                                  width: '26px', 
                                  height: '26px', 
                                  color: '#344767', 
                                  background: '#f3f5fb', 
                                  borderRadius: '100%' ,
                                  padding: '4px',
                                  }} 
                              />
                          </Box>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}

              {
                isLoading && (
                  <React.Fragment>
                    {
                      [1,2,3,4].map((r) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ fontSize: '.8rem'}}
                          >
                            <Skeleton width={60} height={40} />
                          </TableCell>
                          <TableCell sx={{ fontSize: '.8rem'}} align="left">
                            <Skeleton width={180} height={40} />
                          </TableCell>
                          <TableCell sx={{ fontSize: '.8rem'}} align="left">
                            <Skeleton width={180} height={40} />
                          </TableCell>
                          <TableCell sx={{ fontSize: '.8rem'}} align="center">
                            <Skeleton width={100} height={40} />
                          </TableCell>
                        </TableRow>
                        ))
                      }
                  </React.Fragment>
                )
              }
              
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            margin: '2rem 0 0 0',
            '.MuiTablePagination-toolbar': {
              fontSize: '0.875rem',
            },
            '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              fontSize: '0.75rem',
            },
            '.MuiTablePagination-select': {
              fontSize: '0.75rem',
            },
          }}
        />
    </Box>
  );
}

export default OpenAPITable;