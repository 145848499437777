import {useEffect, useState} from "react";
import {Box, Divider, Grid, Icon, IconButton, MenuItem, TextField, Tooltip, Typography} from "@mui/material";
import {GridCellModesModel, GridColDef, useGridApiRef} from "@mui/x-data-grid-pro";
import {useNavigate} from "react-router-dom";
import {GridRowId} from '@mui/x-data-grid';
import {formatNumber} from "helpers/formatters";
import MDBox from "material-ui/components/MDBox";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import StyledDataGrid from "components/styled-data-grid";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import MDCard from "material-ui/components/MDCard";
import HomeAlt2Icon from "assets/icons/home-alt-2";
import HomeEditIcon from "assets/icons/home-edit";
import HomeSearchIcon from "assets/icons/home-search";
import EditPencilIcon from "assets/icons/edit-pencil";
import DeleteIcon from "assets/icons/delete";
import InfoModal from "components/info-modal";
import {Circle, GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon} from "@react-google-maps/api";
import colors from "material-ui/theme/base/colors";
import {getCenterOfPolygon} from "helpers/google-map-helper";
import {Coordinate} from "models/coordinate";
import MDTypography from "material-ui/components/MDTypography";
import StormAutomationService from "services/storm-automation";
import {ServiceArea, ServiceAreaStatus} from "models/hail-trace/ServiceArea";
import HailSizeIcon from "assets/icons/hail-size";
import WindSpeedIcon from "assets/icons/wind-speed";
import CardsToSendIcon from "assets/icons/cards-to-send";
import PeriodRangeSendIcon from "assets/icons/period-range";
import moment from "moment";
import MDIconButton from "material-ui/components/MDIconButton";
import borders from "material-ui/theme/base/borders";
import MDSelect from "material-ui/components/MDSelect";
import DuplicateIcon from "assets/icons/duplicate";
import MDButton from "material-ui/components/MDButton";
import CreateOrderIcon from "assets/icons/create-order";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "../../../models/radius-mailing/filters";
import PlusIcon from "assets/icons/plus";
import HailTraceLogo from "assets/images/hail-trace-logo.svg"
import { useGlobal } from "context/global-context";

interface MasterDetailProps {
    row: ServiceArea
    apiRef: GridApiPro
}

function MasterDetail({ row, apiRef }: MasterDetailProps) {
    const { setShowLoader } = useGlobal()
    const queryClient = useQueryClient()
    const { putServiceAreaStatus } = StormAutomationService()

    const [serviceAreaOriginal, setServiceAreaOriginal] = useState<ServiceArea>(JSON.parse(JSON.stringify(row)))
    const [serviceArea, setServiceArea] = useState<ServiceArea>(JSON.parse(JSON.stringify(row)))

    const [isEditingName, setIsEditingName] = useState<boolean>(false)

    const [hasMapLoaded, setHasMapLoaded] = useState<boolean>(false)
    const [center, setCenter] = useState<Coordinate>()

    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }

    const mapContainerStyle = {
        width: '460px',
        height: '250px',
        borderRadius: "14px"
    }

    const columns: GridColDef[] = [
        {
            headerName: "Service Area",
            field: "name",
            flex: 1
        },
        {
            headerName: "Search Type",
            field: "type",
            flex: 1
        },
        {
            headerName: "Radius (mi)",
            field: "radius",
            flex: 1,
            renderCell: (params) => {
                if (params.row.type.toLowerCase() === "radius") {
                    return params.row.radius ? formatNumber(params.row.radius): "-"
                }

                return "-"
            }
        },
        {
            headerName: "Structures",
            field: "structures",
            flex: 1,
            valueFormatter: (params) => {
                return params.value ? formatNumber(params.value) : "-"
            }
        },
    ]

    useEffect(() => {
        if (!isEditingName) {
            setServiceArea(JSON.parse(JSON.stringify(serviceAreaOriginal)))
        }
    }, [serviceAreaOriginal, isEditingName]);

    function updateServiceArea() {
        setShowLoader(true)

        putServiceAreaStatus(serviceArea).then((result) => {
            setIsEditingName(false)

            setServiceAreaOriginal(result.payload)
            apiRef.updateRows([{ id: serviceArea.id, name: serviceArea.name }]);
            queryClient.invalidateQueries({queryKey: ["serviceAreas"]})
            setShowLoader(false)
        })
    }

    function onMapLoaded() {
        setHasMapLoaded(true)

        // Sets the center of the map to be the center of the first recipient search entry
        if (serviceArea.radii.length) {
            let centerObject = serviceArea.radii[0]

            setCenter(centerObject.coordinate)
        }
        else {
            if (serviceArea.polygons.length) {
                let centerObject = serviceArea.polygons[0]

                setCenter(getCenterOfPolygon(centerObject.coordinates))
            }
        }
    }

    return <MDBox>
        <MDBox p={3}>
            <MDCard border={true} borderColor={"dark"} boxShadow={false} borderRadiusSize={"xl"}>
                <MDBox p={2}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item flex={1}>
                            <Grid container alignItems={"center"} gap={"10px"}>
                                <Grid item>
                                    <Typography fontWeight={"bold"}>Name:</Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems={"center"}>
                                        <Grid item>
                                            {isEditingName ?
                                                <TextField
                                                    type={"text"}
                                                    value={serviceArea.name}
                                                    size={"small"}
                                                    onChange={(e) => setServiceArea(prevState => {
                                                        return { ...prevState, name: e.target.value }
                                                    })}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                :
                                                <Typography fontWeight={"bold"}>{serviceArea.name}</Typography>
                                            }
                                        </Grid>

                                        <Grid item>
                                            {isEditingName ?
                                                <>
                                                    <Tooltip title={"Save Name"}>
                                                        <IconButton onClick={updateServiceArea}>
                                                            <Icon fontSize={"small"}>check</Icon>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={"Cancel"}>
                                                        <IconButton onClick={() => setIsEditingName(false)}>
                                                            <Icon fontSize={"small"}>close</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                :
                                                <Tooltip title={"Edit Name"}>
                                                    <IconButton onClick={() => setIsEditingName(true)}>
                                                        <Icon fontSize={"small"}>edit</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <MDButton color={"primary"} disabled={true}>
                                <Grid container alignItems={"center"} justifyContent={"center"} gap={0.5}>
                                    <Grid item display={"flex"}>
                                        <CreateOrderIcon color={"white"}/>
                                    </Grid>

                                    <Grid item>
                                        Send more cards
                                    </Grid>
                                </Grid>
                            </MDButton>
                        </Grid>
                    </Grid>

                    <Divider light={true} />

                    <Grid container gap={6} flexDirection={"row"} flexWrap={"nowrap"}>
                        <Grid item minWidth={"350px"}>
                            <MDBox mt={2}>
                                <Grid container gap={"5px"} alignItems={"center"}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HailSizeIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Hail Size: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {serviceArea.hailSizeMinFilter ? `${serviceArea.hailSizeMinFilter}" and larger` : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <WindSpeedIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Wind Speed: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {serviceArea.windSpeedMinFilter ? `${serviceArea.windSpeedMinFilter} MPH and stronger` : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <CardsToSendIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>
                                                    Cards to send:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {serviceArea.cardsToSendPerStormMax ? `Up to ${formatNumber(serviceArea.cardsToSendPerStormMax)} cards` : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <PeriodRangeSendIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Sending Period Range: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {serviceArea.startSendingAtDate ? `${moment(serviceArea.startSendingAtDate).format("MM/DD/YY")} ${serviceArea.stopSendingAtDate ? ` - ${moment(serviceArea.stopSendingAtDate).format("MM/DD/YY")}` : ''}` : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeAlt2Icon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Home Owner: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {serviceArea.homeOwnerFilter != null ? OwnerOrRenterFilter[serviceArea.homeOwnerFilter] : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeEditIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Property Type: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {serviceArea.propertyTypeFilter != null ? PropertyTypeFilter[serviceArea.propertyTypeFilter] : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeSearchIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Total Structures: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {serviceArea.totalStructures ? formatNumber(serviceArea.totalStructures) : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>

                        <Grid item>
                            <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded}>
                                {hasMapLoaded &&
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        options={mapOptions}
                                        center={center}
                                        zoom={10}
                                    >
                                        {serviceArea.radii.map((address, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={{
                                                        lat: address.coordinate.lat,
                                                        lng: address.coordinate.lng
                                                    }}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}
                                                    >
                                                        {address.name}
                                                    </Typography>
                                                </OverlayView>

                                                <Circle
                                                    center={{
                                                        lat: address.coordinate.lat,
                                                        lng: address.coordinate.lng
                                                    }}
                                                    radius={address.radius * 1609.34}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.30,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 0.8
                                                    }}
                                                />
                                            </MDBox>
                                        })}

                                        {serviceArea.polygons.map((polygon, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={getCenterOfPolygon(polygon.coordinates)}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}
                                                    >
                                                        {polygon.name}
                                                    </Typography>
                                                </OverlayView>

                                                <MapsPolygon
                                                    key={polygon.id}
                                                    draggable={false}
                                                    editable={false}
                                                    path={polygon.coordinates}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.40,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 1,
                                                        strokeWeight: 3
                                                    }}
                                                />
                                            </MDBox>
                                        })}
                                    </GoogleMap>
                                }
                            </LoadScript>
                        </Grid>

                        <Grid item maxWidth={"650px"}>
                            {serviceArea.template?
                                <Grid container gap={2} flexWrap={"nowrap"} sx={{maxWidth: "1000px"}}>
                                    <Grid item flex={1}>
                                        <MDTypography mb={1} fontWeight={"bold"} color={"secondary"} sx={{fontSize: 18}}>Card Front</MDTypography>

                                        <MDCard borderRadiusSize={"md"} boxShadow={false} border={true}>
                                            <img alt={""} style={{width: "100%", height: "100%"}} src={serviceArea.template.sceneFrontFull}/>
                                        </MDCard>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <MDTypography mb={1} fontWeight={"bold"} color={"secondary"} sx={{fontSize: 18}}>Card Rear</MDTypography>

                                        <MDCard borderRadiusSize={"md"} boxShadow={false} border={true}>
                                            <img alt={""} style={{width: "100%", height: "100%"}} src={serviceArea.template.sceneBackFull}/>
                                        </MDCard>
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>

                    <MDBox mt={4}>
                        <StyledDataGrid
                            rows={[...serviceArea.radii ?? [], ...serviceArea.polygons ?? []]}
                            columns={columns}
                            disableRowSelectionOnClick={true}
                            pagination={false}
                            hideFooter={true}
                            noMinWidth={true}
                            cellModesModel={cellModesModel}
                            onCellModesModelChange={(model) => setCellModesModel(model)}
                        />
                    </MDBox>
                </MDBox>
            </MDCard>
        </MDBox>
    </MDBox>
}

function StormAutomationHistoryPage() {
    const queryClient = useQueryClient()
    const { setShowLoader } = useGlobal()

    const navigate = useNavigate()

    const { getServiceAreas, deleteServiceArea, putServiceAreaStatus, copyServiceArea } = StormAutomationService()

    let apiRef = useGridApiRef()

    const [expandedRowId, setExpandedRowId] = useState<GridRowId | null>(null)

    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState<boolean>(false)
    const [showCopyOrderModal, setShowCopyOrderModal] = useState<boolean>(false)
    const [serviceAreaToDelete, setServiceAreaToDelete] = useState<ServiceArea | null>(null)
    const [serviceAreaToCopy, setserviceAreaToCopy] = useState<ServiceArea | null>(null)

    const getServiceAreasQuery = useQuery({
        queryFn: () => {
            return getServiceAreas()
        },
        queryKey: ["serviceAreas"],
        refetchOnWindowFocus: false
    })

    const columns: GridColDef[] = [
        {
            headerName: "Name",
            field: "searchType",
            flex: 1,
            minWidth: 200,
            align: "center",
            headerAlign: "center"
        },
        {
            headerName: "Service Area",
            field: "totalStructures",
            align: "center",
            headerAlign: "center",
            width: 150,
            valueFormatter: (params) => {
                return params.value > 0 ? formatNumber(params.value) : "-"
            }
        },
        {
            headerName: "Status",
            field: "status",
            width: 200,
            headerAlign: "center",
            align: "center",
            renderCell: function (params) {
                let serviceArea = params.row as ServiceArea

                return <MDSelect
                    sx={{ width: "140px", height: 32 }}
                    fullWidth={true}
                    color={(() => {
                        if (serviceArea.status === ServiceAreaStatus.Draft) {
                            return "warning"
                        }
                        if (serviceArea.status === ServiceAreaStatus.Active) {
                            return "success"
                        }
                        if (serviceArea.status === ServiceAreaStatus.Inactive) {
                            return "error"
                        }

                        return "primary"
                    })()}
                    value={serviceArea.status}
                    onChange={(event: any) => {
                        let updatedRow = params.row as ServiceArea
                        updatedRow.status = event.target.value

                        setShowLoader(true)
                        putServiceAreaStatus(updatedRow).then(() => [
                            setShowLoader(false)
                        ])
                    }}
                    variant={"outlined"}
                >
                    <MenuItem value={ServiceAreaStatus.Active}>
                        Active
                    </MenuItem>

                    <MenuItem value={ServiceAreaStatus.Inactive}>
                        Inactive
                    </MenuItem>

                    {serviceArea.status === ServiceAreaStatus.Draft?
                        <MenuItem value={ServiceAreaStatus.Draft}>
                            Draft
                        </MenuItem>
                        :
                        null
                    }
                </MDSelect>
            }
        },
        {
            headerName: "Production Status",
            field: "productionStatus",
            headerAlign: "center",
            align: "center",
            width: 600,
            hideSortIcons: true,
            sortable: false,
            renderCell: function (params) {
                let order = params.row as ServiceArea

                return <Grid container alignItems={"center"} justifyContent={"space-between"} position={"relative"} width={"100%"} overflow={"hidden"}>
                    <Grid item flex={1} p={"4px"} sx={{
                        backgroundColor: "#929CB2",
                        borderTopLeftRadius: borders.borderRadius.md,
                        borderBottomLeftRadius: borders.borderRadius.md,
                        textAlign: "center"
                    }}>
                        <MDTypography fontWeight={"normal"} sx={{fontSize: 16}} color={"white"}>
                            Queued: <MDTypography component="span" display={"inline-block"} pl={0.5} sx={{fontSize: 16}} color={"white"}>{order.queuedCount? formatNumber(order.queuedCount) : 0}</MDTypography>
                        </MDTypography>
                    </Grid>

                    <Grid item flex={1} p={"4px"} sx={{backgroundColor: "#F05A2A", textAlign: "center"}} position={"relative"}>
                        <MDBox sx={{
                            position: "absolute",
                            width: 20,
                            height: 42,
                            left: -7,
                            top: 0,
                            zIndex: 1,
                            background: "#F05A2A",
                            transform: "rotate(20deg)"
                        }}></MDBox>

                        <MDTypography fontWeight={"normal"} sx={{fontSize: 16}} color={"white"}>
                            In Production: <MDTypography component="span" display={"inline-block"} pl={0.5} sx={{fontSize: 16}} color={"white"}>{order.inProductionCount? formatNumber(order.inProductionCount) : 0}</MDTypography>
                        </MDTypography>

                        <MDBox sx={{
                            position: "absolute",
                            width: 20,
                            height: 42,
                            right: -7,
                            top: -7,
                            zIndex: 1,
                            background: "#F05A2A",
                            transform: "rotate(20deg)"
                        }}></MDBox>
                    </Grid>

                    <Grid item flex={1} p={"4px"} sx={{
                        backgroundColor: "#130B46",
                        borderTopRightRadius: borders.borderRadius.md,
                        borderBottomRightRadius: borders.borderRadius.md,
                        textAlign: "center"
                    }}>
                        <MDTypography fontWeight={"normal"} sx={{fontSize: 16}} color={"white"}>
                            Mailed: <MDTypography component="span" display={"inline-block"} pl={0.5} sx={{fontSize: 16}} color={"white"}>{order.mailedCount? formatNumber(order.mailedCount) : 0}</MDTypography>
                        </MDTypography>
                    </Grid>
                </Grid>
            }
        },
        {
            type: "actions",
            field: "actions",
            align: "right",
            flex: 1,
            minWidth: 200,
            getActions: (params: any) => {
                let orderId = params.row.id

                let buttons = []

                buttons.push(
                    <Tooltip title={"Details"}>
                        <MDIconButton borderRadiusSize={"xxl"} sx={{ padding: "5px", width: "40px", height: "30px", minWidth: "unset" }} backgroundColor={"light"} onClick={() => {
                            navigate(`/storm-automation/order/${orderId}`)
                        }}>
                            <EditPencilIcon />
                        </MDIconButton>
                    </Tooltip>
                )

                // if (!params.row.canEdit) {
                    buttons.push(
                        <Tooltip title={"Copy Service Area"}>
                            <MDIconButton borderRadiusSize={"xxl"} sx={{ padding: "5px", width: "40px", height: "30px", minWidth: "unset" }} backgroundColor={"light"} onClick={() => {
                                setserviceAreaToCopy(params.row)
                                setShowCopyOrderModal(true)
                            }}>
                                <DuplicateIcon />
                            </MDIconButton>
                        </Tooltip>
                    )
                // }

                
                buttons.push(
                    <Tooltip title={params.row.canDelete ? "Delete Service Area" : "Unable to delete service area"}>
                        <span>
                            <MDIconButton disabled={!params.row.canDelete} borderRadiusSize={"xxl"} sx={{ padding: "5px", width: "40px", height: "30px", minWidth: "unset" }} backgroundColor={"light"} onClick={() => {
                                setServiceAreaToDelete(params.row)
                                setShowDeleteOrderModal(true)
                            }}>
                                <DeleteIcon color={"error"} />
                            </MDIconButton>
                        </span>
                    </Tooltip>
                )
            

                return buttons
            }
        }
    ]

    function deleteOrder() {
        setShowLoader(true)

        deleteServiceArea(serviceAreaToDelete?.id!).then(() => {
            setShowDeleteOrderModal(false)

            setServiceAreaToDelete(null)

            queryClient.invalidateQueries({ queryKey: ["serviceAreas"] })

            setShowLoader(false)
        })
    }

    function copyOrder(){
        setShowLoader(true)
        copyServiceArea(serviceAreaToCopy!).then((response) => {
            setShowLoader(false)

            navigate(`/storm-automation/order/${response.payload.id}`)
        })
    }

    return <MDCard>
        <MDBox p={3} pl={4} pb={4}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                    <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}}>Storm Automation powered by</MDTypography>
                    <img alt={"Hail Trace"} src={HailTraceLogo}/>
                </Box>
                <MDButton onClick={() => navigate("/storm-automation/order")}>
                    <PlusIcon style={{marginRight: 4}} /> New Automation
                </MDButton>
            </Box>

            <MDBox height={"80vh"} mt={3}>
                <StyledDataGrid
                    apiRef={apiRef}
                    disableBorders={true}
                    backgroundColor={"white"}
                    loading={getServiceAreasQuery.isLoading}
                    rows={getServiceAreasQuery.data?.payload ?? []}
                    columns={columns}
                    disableRowSelectionOnClick={true}
                    getDetailPanelHeight={() => "auto"}
                    detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
                    onDetailPanelExpandedRowIdsChange={(ids) => {
                        if (!ids) {
                            setExpandedRowId(null)
                            return
                        }

                        // Only allow the user to expand one row at a time since the google api only allows 1 map shown at a time
                        setExpandedRowId(ids[ids.length - 1])
                    }}
                    getDetailPanelContent={(row) => {
                        return <MasterDetail row={row.row as ServiceArea} apiRef={apiRef.current} />
                    }}
                    pagination={false}
                    hideFooter={true}
                />
            </MDBox>
        </MDBox>

        <InfoModal
            show={showDeleteOrderModal}
            setShow={setShowDeleteOrderModal}
            headerText={"Are you sure you want to delete this service area?"}
            showConfirmButton={true}
            showCancelButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteOrderModal(false)
            }}
            confirmButtonOnClick={() => {
                deleteOrder()
            }}
        />

        <InfoModal
            show={showCopyOrderModal}
            setShow={setShowCopyOrderModal}
            headerText={"Are you sure you want to copy (duplicate) this service area?"}
            showConfirmButton={true}
            showCancelButton={true}
            cancelButtonOnClick={() => {
                setShowCopyOrderModal(false)
            }}
            confirmButtonOnClick={() => {
                copyOrder()
            }}
        />
    </MDCard>
}

export default StormAutomationHistoryPage