import { getUserData } from 'helpers/getUserInfos';
import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import useCheckoutDataService from 'services/checkout';
import { IBillingInfoSelfPayment } from 'services/checkout/checkout.types';
import { IObjectResult } from 'services/endpoint';

type ModalType = 'upgrade' | 'downgrade' | null;

interface SelfManagementPlanModalContextType {
  isModalVisible: boolean;
  modalType: ModalType;
  showModal: (type: ModalType) => void;
  hideModal: () => void;
  tierToUpgrade: string;
  isLoadingCheckoutData: boolean;
  setIsLoadingCheckoutData: (value: boolean) => void;
  checkoutData: IObjectResult<IBillingInfoSelfPayment> | null;
  setTierToUpgrade: (value: string) => void
}

const SelfManagementPlanModalContext = createContext<SelfManagementPlanModalContextType | undefined>(undefined);

export const SelfManagementPlanModalProvider = ({ children }: { children: ReactNode }) => {

  const { getDataBySubscriptionType } = useCheckoutDataService();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoadingCheckoutData, setIsLoadingCheckoutData] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [checkoutData, setCheckoutData] = useState<IObjectResult<IBillingInfoSelfPayment > | null>(null);
  const [tierToUpgrade, setTierToUpgrade] = useState('');

  const showModal = (type: ModalType) => {
    setModalType(type);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setModalType(null);
  };

  const getCheckoutData = () => {
    if(!tierToUpgrade || tierToUpgrade === 'Enterprise Tier') return
    setIsLoadingCheckoutData(true);
    getDataBySubscriptionType(getSubscriptionToLoad() ?? '').then(res => {
      setCheckoutData(res);
      setIsLoadingCheckoutData(false);
    }, () => {
      setIsLoadingCheckoutData(false)
    })
  }

  const getSubscriptionToLoad = () => {
      if(tierToUpgrade === 'Core Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Monthly') {
          return 'saas-core-monthly'
      }
  
      if(tierToUpgrade === 'Core Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Annual') {
          return 'saas-core-annual'
      }
  
      if(tierToUpgrade === 'Premium Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Monthly') {
          return 'saas-premium-monthly'
      }

      if(tierToUpgrade=== 'Premium Tier' && getUserData()?.customerSubscription?.subscription?.billingPeriod?.description === 'Annual') {
          return 'saas-premium-annual'
      }
  }

  useEffect(() => {
    getCheckoutData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tierToUpgrade])

  return (
    <SelfManagementPlanModalContext.Provider
      value={{
        isModalVisible,
        modalType,
        showModal,
        hideModal,
        tierToUpgrade,
        checkoutData,
        isLoadingCheckoutData,
        setIsLoadingCheckoutData,
        setTierToUpgrade
      }}
    >
      {children}
    </SelfManagementPlanModalContext.Provider>
  );
};

export const useSelfManagementPlanModal = (): SelfManagementPlanModalContextType => {
  const context = useContext(SelfManagementPlanModalContext);
  if (!context) {
    throw new Error('useSelfManagementPlanModal deve ser usado dentro do SelfManagementPlanModalProvider');
  }
  return context;
};
