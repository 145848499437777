import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import {
  IMoverAutomation,
  IMoversSchema,
  ISmartAutomationPayload,
  PostageTypeEnum,
} from 'services/smart-automation/smart-automation.types';
import moverAutomationSchema from 'services/smart-automation/smart-automation.schema';
import MoversForm from './movers-form';
import MoversEnable from './movers-enable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSmartAutomationService from 'services/smart-automation';
import { useGlobal } from 'context/global-context';
import { useMoversContext } from 'context/movers-context';
import { ITemplate } from 'models/template';
import { Box, Dialog, DialogContent } from '@mui/material';
import Loader from 'components/loader';

const moverAutomationInitialValues: IMoversSchema = {
  polygonSearches: [],
  addressRadiusSearches: [],
  filter: { ownerOrRenterFilter: 0, propertyTypeFilter: 2, lookBackMonths: undefined },
  sendingOptions: { maxNumberCardsSentPerMonth: null, postageType: PostageTypeEnum.Standard },
  template: undefined,
  recipientSearchId: 0,
};

interface MoversFormikProps {
  initialValues: IMoversSchema;
}

const MoversFormik = ({ initialValues }: MoversFormikProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');
  const name = queryParams.get('name');

  const { setShowLoader } = useGlobal();
  const { calculateTargets, createSmartAutomation, updateSmartAutomation } = useSmartAutomationService();
  const { targetsCalculated, setTargetsCalculated } = useMoversContext();
  const { id: automationId } = useParams();
  const isEdit = automationId !== 'new';

  const [openEnableModal, setOpenEnableModal] = useState<boolean>(false);
  const [fullPayload, setFullPayload] = useState<ISmartAutomationPayload>();
  const [updatedValues, setUpdatedValues] = useState<IMoversSchema>();
  const [startPolling, setStartPolling] = useState<boolean>(false);
  const [template, setTemplate] = useState<ITemplate>();

  const onMutate = () => setShowLoader(true);

  const { mutate: calculate, isPending: isCalculating } = useMutation({
    mutationFn: (values: ISmartAutomationPayload) => calculateTargets(values),
    onSuccess: ({ payload }: { payload: IMoverAutomation }) => {
      setUpdatedValues({ ...payload, template });
      setStartPolling(true);
    },
  });

  const { mutate: createAutomation } = useMutation({
    mutationFn: createSmartAutomation,
    onMutate,
    onSuccess: ({ payload }) => navigate(`/smart-automation/${payload.id}${search}`, { replace: true }),
  });

  const { mutate: updateAutomation } = useMutation({
    mutationFn: updateSmartAutomation,
    onMutate,
    onSuccess: ({ payload }) => {
      if (targetsCalculated) {
        setFullPayload(payload);
        setOpenEnableModal(true);
        setShowLoader(false);
      } else calculate(payload);
    },
  });

  useEffect(() => {
    setShowLoader(true);
  }, [setShowLoader]);

  useEffect(() => {
    if (isEdit && initialValues) {
      if (initialValues.isCalculatingTargets) {
        setStartPolling(true);
        setShowLoader(true);
      } else if (
        !!initialValues.recipientSearchId &&
        (!!initialValues.polygonSearches?.[0]?.availableQuantity ||
          !!initialValues.addressRadiusSearches?.[0]?.availableQuantity)
      ) {
        setTargetsCalculated?.(true);
      }
    }
    return () => {
      setShowLoader(false);
    };
  }, [initialValues, isEdit, setShowLoader, setTargetsCalculated]);

  const handleSubmit = (values: IMoversSchema) => {
    const { template: newTemplate, ...payload } = values;
    const moversPayload: ISmartAutomationPayload = {
      dataSourceId: Number(source),
      templateId: newTemplate?.id,
      payload: JSON.stringify(payload),
      name: name ?? '',
    };
    if (!targetsCalculated || !template) setTemplate(newTemplate);
    if (isEdit) updateAutomation({ ...moversPayload, id: Number(automationId) });
    else createAutomation(moversPayload);
  };

  const customValidation = (values: IMoversSchema) => {
    if (values.polygonSearches?.length || values.addressRadiusSearches?.length || !isEdit) return {};
    return { addressRadiusSearches: 'At least one address or one polygon search is required' };
  };

  return (
    <>
      <Dialog open={startPolling || isCalculating}>
        <DialogContent>
          <Box display={'flex'} justifyContent={'center'}>
            <Loader />
          </Box>
          Loading may take some time, especially with larger data sets. You can leave or refresh this page; the process
          will continue in the background.
        </DialogContent>
      </Dialog>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={initialValues || moverAutomationInitialValues}
        validationSchema={moverAutomationSchema}
        validate={customValidation}
        onSubmit={handleSubmit}
      >
        <MoversForm updatedValues={updatedValues} startPolling={startPolling} setStartPolling={setStartPolling} />
      </Formik>
      <MoversEnable payload={fullPayload} open={openEnableModal} setOpen={setOpenEnableModal} />
    </>
  );
};

export default MoversFormik;
