import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import MDButton from 'material-ui/components/MDButton';
import SubscriptionIcon from 'assets/images/subscription.png';


const SubscriptionStep: React.FC = () => {
  const navigate = useNavigate()

  const goToSubscriptionsPage = () => {
    navigate('/subscriptions');
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '2rem' }}>
      <Typography variant="h3" sx={{ margin: '3rem 0 .5rem 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 26 }}>
            Subscription
      </Typography>
      <Box sx={{ padding: '2rem 0' }}>
          <Typography variant="body2" fontWeight={'bold'}>Active LettrLabs subscription</Typography>
          <Box sx={{ display: 'flex', alignItems: 'start'}}>
            <Box sx={{ width: '70%'}}>
              <Typography variant="body2" sx={{ margin: '.5rem 0', opacity: .8, padding: '1rem 0' }}>
                  To handle daily incoming leads from your website traffic, activating LeadReveal requires an active subscription.            
              </Typography>
              <MDButton onClick={goToSubscriptionsPage} color='primary'>Click to Subscribe</MDButton>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 4, marginRight: '-30px'}}>
              <img src={SubscriptionIcon} alt='Subscription Icon' width={'300px'} style={{ marginTop: '-50px'}} />
              <Typography variant='h4' sx={{ marginTop: '-30px'}} >Subscribe Now</Typography>
            </Box>
          </Box>
      </Box>
    </Box>
  );
}

export default SubscriptionStep;