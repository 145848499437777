import React, { useEffect, useState} from "react";

import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";

import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useGlobal } from "context/global-context";
import { ITemplate, Template } from "models/template";

import MDButton from "material-ui/components/MDButton";
import TemplateCard from "components/template-card";
import MDTypography from "material-ui/components/MDTypography";
import TemplateService from "services/template";
import CreateOrderIcon from "assets/icons/create-order";
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield";
import { useNavigate } from "react-router-dom";

function MyTemplatesPage() {
    const {setShowLoader} = useGlobal()
    const {getTemplates, postTemplate} = TemplateService()
    
    const navigate = useNavigate()

    const [columns, setColumns] = useState<number | null>(null)
    const [windowWidth, setWindowWidth] = useState<number>(0)
    const [newTemplateName, setNewTemplateName] = useState<string>("")

    const [showCreateNewTemplateModal, setShowCreateNewTemplateModal] = useState<boolean>(false)

    const getTemplatesQuery = useQuery({
        queryKey: ["templates", false],
        queryFn: () => getTemplates()
    })

    const createNewTemplateMutation = useMutation({
        mutationFn: () => {
            let template = new Template(0);
            template.name = newTemplateName

            return postTemplate(template)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            setShowCreateNewTemplateModal(false)
            navigate(`/template/${data.id}`)
        }
    })

    useEffect(() => {
        setShowLoader(getTemplatesQuery.isLoading)
    }, [getTemplatesQuery.isLoading, setShowLoader]);

    useEffect(() => {
        if(!windowWidth){
            return
        }

        if(windowWidth >= 2300){
            setColumns(5)
        }
        else if(windowWidth >= 1900){
            setColumns(4)
        }
        else if(windowWidth >= 1300){
            setColumns(3)
        }
        else if(windowWidth >= 1000){
            setColumns(2)
        }
        else{
            setColumns(1)
        }
    }, [windowWidth]);

    useEffect(() => {
        setWindowWidth(window.innerWidth)

        window.addEventListener("resize", function () {
            setWindowWidth(window.innerWidth)
        })
    }, []);

    if(!columns){
        return null
    }

    return (
        <MDCard sx={{ margin: '.25rem', position: 'relative', minHeight: '90vh'}}>
                <MDBox sx={{ display: 'flex', flexDirection: 'column' }} px={4} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100px'}} mb={2} pb={4}>
                        <MDTypography fontWeight={"bold"} sx={{fontSize: "18px"}}>My Templates</MDTypography>
                        <Grid item>
                            <Grid container gap={2}>
                                <Grid item>
                                    <MDButton
                                        color={"primary"}
                                        onClick={() => {
                                            setShowCreateNewTemplateModal(true)
                                        }}
                                    >
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item display={"flex"}>
                                                <CreateOrderIcon/>
                                            </Grid>
                                            <Grid item>
                                                New Template
                                            </Grid>
                                        </Grid>
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{ borderBottom: '4px solid #eef2ff', width: '100%', position: 'absolute', left: 0, top: '65px'}} />

                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                        {getTemplatesQuery.data?.map((template: Template, index: number) => (
                            <Grid item key={index}  p={1} sx={{ padding: getTemplatesQuery.data?.some((t: ITemplate) => t.product === "Handwritten Bi-Fold Card") ? '4rem 0 0 0' : '2rem 0' }}>
                                <TemplateCard template={template}/>
                            </Grid>
                        ))}
                    </Box>
                </MDBox>

            <TwoButtonWithOptionalFormFieldModal
                open={showCreateNewTemplateModal}
                onClose={() => setShowCreateNewTemplateModal(false)}
                formFieldLabel="Template Name"
                formFieldHelperText="Please name your template, this will help you identify it later"
                formFieldValue={newTemplateName}
                formFieldOnChange={setNewTemplateName}
                primaryButtonText="Create"
                primaryButtonOnClick={() => createNewTemplateMutation.mutate()}
                secondaryButtonText="Cancel"
                secondaryButtonOnClick={() => setShowCreateNewTemplateModal(false)}
            />
        </MDCard>
    )
}

export default MyTemplatesPage