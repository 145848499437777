export enum OrderRecipientWarning {
  PotentiallyUndeliverable = 'potentiallyUndeliverable',
}

export enum OrderRecipientError {
  InvalidCharacter = 'invalidCharacter',
  MissingMailMergeField = 'missingMailMergeFields',
  MissingAddressFields = 'missingAddressFields',
  MissingNameFields = 'missingNameFields',
  NonUsAddress = 'nonUsAddress',
  Undeliverable = 'undeliverable'
}

export enum OrderRecipientInvalid {
  DoNotMail = 'doNotMail',
  Duplicated = 'duplicated',
}

export interface IOrderRecipient {
  id: number;
  orderId: number;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  toOrganization?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  custom1?: string;
  custom2?: string;
  custom3?: string;
  custom4?: string;
  custom5?: string;
  custom6?: string;
  radiusPinStreet?: string;
  radiusPinAddress?: string;
  errors?: string[];
  warnings?: string[];
  missingMailMergeFields?: string[];
  isDeliverable?: boolean;
  isDuplicated?: boolean;
  doNotMail?: boolean;
}

export interface IOrderRecipientWarnings {
  potentiallyUndeliverable: number;
}

export interface IOrderRecipientErrors {
  invalidCharacter: number;
  missingAddressFields: number;
  missingMailMergeField: number;
  missingNameFields: number;
  nonUsAddress: number;
  undeliverable: number;
}

export interface IOrderRecipientInvalid {
  doNotMail: number;
  duplicated: number;
}

export interface IOrderRecipientData {
  recipients: IOrderRecipient[];
  usedColumns: string[];
  totalRecords: number;
  totalFiltered: number;
  totalDeliverable: number;
  recipientWarningDetails: IOrderRecipientWarnings;
  recipientErrorDetails: IOrderRecipientErrors;
  recipientInvalidDetails: IOrderRecipientInvalid;
}

export interface IOrderRecipientFilterParams {
  page: number;
  pageSize: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  search: string;
  showWarning: boolean;
  showError: boolean;
}
