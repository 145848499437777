import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Actions from './components/Actions';
import EditIcon from 'assets/icons/edit';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';

import { StatusBall } from './components/StatusBall';
import { IAutomation } from 'services/automations/automatios.service.types';
import { statusStyles } from './components/StatusStyles';
import { visuallyHidden } from '@mui/utils';
import { Skeleton, Tooltip } from '@mui/material';
import { useAutomationContext } from 'pages/automations/context';
import { AutomationsTableProps, EnhancedTableProps, HeadCell, Order } from './models/table.types';

function getComparator<Key extends keyof IAutomation>(
  order: Order,
  orderBy: Key
): (a: IAutomation, b: IAutomation) => number {
  return (a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === 'object' || typeof bValue === 'object') {
      return 0;
    }

    const aComparable = aValue as string | number | boolean | null | undefined;
    const bComparable = bValue as string | number | boolean | null | undefined;

    if (aComparable === bComparable) return 0;
    if (aComparable == null) return 1;
    if (bComparable == null) return -1;
    return order === 'desc'
      ? aComparable > bComparable
        ? -1
        : 1
      : aComparable > bComparable
      ? 1
      : -1;
  };
}

const headCells: readonly HeadCell[] = [
  { id: 'status', numeric: false, disablePadding: false, label: '' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'integrationOrderTypeName', numeric: false, disablePadding: true, label: 'Automation Type' },
  { id: 'orderStatus', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'queued', numeric: true, disablePadding: false, label: 'Queued' },
  { id: 'inProduction', numeric: true, disablePadding: false, label: 'In Production' },
  { id: 'mailed', numeric: true, disablePadding: false, label: 'Mailed' }
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IAutomation) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ whiteSpace: 'nowrap', fontSize: '.8rem'}}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell sx={{ fontSize: '.8rem'}}>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function AutomationsTable({ isLoading }: AutomationsTableProps) {
  const { 
    automations, 
    toggleModal, 
    setSelectedAutomation,
    automationFilterData
  } = useAutomationContext();

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IAutomation>('name');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IAutomation,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () => 
      [...automations]
        .sort(getComparator(order, orderBy))
        .filter((row) => {
          const matchesType = !automationFilterData.integrationOrderTypeName || 
            row.integrationOrderTypeName.includes(automationFilterData.integrationOrderTypeName);
          const matchesStatus = !automationFilterData.status || 
            row.status?.includes(automationFilterData.status);
          return matchesType && matchesStatus;
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, automations, order, orderBy, automationFilterData]
  );

  const getStatusComponent = (status: string) => {
    const { color } = statusStyles[status.toUpperCase()] || { color: 'black', ballColor: 'black' };
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '80%', alignItems: 'center', gap: 1, color, fontWeight: 500 }}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          {status.toUpperCase() === 'INACTIVE' ? 'Paused' : status}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%', m: 4, p: 4, border: '1px solid rgba(0,0,0,.1)', borderRadius: '5px' }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={automations.length}
          />
          <TableBody>
            {!isLoading && visibleRows.map((row) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    <StatusBall row={row} />
                  </TableCell>
                  <TableCell align="left" sx={{  fontSize: '.8rem'}}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Tooltip title={row.name ?? ''}>
                          <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '400px'}}>
                            {row.name}
                          </Box>
                        </Tooltip>
                        <Tooltip title="Edit name" placement='right'>
                          <Box>
                            <EditIcon
                              onClick={() => {
                                setSelectedAutomation(row);
                                toggleModal('isUpdateAutomationNameModalOpen', true);
                              }}
                              sx={{ 
                                fontSize: '16px !important', 
                                width: '26px', 
                                height: '26px', 
                                color: '#344767', 
                                background: '#f3f5fb', 
                                borderRadius: '100%' ,
                                padding: '4px'
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '.8rem'}}>{row.integrationOrderTypeName}</TableCell>
                  <TableCell align="left" sx={{ fontSize: '.8rem'}}>{getStatusComponent(row.status ?? '')}</TableCell>
                  <TableCell align="left" sx={{ fontSize: '.8rem'}}>{row.queued}</TableCell>
                  <TableCell align="left" sx={{ fontSize: '.8rem'}}>{row.inProduction}</TableCell>
                  <TableCell align="left" sx={{ fontSize: '.8rem'}}>{row.mailed}</TableCell>
                  <TableCell  sx={{ fontSize: '.8rem' }}>
                    <Actions row={row} />
                  </TableCell>
                </TableRow>
              );
            })}
            
            {isLoading && (
              <React.Fragment>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {Array(8).fill(null).map((_, idx) => (
                      <TableCell key={idx} sx={{ width: idx === 0 ? '50px' : '400px' }}>
                        <Skeleton height={35} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={automations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          margin: '2rem 0 0 0',
          '.MuiTablePagination-toolbar': {
            fontSize: '0.875rem',
          },
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            fontSize: '0.75rem',
          },
          '.MuiTablePagination-select': {
            fontSize: '0.75rem',
          },
        }}
      />
    </Box>
  );
}