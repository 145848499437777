import {
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    Radio,
    Table,
    TableBody,
    TableRow, TextField
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "material-ui/components/MDButton";
import MDBox from "material-ui/components/MDBox";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useState, useEffect, FormEvent} from "react";
import {loadStripe, PaymentMethod, Stripe} from "@stripe/stripe-js";
import {IProfile} from "models/profile";
import MDTypography from "material-ui/components/MDTypography";
import {Theme} from "@mui/material/styles";
import InfoModal from "components/info-modal";
import {RecipientSearch} from "models/recipient-search/recipientSearch";
import {formatNumber, formatPrice} from "helpers/formatters";
import PaymentCard from "components/payment-card";
import RadioSelectWrapper from "components/radio-select-wrapper";
import {RecipientSearchCheckout} from "models/recipientSearchCheckout";
import RecipientSearchService from "services/recipient-search";
import { useGlobal } from "context/global-context";

interface CheckoutModalProps{
    show: boolean
    setShow: Function
    recipientSearchData: RecipientSearch
    setRecipientSearchData: Function
    showSuccessfulCheckoutModal: boolean
    setShowSuccessfulCheckoutModal: Function
}

interface StripeFormProps{
    profile: IProfile,
    checkout: RecipientSearchCheckout
    setShowErrorModal: Function
    setErrorModalMessage: Function
    setShowSuccessModal: Function
    setShow: Function
    recipientSearchData: RecipientSearch
}

enum PaymentMethodTypes {
    ChargeCardOnFile= 1,
    NewPayment= 2
}

function StripeForm({profile, checkout, setShowErrorModal, setErrorModalMessage, setShowSuccessModal, setShow, recipientSearchData}: StripeFormProps){
    const {setShowLoader} = useGlobal()

    const {postRecipientSearch} = RecipientSearchService()

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodTypes | null>(null)
    const [selectedCard, setSelectedCard] = useState<PaymentMethod | null>(null)

    const stripe = useStripe();
    const elements = useElements();

    function checkoutFn(event: FormEvent){
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        // Update the name of the order
        postRecipientSearch(recipientSearchData).then(() => {
            //Paying with an already existing card
            if(selectedCard){
                setShowLoader(true)

                stripe.confirmCardPayment(checkout.stripeToken, {
                    payment_method: selectedCard.id
                }).then((result) => {
                    handlePaymentCallback(result)
                })
            }
            else{
                elements.submit()
                    .then((result1) => {
                        if(result1.error){
                            return;
                        }

                        setShowLoader(true)

                        //stripe.confirmPayment will only redirect if the user chooses a redirect-based payment method
                        stripe.confirmPayment({
                            elements,
                            redirect: "if_required"
                        }).then((result2: any) => {
                            handlePaymentCallback(result2)
                        })
                    })
            }
        })
    }

    function handlePaymentCallback(response: any){
        if(response.error){
            let error = response.error
            let message

            if (error.type === "card_error") {
                if(error.code === "card_declined"){
                    message = "You card has been declined. Please contact your bank for additional information"
                }
                else if(error.code === "expired_card"){
                    message = "You card has expired"
                }
                else if(error.code === "incorrect_cvc" || error.code === "incorrect_number"){
                    message = "Invalid cvc or number. Please verify your credentials and try again"
                }
                else{
                    message = "An unexpected error occurred. Please try again or contact billing@lettrlabs.com"
                }
            }
            else {
                message = "An unexpected error occurred. Please try again or contact billing@lettrlabs.com"
            }

            setShowErrorModal(true)
            setErrorModalMessage(message)
        }
        else{
            let paymentIntent = response.paymentIntent;

            //'canceled','processing','requires_action','requires_capture','requires_confirmation','requires_payment_method','succeeded'
            if(paymentIntent.status === "succeeded"){
                setShow(false)
                setShowSuccessModal(true)
            }
        }

        setShowLoader(false)
    }

    useEffect(() => {
        if(checkout.savedPaymentMethods && checkout.savedPaymentMethods.length){
            setPaymentMethod(PaymentMethodTypes.ChargeCardOnFile)
        }
        else{
            setPaymentMethod(PaymentMethodTypes.NewPayment)
        }
    }, [checkout]);

    return <form id="payment-form" onSubmit={checkoutFn}>
        <Grid container gap={2} justifyContent={"space-between"} width={"100%"}>
            <Grid item>
                <Grid container gap={2} flexDirection={"column"}>
                    <Grid item>
                        <RadioSelectWrapper
                            selected={paymentMethod === PaymentMethodTypes.ChargeCardOnFile}
                            disabled={!checkout.savedPaymentMethods.length}
                            onClick={() => {
                                setPaymentMethod(PaymentMethodTypes.ChargeCardOnFile)
                            }}
                        >
                            <MDBox p={2}>
                                <Grid container>
                                    <Grid item>
                                        <Radio
                                            checked={paymentMethod === PaymentMethodTypes.ChargeCardOnFile}
                                        />
                                    </Grid>

                                    <Grid item display={"flex"} alignItems={"center"}>
                                        <MDTypography variant={"h4"}>
                                            Charge card on file
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </RadioSelectWrapper>
                    </Grid>

                    <Grid item>
                        <RadioSelectWrapper
                            selected={paymentMethod === PaymentMethodTypes.NewPayment}
                            onClick={() => {
                                setSelectedCard(null)
                                setPaymentMethod(PaymentMethodTypes.NewPayment)
                            }}
                        >
                            <MDBox p={2}>
                                <Grid container>
                                    <Grid item>
                                        <Radio checked={paymentMethod === PaymentMethodTypes.NewPayment}/>
                                    </Grid>

                                    <Grid item display={"flex"} alignItems={"center"}>
                                        <MDTypography variant={"h4"}>New payment info</MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </RadioSelectWrapper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item flex={1}>
                {paymentMethod === PaymentMethodTypes.ChargeCardOnFile?
                    <Grid container flexDirection={"column"} gap={1}>
                        {checkout.savedPaymentMethods.map((card, index) => {
                            return <Grid item key={index}>
                                <PaymentCard card={card} variant={"small"}
                                             selected={card.id === selectedCard?.id} onClick={() => {
                                    setSelectedCard(card)
                                }}/>
                            </Grid>
                        })}
                    </Grid>
                    :
                    null
                }

                {paymentMethod === PaymentMethodTypes.NewPayment?
                    <PaymentElement
                        options={{
                            defaultValues: {
                                billingDetails: {
                                    name: (profile && profile.firstName && profile.lastName)? `${profile.firstName} ${profile.lastName}` : ``,
                                    address: {
                                        country: "US"
                                    }
                                }
                            }
                        }}/>
                    :
                    null
                }
            </Grid>
        </Grid>

        <MDBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="flex-end"
            gap={2}
        >
            <MDButton
                color={"light"}
                onClick={() => setShow(false)}
            >
                Cancel
            </MDButton>

            <MDButton
                color="primary"
                disabled={!recipientSearchData.name || (paymentMethod === PaymentMethodTypes.ChargeCardOnFile && !selectedCard)}
                onClick={checkoutFn}
            >
                Checkout
            </MDButton>
        </MDBox>
    </form>
}

function CheckoutModal({show, setShow, recipientSearchData, setRecipientSearchData, showSuccessfulCheckoutModal, setShowSuccessfulCheckoutModal}: CheckoutModalProps) {
    const {setShowLoader} = useGlobal()

    const {postRecipientSearch, postRecipientSearchCheckout, getRecipientSearchCheckout} = RecipientSearchService()

    const [stripeToken, setStripeToken] = useState(null)
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
    const [profile, setProfile] = useState(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

    const [errorModalMessage, setErrorModalMessage] = useState<string>('')

    const [checkout, setCheckout] = useState<any>(null)

    const borderBottom = {
        borderBottom: ({ borders: { borderWidth }, palette: { light } }: Theme) =>
            `${borderWidth[1]} solid ${light.main}`,
    }

    useEffect(function(){
        if (show){
            setIsLoading(true)
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''))

            getRecipientSearchCheckout(recipientSearchData.id).then((response) => {
                if(response.hasErrors){
                    //setErrorModalMessage(response.errors[0]);
                    //setShowErrorModal(true);
                }
                else{
                    setStripeToken(response.payload.stripeToken)
                    setProfile(response.payload.profile)
                    setCheckout(response.payload)
                }

                setIsLoading(false)
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    function checkoutFree(){
        setShowLoader(true)

        // Update the name of the order
        postRecipientSearch(recipientSearchData).then(() => {
            postRecipientSearchCheckout(recipientSearchData.id).then((response) => {
                if(response.payload.paymentStatus === "success"){
                    setShowSuccessfulCheckoutModal(true)
                    setShow(false)
                }
                else{
                    setShowErrorModal(true)
                    setErrorModalMessage('An unexpected error occurred. Please try again or contact billing@lettrlabs.com')
                }

                setShowLoader(false)
            })
        })
    }

    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "1000px"}}>
            <DialogTitle variant={"h5"} p={0}>
                Checkout
            </DialogTitle>

            {!isLoading && checkout?
                <MDBox mt={2}>
                    <TextField
                        sx={{width: "100%"}}
                        type="text"
                        label="Search Name*"
                        value={recipientSearchData.name}
                        InputLabelProps={{shrink: true}}
                        onChange={(event) => {
                            setRecipientSearchData((prevState: RecipientSearch) => {
                                let copy: RecipientSearch = {...prevState}

                                copy.name = event.target.value

                                return copy
                            })
                        }}
                    />

                    {!recipientSearchData.name?
                        <MDTypography
                            variant={"h6"}
                            fontWeight={"regular"}
                            color="error"
                        >
                            {'This field is required'}
                        </MDTypography>
                        :
                        null
                    }

                    <Table sx={{ marginTop: "30px" }}>
                        <MDBox component="thead">
                            <TableRow sx={{"th": {border: "none"}}}>
                                <MDBox
                                    component="th"
                                    width={{ xs: "45%", md: "50%" }}
                                    py={1.5}
                                    px={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5" color="text" fontWeight="medium">
                                        Item
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5" color="text" fontWeight="medium">
                                        Quantity
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >

                                </MDBox>
                                <MDBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5" color="text" fontWeight="medium">
                                        Amount
                                    </MDTypography>
                                </MDBox>
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {checkout.lineItems &&
                                checkout.lineItems.map((lineItem: any, index: number) => (
                                    <TableRow key={"record" + index} sx={index === checkout.lineItems.length - 1? {} : {"td": {border: "none"}}}>
                                        <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                                            <MDTypography
                                                variant="body2"
                                                color="text"
                                                fontWeight="regular"
                                            >
                                                {lineItem.name}
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            sx={borderBottom}
                                        >
                                            <MDTypography
                                                variant="body2"
                                                color="text"
                                                fontWeight="regular"
                                            >
                                                {formatNumber(lineItem.quantity)}
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            sx={borderBottom}
                                        >
                                            x {formatPrice(lineItem.rate)}
                                        </MDBox>
                                        <MDBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            sx={borderBottom}
                                        >
                                            <MDTypography
                                                variant="body2"
                                                color="text"
                                                fontWeight="regular"
                                            >
                                                {lineItem.total >= 0 && formatPrice(lineItem.total)}
                                                {lineItem.total < 0 && "-" + formatPrice(lineItem.total * -1)}
                                            </MDTypography>
                                        </MDBox>
                                    </TableRow>
                                ))}
                            <TableRow sx={{"td:not(:nth-last-child(-n+2))": {border: "none"}}}>
                                <MDBox component="td" textAlign="left" p={1} sx={borderBottom} />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5">
                                        Subtotal
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5">
                                        {formatPrice(checkout.totalCharges)}
                                    </MDTypography>
                                </MDBox>
                            </TableRow>

                            {checkout.credits.length > 0 && (
                                <TableRow sx={{"td:not(:nth-last-child(-n+2))": {border: "none"}}}>
                                    <MDBox textAlign="left" p={1} sx={borderBottom} />
                                    <MDBox
                                        component="td"
                                        textAlign="left"
                                        py={1}
                                        pr={1}
                                        pl={3}
                                        sx={borderBottom}
                                    />
                                    <MDBox
                                        component="td"
                                        textAlign="left"
                                        py={1}
                                        pr={1}
                                        pl={3}
                                        sx={borderBottom}
                                    >
                                        <MDTypography variant="h5">
                                            Credits
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox
                                        component="td"
                                        textAlign="left"
                                        py={1}
                                        pr={1}
                                        pl={3}
                                        sx={borderBottom}
                                    >
                                        <MDTypography variant="h5">
                                            {formatPrice(checkout.totalCredits)}
                                        </MDTypography>
                                    </MDBox>
                                </TableRow>
                            )}

                            <TableRow sx={{"td": {border: "none"}}}>
                                <MDBox component="td" textAlign="left" p={1} sx={borderBottom} />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5" color={"primary"}>
                                        Total Due
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <MDTypography variant="h5">
                                        {checkout.balance >= 0 && formatPrice(checkout.balance)}
                                        {checkout.balance < 0 && formatPrice(-1 * checkout.balance)}
                                    </MDTypography>
                                </MDBox>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <MDBox mt={2}>
                        {checkout?
                            checkout.stripeToken?
                                <MDBox>
                                    {checkout.stripeToken && stripePromise?
                                        <Elements stripe={stripePromise} options={{clientSecret: stripeToken ?? ''}}>
                                            <StripeForm
                                                profile={profile!}
                                                checkout={checkout}
                                                setShowErrorModal={setShowErrorModal}
                                                setShowSuccessModal={setShowSuccessfulCheckoutModal}
                                                setErrorModalMessage={setErrorModalMessage}
                                                setShow={setShow}
                                                recipientSearchData={recipientSearchData}
                                            />
                                        </Elements>
                                        :
                                        null
                                    }
                                </MDBox>
                                :
                                <MDBox
                                    mt={3}
                                    width="100%"
                                    display="flex"
                                    justifyContent="flex-end"
                                    gap={2}
                                >
                                    <MDButton
                                        color="light"
                                        onClick={() => setShow(false)}
                                    >
                                        Cancel
                                    </MDButton>

                                    <MDButton
                                        color="primary"
                                        disabled={!recipientSearchData.name}
                                        onClick={checkoutFree}
                                    >
                                        Checkout
                                    </MDButton>
                                </MDBox>
                            :
                            null
                        }
                    </MDBox>

                </MDBox>
                :
                <MDBox textAlign="center">
                    <CircularProgress color="primary"></CircularProgress>
                </MDBox>
            }
        </DialogContent>

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalMessage}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {setShowErrorModal(false)}}
        />
    </Dialog>
}

export default CheckoutModal