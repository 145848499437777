import { Box } from '@mui/system';
import { DialogContent, Skeleton, Typography } from '@mui/material';

import React from 'react';

const LoadingState: React.FC = () => {
  return (
    <DialogContent sx={{ padding: '2rem 0'}}>
        <Typography variant="h3" sx={{ margin: '1rem 0 0 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 26}}>
            <Skeleton width={150} height={40} />
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: '3rem 0 2rem 0', justifyContent: 'space-between'}}>
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'90%'} height={40} />
            <Skeleton width={'60%'} height={40} />
            
        </Box>
    </DialogContent>
  );
}

export default LoadingState;