import React from 'react';

import { Box } from '@mui/system';
import { Skeleton, Typography } from '@mui/material';


const LoadingCheckout: React.FC = () => {
  return (
    <React.Fragment>
        {
            [0,1,2].map(l => (
                <Box key={l} sx={{ display: 'flex' }}>
                    <Skeleton width={'60px'} height={'100px'} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '1rem', width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <Typography component={'b'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                                <Skeleton width={'320px'} height={'45px'} />
                            </Typography>
                            <Typography component={'b'} sx={{ display: 'flex', gap: 1, alignItems: 'center', fontSize: '13px', opacity: '.7'}}>
                                <Skeleton width={'80px'} height={'20px'} /> / <Skeleton width={'60px'} height={'20px'} />
                            </Typography>
                        </Box>
                        <Typography variant='h5' sx={{ fontWeight: 'normal', color: 'grey' }}>
                            <Skeleton width={'180px'} height={'20px'} />
                        </Typography>
                        <Typography variant='h5' sx={{ fontWeight: 'normal', color: 'grey' }}>
                            <Skeleton width={'140px'} height={'20px'} />
                        </Typography>
                    </Box>
                </Box>
            ))
        }
    </React.Fragment>
  )
}

export default LoadingCheckout;