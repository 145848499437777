import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { AutomationProvider, useAutomationContext } from './context';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import PlusIcon from 'assets/icons/plus';
import SetupModal from './components/Modals/Setup';
import SettingsIcon from '@mui/icons-material/Settings';
import MDTypography from 'material-ui/components/MDTypography';
import MainLoadingState from './components/MainLoadingState';
import AutomationsTable from './components/Table';
import UpdateStatusModal from './components/Modals/UpdateStatus';
import NewAutomationModal from './components/Modals/New';
import AutomationsService from 'services/automations';
import AutomationDetailModal from './components/Modals/Detail';
import DeleteAutomationModal from './components/Modals/Delete';
import FriendlyFirstAccessPage from './components/FriendlyFirstAccessPage';
import DuplicateAutomationModal from './components/Modals/Duplicate';
import UpdateAutomationNameModal from './components/Modals/UpdateName';
import SuccessCallbackSetupModal from './components/Modals/SuccessCallbackSetup';

const Automations: React.FC = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { toggleModal, automationTypeList, automationStatusList, automationFilterData, setAutomationFilterData, setInitialData } = useAutomationContext();

    const { enqueueSnackbar } = useSnackbar();
    const { getAllAutomations } = AutomationsService();

    const [platformName, setPlatformName] = useState('');
    const [isOpenShopifySuccessfullyModal, setIsOpenShopifySuccessfullyModal] = useState(false);

    const {
        data: automations,
        isLoading,
    } = useQuery({
        queryFn: () => getAllAutomations(),
        queryKey: ['allAutomations'],
        refetchOnWindowFocus: false, 
        staleTime: 0
    });

    const canShowMainPage = () => {
        return !isLoading && automations?.payload.length !== 0
    }

    useEffect(() => {
        const action = searchParams.get('action');
        const status = searchParams.get('status');
        const automationType = searchParams.get('automationType');
        const newAutomationByParam = searchParams.get('new');

        if(newAutomationByParam) {
            toggleModal('isNewAutomationModalOpen', true);
        }
        
        if (automations?.payload) {
            setInitialData(automations?.payload);
        }
        
        if(action?.toLocaleLowerCase() === 'setup' && status?.toLocaleLowerCase() === 'success' && (automationType?.toLocaleLowerCase() === 'shopify' || automationType?.toLocaleLowerCase() === 'klaviyo')) {
            setIsOpenShopifySuccessfullyModal(true);
            setPlatformName(automationType);
        }

        if(status?.toLocaleLowerCase() === 'createdsuccessfully') {
            enqueueSnackbar('Automation successfully created.', { variant: 'success' } );
            navigate('/automations')
        }

        if(status?.toLocaleLowerCase() === 'updatedstatussuccessfully') {
            enqueueSnackbar('Automation Status successfully updated.', { variant: 'success' } );
            navigate('/automations')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automations, setInitialData, searchParams]);

    return (
        <React.Fragment>
            {
                canShowMainPage() && (
                    <MDCard border={false} boxShadow={false} sx={{ margin: '.25rem', position: 'relative'}}>
                        <Box px={4}>
                            <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                                <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                                    Automations
                                </MDTypography>
                                <Box display={'flex'} gap={1}>
                                    <MDButton data-testid="setup-automation-btn" color="light" sx={{ borderRadius: '5px !important' }} onClick={() => toggleModal('isSetupModalOpen', true)}>
                                        <SettingsIcon style={{ marginRight: 8, borderRadius: 5 }} /> Manage Integrations
                                    </MDButton>
                                    <MDButton data-testid="new-automation-btn" onClick={() => toggleModal('isNewAutomationModalOpen', true)} sx={{ borderRadius: '5px !important' }}>
                                        <PlusIcon style={{ marginRight: 8 }} /> New Automation
                                    </MDButton>
                                </Box>
                            </Grid>
                            <Grid container gap={2} mt={4}>
                                <FormControl sx={{ width: 200 }}>
                                    <InputLabel id="demo-simple-select-label">Automation Type</InputLabel>
                                    <Select
                                        data-testid="automation-type-select"
                                        autoWidth
                                        sx={{ borderRadius: '5px !important' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={automationFilterData.integrationOrderTypeName}
                                        onChange={(e: SelectChangeEvent) => setAutomationFilterData({ ...automationFilterData, integrationOrderTypeName: e.target.value })}
                                        label="Automation Type"
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {
                                            automationTypeList.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ width: 200 }}>
                                    <InputLabel id="status-select-label">Status</InputLabel>
                                    <Select
                                        data-testid="automation-status-select"
                                        autoWidth
                                        sx={{ borderRadius: '5px !important' }}
                                        labelId="status-select-label"
                                        data-test-id="status-select"
                                        id="demo-simple-select"
                                        label="Status"
                                        value={automationFilterData.status}
                                        onChange={(e: SelectChangeEvent) => setAutomationFilterData({ ...automationFilterData, status: e.target.value })}
                                    >
                                        <MenuItem value={""}>All</MenuItem>
                                        {
                                            automationStatusList.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Box>
                        <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />
                        <Grid container>
                            <AutomationsTable isLoading={isLoading} />
                        </Grid>
                    </MDCard>
                )
            }

            <MainLoadingState isLoading={isLoading} />

            <FriendlyFirstAccessPage isLoading={isLoading} payload={automations?.payload} />

            <Grid container>
                <SetupModal />
                <UpdateStatusModal />
                <NewAutomationModal />
                <DeleteAutomationModal />
                <AutomationDetailModal />
                <DuplicateAutomationModal />
                <SuccessCallbackSetupModal platform={platformName} isOpenShopifySuccessfullyModal={isOpenShopifySuccessfullyModal} setIsOpenShopifySuccessfullyModal={(value) => setIsOpenShopifySuccessfullyModal(value)} />
                <UpdateAutomationNameModal />
                </Grid>
        </React.Fragment>
    )
}

const AutomationsPage: React.FC = () => (
    <AutomationProvider>
        <SnackbarProvider 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            maxSnack={3}>
                <Automations />
            </SnackbarProvider>
    </AutomationProvider>
);

export default AutomationsPage;
