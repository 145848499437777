// @ts-nocheck
import { ITemplateArtifacts } from "./templateArtifact"

export interface ITemplate {
  id: number;
  address1: string;
  address2: string;
  advancedFeaturesEnabled: string;
  campaignName: string;
  city: string;
  customerId: string;
  dataUrl: string;
  description: string;
  differentPayer: string;
  doubleSided: string;
  executionDate: string;
  firstName: string;
  font: string;
  fontSize: number;
  fontSizeReturnAddress: number;
  holdUntilDate: string;
  lastName: string;
  name: string;
  mailToMe: string;
  templateArtifacts: ITemplateArtifacts;
  templateStatus: string;
  templateVolume: number;
  payeeEmail: string;
  payeeFirstName: string;
  payeeLastName: string;
  postageType: string;
  product: string;
  profileId: number;
  qrUrl: string;
  recipientsCsvBoxUrl: string;
  recipientsUploaded: boolean;
  returnAddress1: string;
  returnAddress2: string;
  returnCity: string;
  returnFirstName: string;
  returnLastName: string;
  returnOrganization: string;
  returnState: string;
  returnTitle: string;
  returnZip: string;
  sceneUrl: string;
  state: string;
  submittedDate: Date;
  text: string;
  text2: string;
  updatedDate: Date;
  useQr: boolean;
  zip: string;
  isTemplate: boolean;
  isIntegrationOrderTemplate: boolean;
  envelopeFull: string;
  sceneBackFull: string;
  sceneBackThumb: string;
  sceneFrontFull: string;
  sceneFrontThumb: string;
  canDelete: boolean;
  hasDiscount: boolean;
  templateNotSupported: boolean
  productionSpeedPrices: {
    ExpeditedFastest: number;
    ExpeditedFaster: number;
    Normal: number;
    HailTrace: number;
  };
  postageTypePrices: {
    Standard: number;
    FirstClass: number;
    HailTrace: number;
  };
  noFrontLogo: boolean;
  qrCodeY: number;
  qrCodeX: number;
  qrCodeWidth: number;
  qrCodeHeight: number;
  qrAngle: number;
  trackingPhoneNumber: string;
  qrCodeSide?: 'Rear' | 'Front';
}

export interface ITemplates extends Array<ITemplate> {}

export class Template implements ITemplate {
  constructor(id: number) {
    this.id = id;
    this.recipientsUploaded = false;
    this.advancedFeaturesEnabled = 'No';
    this.canDelete = true;
  }
  isTemplate: boolean;
  isIntegrationOrderTemplate: boolean;
  id: number;
  address1: string;
  address2: string;
  advancedFeaturesEnabled: string;
  campaignName: string;
  city: string;
  customerId: string;
  dataUrl: string;
  description: string;
  differentPayer: string;
  doubleSided: string;
  executionDate: string;
  firstName: string;
  font: string;
  fontSize: number;
  fontSizeReturnAddress: number;
  holdUntilDate: string;
  lastName: string;
  name: string;
  mailToMe: string;
  templateArtifacts: ITemplateArtifacts;
  templateStatus: string;
  templateVolume: number;
  payeeEmail: string;
  payeeFirstName: string;
  payeeLastName: string;
  postageType: string;
  product: string;
  profileId: number;
  qrUrl: string;
  recipientsCsvBoxUrl: string;
  recipientsUploaded: boolean;
  returnAddress1: string;
  returnAddress2: string;
  returnCity: string;
  returnFirstName: string;
  returnLastName: string;
  returnOrganization: string;
  returnState: string;
  returnTitle: string;
  returnZip: string;
  sceneUrl: string;
  state: string;
  submittedDate: Date;
  text: string;
  text2: string;
  updatedDate: Date;
  useQr: boolean;
  zip: string;
  envelopeFull: string;
  sceneBackFull: string;
  sceneBackThumb: string;
  sceneFrontFull: string;
  sceneFrontThumb: string;
  canDelete: boolean;
  hasDiscount: boolean;
  templateNotSupported: boolean
  productionSpeedPrices: {
    ExpeditedFastest: number;
    ExpeditedFaster: number;
    Normal: number;
    HailTrace: number;
  };
  postageTypePrices: {
    Standard: number;
    FirstClass: number;
    HailTrace: number;
  };
  noFrontLogo: boolean;
  qrCodeY: number;
  qrCodeX: number;
  qrCodeWidth: number;
  qrCodeHeight: number;
  qrAngle: number;
  trackingPhoneNumber: string;
  qrCodeSide?: 'Rear' | 'Front';
}
