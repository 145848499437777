import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';

import { RouteType } from 'newStandard/src/routes/types';
import { useGlobal } from 'context/global-context';

export default function SidebarItem({ collapse, route, name, icon, hide }: RouteType) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setTemplateFlow } = useGlobal();
  const [isSelected, setIsSelected] = useState<boolean>(route === pathname);

  useEffect(() => {
    if (route === pathname) setIsSelected(true);
    else if (collapse?.find((el) => el.route === pathname)) setIsSelected(true);
    else setIsSelected(false);
  }, [collapse, pathname, route]);

  const handleNavigate = (navigateTo?: string) => {
    if (!navigateTo) return;
    setTemplateFlow({});
    navigate(navigateTo);
  };

  if (hide) return null;

  return (
    <ListItem disablePadding data-testid={'menu_item_' + name?.toLocaleLowerCase()}>
      <Tooltip
        title={
          collapse ? (
            <Box>
              {collapse.map((childRoute) =>
                childRoute.hide ? null : (
                  <ListItemButton
                    key={childRoute.route}
                    selected={childRoute.route === pathname}
                    onClick={() => handleNavigate(childRoute.route)}
                    sx={{
                      flexDirection: 'column',
                      borderRadius: 1,
                      alignItems: 'flex-start',
                      px: 1,
                      py: 1,
                      '&:first-child': { marginTop: '3px'},
                      '&:last-child': { marginBottom: '3px'},
                      '&.Mui-selected': { backgroundColor: '#f5f5f5', color: '#F05A2A' },
                    }}
                  >
                    <ListItemText
                      data-testid={'submenu_item_' + childRoute.name.replaceAll('', '_').toLowerCase()}
                      primary={childRoute.name}
                      primaryTypographyProps={{ variant: 'caption', fontSize: '14px', sx: { fontWeight: 'normal' } }}
                    />
                  </ListItemButton>
                )
              )}
            </Box>
          ) : ''
        }
        placement="right"
        arrow
        enterDelay={0}
        leaveDelay={0}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: '#fff',
              minWidth: '210px !important',
              color: '#344767',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: 1,
              ml: '0 !important',
            },
          },
          arrow: {
            sx: {
              color: '#fff',
            },
          },
        }}
      >
        <ListItemButton
          selected={isSelected}
          onClick={() => (!collapse && route ? handleNavigate(route) : null)}
          sx={{
            px: 1,
            flexDirection: 'column',
            position: 'relative',
            '&.Mui-selected': { backgroundColor: '#fff', svg: { color: '#F05A2A' }, span: { color: '#F05A2A' } },
          }}
        >
          <ListItemIcon sx={{ justifyContent: 'center', color: '#344767' }}>{icon}</ListItemIcon>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ variant: 'caption', fontWeight: 'normal', sx: { color: '#344767' } }}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}
