import React from 'react';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import AutomationImage from 'assets/images/automation-image.jpg';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { IAutomation } from 'services/automations/automatios.service.types';
import { useAutomationContext } from 'pages/automations/context';

interface IFriendlyFirstAccessPage {
    isLoading: boolean,
    payload: IAutomation[] | undefined
}

const FriendlyFirstAccessPage: React.FC<IFriendlyFirstAccessPage> = ({ isLoading, payload }) => {

    const { toggleModal } = useAutomationContext();
    
    return (
        <React.Fragment>
            {
                !isLoading && (payload && payload.length === 0) && (
                    <MDCard border={false} boxShadow={false} sx={{ minHeight: '87vh', margin: '.25rem'}} >
                        <Box px={4}>
                            <Box sx={{ display: 'flex', padding: '4rem 4rem', alignItems: 'center' }}>   
                                <Box sx={{ width: '70%'}}>
                                    <Typography variant='h2' fontWeight={'normal'} color={'#000'} lineHeight={'4rem'} marginBottom={'2rem'}>
                                        It seems like you <br />don’t have any automations yet.
                                    </Typography>
                                    <Typography variant='body2' sx={{ opacity: .7}}>
                                        Not using automation is like driving without GPS: you might get there, but it’ll be much harder! <br /> Tools like Klaviyo, Shopify, Zapier, Mover Mail, and Lead Revel help boost sales, engage customers, and save time.
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, margin: '1rem 0 1.5rem 0' }}>
                                        <Typography variant='body2' sx={{ opacity: .7}}>
                                            Take the first step and let technology work for you!
                                        </Typography>
                                        <Box>🚀</Box>
                                    </Box>
                                    <MDButton data-testid="create-first-automation-btn" onClick={() => toggleModal('isNewAutomationModalOpen', true)}>Create your first Automation</MDButton>
                                </Box>        
                                <Box sx={{ width: '45%'}} data-testid="performance-illustrtion">
                                    <img src={AutomationImage} alt='Performance Illustration' width={'100%'}/>
                                </Box>                 
                            </Box>
                        </Box>
                    </MDCard>
                )
            }
        </React.Fragment>
    )
}

export default FriendlyFirstAccessPage;